import React from 'react'
import './TabsSelector.sass'

interface TabsSelectorProps {
    options: { id: string; label: string }[]
    selectedTabId: string
    onSelectedTabChange: (newSelectedTabId: string) => void
}

const TabsSelector: React.FC<TabsSelectorProps> = (props) => {
    return (
        <div className="tab-selector-wrapper">
            {props.options.map((opt) => (
                <div
                    key={opt.id}
                    onClick={() => props.onSelectedTabChange(opt.id)}
                    className={`tab-selector-wrapper__tab-list-element ${opt.id === props.selectedTabId ? 'active-tab' : ''}`}
                >
                    {opt.label}
                </div>
            ))}
        </div>
    )
}

export default TabsSelector
