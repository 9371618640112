import React, { useEffect, useMemo, useState } from 'react'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import { Tag } from '../../types/data'
import { TextField } from '@material-ui/core'
import './TagsContainer.sass'
import { deepCopy } from '../../utility/functions'

interface TagsContainerProps {
    options: Tag[]
    selectedTags: Tag[]
    fixAdornment?: boolean
    disabled?: boolean
    singleVal?: boolean
    onTagsChange: (newTags: Tag[]) => void
}

const TagsContainer: React.FC<TagsContainerProps> = (props) => {
    const [selectedTags, setSelectedTags] = useState<Tag[]>([])

    useEffect(() => {
        setSelectedTags(props.selectedTags)
    }, [props.selectedTags])

    const sortedOptions = useMemo(() => {
        const tagSelectorOptions = deepCopy(props.options)
        if (!tagSelectorOptions || tagSelectorOptions.length === 0) return []
        return tagSelectorOptions.sort((a, b) =>
            a.keyName.toLocaleLowerCase() < b.keyName.toLocaleLowerCase() ? -1 : 1
        )
    }, [props.options])

    return (
        <div
            className={`tags-container-wrapper ${props.fixAdornment ? 'tags-container-wrapper--fixed-adornment' : ''}`}
        >
            <Autocomplete
                multiple={!props.singleVal || true}
                disabled={!!props.disabled}
                // id="fixed-tags-demo"
                value={selectedTags || []}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                onChange={(_event, newValue) => {
                    if (!newValue) {
                        props.onTagsChange([])
                    } else {
                        props.onTagsChange([...(newValue as Tag[])])
                    }
                }}
                options={sortedOptions}
                getOptionLabel={(option) => option.keyName}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip variant="outlined" color="default" label={option.keyName} {...getTagProps({ index })} />
                    ))
                }
                style={{ width: 500 }}
                renderInput={(params) => <TextField variant="outlined" {...params} />}
            />
        </div>
    )
}

export default TagsContainer
