import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import '../../styles/pages/ContestCreationWidget.sass'

interface Props {
    onConfirm: (description: string) => void
}

const DefineContestDescription: React.FC<Props> = (props) => {
    const [contestDescription, setContestDescription] = useState('')

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Scrivi una breve descrizione di quello che vuoi ottenere</h1>
                <p>
                    Fornisci ai nostri professionisti tutte le informazioni che avresti dato ad un architetto offline
                    circa il tuo progetto. Facci sapere quali sono le tue esigenze e quali sono i tuoi gusti. Ricordati
                    che potrai modificare la descrizione aggiungendo dettagli anche a concorso già iniziato.
                </p>
            </div>
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <TextInput
                        isTextarea
                        value={contestDescription}
                        placeholder="Descrivi ciò che ti piacerebbe ottenere dai nostri professionisti. Ad esempio 'Amo uno stile moderno e con colori semplici come quello degli Apple Store'."
                        onChange={(newVal) => setContestDescription(newVal)}
                    />
                    <div style={{ height: 24, width: 1 }} />
                    <Button onClick={() => props.onConfirm(contestDescription)}>Avanti</Button>
                </div>
            </div>
        </div>
    )
}

export default DefineContestDescription
