import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './IconButton.sass'

interface IconButtonProps {
    icon: IconProp
    color?: string
    onClick?: () => void
}

const IconButton: React.FC<IconButtonProps> = (props) => {
    return (
        <div className="icon-button-container" onClick={() => (props.onClick ? props.onClick() : null)}>
            <FontAwesomeIcon icon={props.icon} color={props.color || '#000'} size="1x" />
        </div>
    )
}

export default IconButton
