import React from 'react'
import '../../styles/pages/ContestCreationWidget.sass'

interface AvailableTypeOption {
    id: string
    key: string
}

interface Props {
    options: AvailableTypeOption[]
    onConfirm: (selectedType: string) => void
}

const DefineContestCategory: React.FC<Props> = (props) => {
    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>A che categoria appartiene il tuo spazio?</h1>
                <p>
                    Aiutaci a identificare con precisione la categoria che vuoi progettare. Seleziona la categoria più
                    vicina possibile al tuo tipo di spazio. Se lo spazio che devi progettare proprio non rientra in
                    nessuna delle opzioni disponibili, clicca su Altro.
                </p>
            </div>
            <div className="contest-creation-items-row">
                {props.options.map((contestType) => (
                    <div
                        key={contestType.id}
                        className="interactive-box"
                        onClick={() => props.onConfirm(contestType.id)}
                    >
                        {contestType.key}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DefineContestCategory
