import React, { useEffect, useState } from 'react'
import {
    ContestParticipation,
    ContestPlacement,
    ContestSummary,
    PlacementsRequestObject,
    SendMentonBodyObject,
} from '../../../types/contests'
import Button from '../../../components/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faMedal } from '@fortawesome/free-solid-svg-icons'
import { getContestPlacements, sendContestMention, sendContestResults } from '../../../resources/api-constants'
import { useAppSelector } from '../../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../../utility/toast'

interface Props {
    participations: ContestParticipation[]
    prizeAmounts: number[]
    contest: ContestSummary
}

interface CollapsiblePartecipationProps {
    contest: ContestSummary
    participationKey: string
    groupedParticipations: GroupedParticipations
    designersMentions: string[]
    podiumPlaces: string[]
    finalPlacements: ContestPlacement[]
    updateMentions: (mentions: string[]) => void
    updatePodium: (newPodium: string[]) => void
}

interface PartecipationPodiumButtonsProps {
    currentPodium: string[]
    designerTempName: string
    designersMentions: string[]
    mentions: string[]
    updateMentions: (mentions: string[]) => void
    updatePodium: (newPodium: string[]) => void
}

interface DisclamerViewProps {
    onAccept: () => void
}

interface GroupedParticipations {
    [key: string]: ContestParticipation[]
}

const ContestParticipationsSection: React.FC<Props> = (props) => {
    const user = useAppSelector((data) => data.user)
    const [groupedParticipations, setGroupedParticipations] = useState<GroupedParticipations | null>(null)
    const [podiumPlaces, setpodiumPlaces] = useState(['', '', '', '', ''])
    const [mentions, setMentions] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [disclamerWasAccepted, setDisclamerWasAccepted] = useState(false)
    const [finalPlacements, setFinalPlacements] = useState<ContestPlacement[]>([])

    useEffect(() => {
        setDisclamerWasAccepted(props.contest.status > 5)
    }, [props.contest])

    useEffect(() => {
        if (!props.participations || props.participations.length === 0) return
        const participations = props.participations.reduce((participations, item) => {
            participations[item.tempNameDesigner] = participations[item.tempNameDesigner] || []
            participations[item.tempNameDesigner].push(item)
            return participations
        }, Object.create(null))
        setGroupedParticipations(participations)
    }, [props.participations])

    const sendFinalResults = async () => {
        try {
            setLoading(true)

            await Promise.all(
                mentions.map(async (mention) => {
                    const data: SendMentonBodyObject = {
                        idContest: props.participations[0].idContest,
                        idDesigner: mention,
                        description: '',
                    }
                    await sendContestMention(user.userInfo?.authToken || '', data)
                })
            )

            const parsedPlacementsObjects: { idDesigner: string; placement: number; amount: number }[] = []

            podiumPlaces.forEach((podiumPlace, i) => {
                if (podiumPlace !== '') {
                    parsedPlacementsObjects.push({
                        idDesigner: podiumPlace,
                        placement: i + 1,
                        amount: props.prizeAmounts[i],
                    })
                }
            })

            const placementsData: PlacementsRequestObject = {
                idContest: props.participations[0].idContest,
                placements: parsedPlacementsObjects,
            }
            await sendContestResults(user.userInfo?.authToken || '', placementsData)
            ToastSuccess('I vincitori sono stati premiati e avvisati con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la premiazione dei vincitori.')
        }
        setLoading(false)
    }

    const fetchContestPlacements = async () => {
        try {
            const placements = await getContestPlacements(user.userInfo?.authToken || '', props.contest.idContest)
            if (placements) {
                setFinalPlacements(placements)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (props.contest.status > 5) {
            void fetchContestPlacements()
        }
    }, [props.contest])

    return (
        <>
            {disclamerWasAccepted ? (
                <div style={{ width: '100%', maxWidth: '1340px' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 16,
                        }}
                    >
                        {props.contest.status === 5 ? (
                            <>
                                <div className="info-message-container" style={{ marginTop: 0 }}>
                                    <div className="subtitle-text">
                                        Visualizza i partecipanti al tuo contest e premiali posizionandoli sul podio o
                                        assegnando punti menzione cliccando sulla medaglia.
                                    </div>
                                </div>
                                <Button
                                    loading={loading}
                                    disabled={loading || podiumPlaces.every((place) => place === '')}
                                    onClick={() => void sendFinalResults()}
                                >
                                    Premia i vincitori
                                </Button>
                            </>
                        ) : (
                            <div className="info-message-container" style={{ marginTop: 0 }}>
                                {props.contest.zipFile && props.contest.zipFile !== '' ? (
                                    <div className="subtitle-text">
                                        <a
                                            style={{ color: '#000', fontWeight: 600 }}
                                            download
                                            href={props.contest.zipFile}
                                        >
                                            Clicca qui
                                        </a>{' '}
                                        per scaricare l&apos;archivio con gli elaborati del contest.
                                    </div>
                                ) : (
                                    <div className="subtitle-text">
                                        Non è stato possibile creare l&apos;archivio con gli elaborati del contest.
                                        Contatta l&apos;amministratore per ricevere assistenza.
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="contest-partecipations-grid">
                        {!!groupedParticipations &&
                            Object.keys(groupedParticipations).map((participationKey) => (
                                <CollapsiblePartecipation
                                    contest={props.contest}
                                    key={participationKey}
                                    participationKey={participationKey}
                                    groupedParticipations={groupedParticipations}
                                    designersMentions={mentions}
                                    podiumPlaces={podiumPlaces}
                                    updatePodium={(newPodium) => setpodiumPlaces(newPodium)}
                                    updateMentions={(mentions) => setMentions(mentions)}
                                    finalPlacements={finalPlacements}
                                />
                            ))}
                    </div>
                </div>
            ) : (
                <DisclamerView onAccept={() => setDisclamerWasAccepted(true)} />
            )}
        </>
    )
}

const DisclamerView: React.FC<DisclamerViewProps> = (props) => {
    return (
        <div
            style={{
                width: '100%',
                maxWidth: '1340px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 32,
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginBottom: 32,
                }}
            >
                <Button onClick={() => props.onAccept()}>Accetto</Button>
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <p>
                    Gli utenti che accedono alle soluzioni pubblicate per la visione e la premiazione dei vincitori del
                    concorso accettano di utilizzarle esclusivamente{' '}
                    <b>per scopi personali inerenti al contest specifico</b>. L&apos;utente si impegna a non utilizzare
                    le risorse in alcun modo che violi i diritti di proprietà intellettuale o altri diritti di terzi.
                </p>
                <p>
                    Tutte le soluzioni pubblicate dai designers partecipanti al concorso{' '}
                    <b>sono protette da copyright</b> e sono di proprietà esclusiva dei designers. L&apos;utente
                    acquisisce, <b>esclusivamente con riferimento al contest specifico</b>, solo i diritti di utilizzo
                    delle soluzioni pubblicate e premiate al temine del concorso, non potendo vantare alcun differente
                    diritto di proprietà sulle stesse. L&apos;utente si impegna a non copiare, modificare, distribuire o
                    vendere le risorse pubblicate dai designer iscritti al concorso o qualsiasi parte di esse senza il
                    previo consenso scritto del designer stesso,{' '}
                    <b style={{ textDecoration: 'underline' }}>
                        consapevole delle conseguenze civili e penali conseguenti alla violazione di tale divieto
                    </b>
                    .
                </p>
            </div>
        </div>
    )
}

const CollapsiblePartecipation: React.FC<CollapsiblePartecipationProps> = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false)

    const getDesignerPlacement = (designerId: string) => {
        if (props.finalPlacements && props.finalPlacements.length > 0) {
            const foundPlacement = props.finalPlacements.find((placement) => placement.idDesigner === designerId)
            if (foundPlacement) {
                return <span>Posizione: {foundPlacement.placement}°</span>
            }
            return null
        }
        return null
    }

    const getResourceElement = (resource: ContestParticipation) => {
        const fileType = resource.projectFile.slice(resource.projectFile.lastIndexOf('.') + 1)
        switch (fileType) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return (
                    <a
                        className="resource-image-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={resource.projectFile}
                            alt={resource.projectFile.slice(resource.projectFile.lastIndexOf('/'))}
                        />
                    </a>
                )
            default:
                return (
                    <a
                        className="resource-document-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="resource-type-extension">{fileType}</span>
                    </a>
                )
        }
    }

    return (
        <div className="contest-participations-element">
            <div className="contest-participations-element__header">
                <div
                    style={{ display: 'flex', gap: 16, flex: 1, cursor: 'pointer', alignItems: 'center' }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
                    {props.participationKey}
                    {getDesignerPlacement(props.groupedParticipations[props.participationKey][0].idAuthor)}
                </div>
                {props.contest.status < 6 && (
                    <PartecipationPodiumButtons
                        currentPodium={props.podiumPlaces}
                        designerTempName={props.participationKey}
                        designersMentions={props.designersMentions}
                        updatePodium={(newPodium) => props.updatePodium(newPodium)}
                        updateMentions={(mentions) => props.updateMentions(mentions)}
                        mentions={props.designersMentions}
                    />
                )}
            </div>
            <div className={`contest-participations-element__body ${isCollapsed ? 'collapsed-body' : ''}`}>
                <div key={props.participationKey} className="public-project-resources-container">
                    {props.groupedParticipations[props.participationKey as keyof GroupedParticipations].map(
                        (participation) => (
                            <div key={participation.ID} className="public-project-resources-container__resource-box">
                                {getResourceElement(participation)}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

const PartecipationPodiumButtons: React.FC<PartecipationPodiumButtonsProps> = (props) => {
    return (
        <div className="partecipation-podium-buttons">
            <Button
                onClick={() => {
                    if (props.mentions.includes(props.designerTempName)) {
                        props.updateMentions(props.mentions.filter((mention) => mention !== props.designerTempName))
                    } else {
                        props.updateMentions([...props.mentions, props.designerTempName])
                    }
                }}
            >
                <FontAwesomeIcon
                    icon={faMedal}
                    color={props.designersMentions.includes(props.designerTempName) ? '#fecb47' : '#fff'}
                />
            </Button>
            <Button
                disabled={props.currentPodium.includes(props.designerTempName)}
                type="ghost"
                onClick={() =>
                    props.updatePodium(
                        props.currentPodium.map((podiumPlace, i) => {
                            if (i === 0) return props.designerTempName
                            return podiumPlace
                        })
                    )
                }
            >
                <span style={{ color: props.currentPodium[0] === props.designerTempName ? '#ffb800' : '#000' }}>
                    1°
                </span>
            </Button>
            <Button
                disabled={props.currentPodium.includes(props.designerTempName)}
                type="ghost"
                onClick={() =>
                    props.updatePodium(
                        props.currentPodium.map((podiumPlace, i) => {
                            if (i === 1) return props.designerTempName
                            return podiumPlace
                        })
                    )
                }
            >
                <span style={{ color: props.currentPodium[1] === props.designerTempName ? '#ffb800' : '#000' }}>
                    2°
                </span>
            </Button>
            <Button
                disabled={props.currentPodium.includes(props.designerTempName)}
                type="ghost"
                onClick={() =>
                    props.updatePodium(
                        props.currentPodium.map((podiumPlace, i) => {
                            if (i === 2) return props.designerTempName
                            return podiumPlace
                        })
                    )
                }
            >
                <span style={{ color: props.currentPodium[2] === props.designerTempName ? '#ffb800' : '#000' }}>
                    3°
                </span>
            </Button>
            <Button
                disabled={props.currentPodium.includes(props.designerTempName)}
                type="ghost"
                onClick={() =>
                    props.updatePodium(
                        props.currentPodium.map((podiumPlace, i) => {
                            if (i === 3) return props.designerTempName
                            return podiumPlace
                        })
                    )
                }
            >
                <span style={{ color: props.currentPodium[3] === props.designerTempName ? '#ffb800' : '#000' }}>
                    4°
                </span>
            </Button>
            <Button
                disabled={props.currentPodium.includes(props.designerTempName)}
                type="ghost"
                onClick={() =>
                    props.updatePodium(
                        props.currentPodium.map((podiumPlace, i) => {
                            if (i === 4) return props.designerTempName
                            return podiumPlace
                        })
                    )
                }
            >
                <span style={{ color: props.currentPodium[4] === props.designerTempName ? '#ffb800' : '#000' }}>
                    5°
                </span>
            </Button>
        </div>
    )
}

export default ContestParticipationsSection
