import React from 'react'
import '../../styles/pages/ContestCreationWidget.sass'

interface AvailableTypeOption {
    id: string
    key: string
}

interface Props {
    options: AvailableTypeOption[]
    onConfirm: (selectedType: string) => void
}

const DefineContestType: React.FC<Props> = (props) => {
    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Che tipo di spazio stai progettando?</h1>
                <p>
                    Facci sapere se stai lavorando su uno spazio residenziale, su uno commerciale o piuttosto su un
                    esterno. Selezioneremo per te solo i progettisti della nostra community specializzati nella
                    tipologia di progetti di cui hai bisogno!
                </p>
            </div>
            <div className="contest-creation-items-row">
                {props.options.map((contestType) => (
                    <div
                        key={contestType.id}
                        className="interactive-box"
                        onClick={() => props.onConfirm(contestType.id)}
                    >
                        {contestType.key}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DefineContestType
