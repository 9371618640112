import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import InfiniteListLoader from '../../components/Loader/InfiniteListLoader'
import { getSingleProject, likeProject } from '../../resources/api-constants'
import { ResourcesTypes } from '../../resources/other-constants'
import { ROUTES } from '../../resources/routes-constants'
import { useAppSelector } from '../../store/reducers/store'
import { Resource } from '../../types/data'
import { MainProjectInfo } from '../../types/projects'
import '../../styles/pages/projectsPage.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faUser } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/Button/Button'
import { LikeProjectBody } from '../../types/requests'

const emptyProject: MainProjectInfo = {
    id: -1,
    idProject: '',
    idDesigner: '',
    title: '',
    description: '',
    tags: [],
    isContest: 0,
    enabled: 0,
    dataCreations: '',
    resource: [],
    likes: 0,
    name: '',
}

const PublicProjectDetailPage: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [currentProjectInfo, setCurrentProjectInfo] = useState<MainProjectInfo>(emptyProject)
    const [currentProjectCover, setCurrentProjectCover] = useState<Resource | null>(null)
    const { state } = useLocation()

    console.log(currentProjectCover)

    const fetchProjectData = async () => {
        try {
            setIsLoading(true)
            const project = await getSingleProject(user.accessToken, state.idProject)
            if (project) {
                setCurrentProjectInfo(project)
                setCurrentProjectCover(
                    project.resource.find(
                        (resource) => resource.typeResource === ResourcesTypes.PROJECT_FILE_BANNER_IMAGE
                    ) || null
                )
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (state !== null && state.idProject !== undefined) {
            void fetchProjectData()
        }
    }, [state])

    const toggleLikeProject = async () => {
        try {
            const likedProjectBody: LikeProjectBody = {
                idProject: state.idProject,
                state: !currentProjectInfo.liked,
            }
            await likeProject(user.accessToken, likedProjectBody)
            await fetchProjectData()
        } catch (error) {
            console.error(error)
        }
    }

    const getResourceElement = (resource: Resource) => {
        const fileType = resource.projectFile.slice(resource.projectFile.lastIndexOf('.') + 1)
        switch (fileType) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return (
                    <a
                        className="resource-image-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={resource.projectFile}
                            alt={resource.projectFile.slice(resource.projectFile.lastIndexOf('/'))}
                        />
                    </a>
                )
            default:
                return (
                    <a
                        className="resource-document-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="resource-type-extension">{fileType}</span>
                    </a>
                )
        }
    }

    return (
        <div className="page-main-container">
            <div className="page-main-header">
                <div className="page-title-container">
                    <span className="page-title">
                        <label className="page-title__link-text">
                            <Link to={ROUTES.PROJECTS_ROUTE}>Progetti</Link> /
                        </label>
                        {currentProjectInfo ? currentProjectInfo.title : 'Nuovo progetto'}
                    </span>
                </div>
                {/* <div className="page-actions-container">
                    <Button type="secondary" onClick={() => performLikeOnProject()}>
                        <span>
                            <FontAwesomeIcon icon={faHeart} />
                            Mi piace
                        </span>
                    </Button>
                </div> */}
            </div>

            {currentProjectInfo && !isLoading ? (
                <div className="detail-body">
                    <div className="page-main-header">
                        <div className="author-box">
                            <div className="author-sprite">
                                <FontAwesomeIcon className="author-sprite__icon" icon={faUser} />
                            </div>
                            <span className="author-name">{currentProjectInfo.name}</span>
                        </div>
                        <div>
                            <Button onClick={() => void toggleLikeProject()}>
                                <FontAwesomeIcon icon={faHeart} color={currentProjectInfo.liked ? '#fecb47' : '#fff'} />
                            </Button>
                        </div>
                    </div>
                    <div className="public-project-tag-container">
                        {currentProjectInfo.tags.map((tag) => (
                            <span key={tag.id} className="public-project-tag">
                                {tag.keyName}
                            </span>
                        ))}
                    </div>
                    <div className="public-project-general-section">
                        <p>{currentProjectInfo.description}</p>
                    </div>
                    <div className="public-project-resources-container">
                        {currentProjectInfo.resource.map((res) => (
                            <div key={res.id} className="public-project-resources-container__resource-box">
                                {getResourceElement(res)}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <InfiniteListLoader text="Sto caricando il progetto" />
            )}
        </div>
    )
}

export default PublicProjectDetailPage
