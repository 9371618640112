import React from 'react'
import './Loader.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface IconButtonProps {
    text?: string
}

const InfiniteListLoader: React.FC<IconButtonProps> = () => {
    return (
        <div className="infinite-list-bottom-loader">
            <div className="loader-spinner rotating">
                <FontAwesomeIcon icon={faSpinner} size="2x" color="#373e35" />
            </div>
        </div>
    )
}

export default InfiniteListLoader
