import { faExternalLinkSquareAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import { deleteContestResource, deleteProjectResource } from '../../resources/api-constants'
import { useAppSelector } from '../../store/reducers/store'
import { Resource } from '../../types/data'
import { ToastError, ToastSuccess } from '../../utility/toast'
import './EditResourceModal.sass'

interface EditResourceModalProps {
    isVisible: boolean
    projectCode: string
    resource: Resource | null
    isContest?: boolean
    onClose: () => void
    onSave: (editedResource: Resource | null, isDelete?: boolean) => void
}

const getResourceFileName = (resourceFile: string) => {
    return resourceFile.slice(resourceFile.lastIndexOf('/') + 1)
}

const EditResourceModal: React.FC<EditResourceModalProps> = (props) => {
    const user = useAppSelector((data) => data.user)
    const [resourceDescription, setResourceDescription] = useState('')

    useEffect(() => {
        if (!props.resource) {
            console.error('Internal Error: empty resource object.')
            props.onClose()
        } else {
            setResourceDescription(props.resource.description || '')
        }
    }, [props.resource])

    const saveEditedResource = () => {
        const editedResource: Resource = {
            ...props.resource!,
            description: resourceDescription,
        }
        props.onSave(editedResource)
    }

    const deleteCurrentResource = async () => {
        try {
            if (props.isContest) {
                await deleteContestResource(
                    user.accessToken,
                    props.projectCode,
                    props.resource ? props.resource.id : -1
                )
            } else {
                await deleteProjectResource(
                    user.accessToken,
                    props.projectCode,
                    props.resource ? props.resource.id : -1
                )
            }
            props.onSave(props.resource || null, true)
            ToastSuccess('Risorsa eliminata con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Non è stato possibile eliminare la risorsa.')
        }
    }

    const getResourceMediaFilePreview = (resourceFile: string) => {
        const extension = resourceFile.slice(resourceFile.lastIndexOf('.'))
        const filename = getResourceFileName(resourceFile)
        switch (extension) {
            case '.jpeg':
            case '.jpg':
            case '.png':
                return <img src={resourceFile} alt={filename} />
            case '':
                return
            default:
                return (
                    <div className="file-extension-display">
                        <span className="file-extension-display__extension">{extension.slice(1)}</span>
                    </div>
                )
        }
    }

    return (
        <Modal
            disableEscapeKeyDown
            open={props.isVisible}
            onClose={props.onClose}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div className="modal-box">
                {props.resource && (
                    <>
                        <h2>Modifica risorsa</h2>
                        <div className="modal-box__body">
                            <div className="resource-media-large-container">
                                {getResourceMediaFilePreview(props.resource ? props.resource.projectFile : '')}
                            </div>
                            <div className="resource-actions-list">
                                <span className="resource-actions-list__filename-element">
                                    {getResourceFileName(props.resource ? props.resource.projectFile : '')}
                                </span>
                                <span className="resource-actions-list__action">
                                    <FontAwesomeIcon icon={faExternalLinkSquareAlt} color="#aaa" />
                                    <a
                                        className="info-link-action"
                                        href={props.resource?.projectFile}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Apri in una nuova finestra
                                    </a>
                                </span>
                                <span
                                    className="resource-actions-list__action"
                                    onClick={() => void deleteCurrentResource()}
                                >
                                    <FontAwesomeIcon icon={faTrash} color="#aaa" />
                                    <div className="error-link-action">Elimina definitivamente la risorsa</div>
                                </span>
                                <TextInput
                                    label="DESCRIZIONE"
                                    value={resourceDescription}
                                    onChange={(newVal) => setResourceDescription(newVal)}
                                    isTextarea
                                    placeholder="Descrivi brevemente il contenuto di questa risorsa..."
                                />
                                <Button onClick={() => saveEditedResource()}>Salva</Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default EditResourceModal
