/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import '../../styles/pages/ContestCreationWidget.sass'
import { ContestSummary } from '../../types/contests'
import { getFinalPrice, getReportsOfTypology } from '../../resources/api-constants'
import Button from '../../components/Button/Button'
import { PriceSizeTypology, ReportTypology } from '../../types/data'
import ExtraReportSelector, {
    ExtraReportInfo,
    GroupedExtraReports,
} from '../../components/ExtraReportSelector/ExtraReportSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import StaticExtraReportSelector from '../../components/ExtraReportSelector/StaticExtraReportSelector'

interface Props {
    tipologySizeId: string
    loadingCreation: boolean
    contest: ContestSummary
    onConfirm: (priceObject: PriceSizeTypology, extraIDs: number[], baseReportObj: PriceSizeTypology) => void
}

const ContestRecap: React.FC<Props> = (props) => {
    const [contestPrice, setContestPrice] = useState<PriceSizeTypology | null>(null)
    const [fixedBasePrice, setFixedBasePrice] = useState(0)
    const [priceToDisplay, setPriceToDisplay] = useState(0)
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const [selectedExtraReports, setSelectedExtraReports] = useState<ReportTypology[] | null>(null)
    const [ungroupedAvailableExtraReports, setUngroupedAvailableExtraReports] = useState<ReportTypology[] | null>(null)
    const [availableExtraReports, setAvailableExtraReports] = useState<GroupedExtraReports | null>(null)
    const [selectedExtraReportsIDs, setSelectedExtraReportsIDs] = useState<ExtraReportInfo[]>([])

    const getContestCosts = async () => {
        try {
            const price = await getFinalPrice(props.tipologySizeId)
            if (price) {
                setContestPrice(price)
                setFixedBasePrice(price.price)
                setPriceToDisplay(price.price)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchExtraReports = async () => {
        try {
            if (!props.tipologySizeId) return
            const extraReports: ReportTypology[] | undefined = await getReportsOfTypology(
                props.tipologySizeId.toString()
            )
            if (extraReports && extraReports.length > 0) {
                const groupedReports = extraReports.reduce((reports, item) => {
                    reports[item.name] = reports[item.name] || []
                    reports[item.name].push(item)
                    return reports
                }, Object.create(null))
                setUngroupedAvailableExtraReports(extraReports)
                setAvailableExtraReports(groupedReports)
                const idArray = selectedExtraReportsIDs.map((extraReport) => extraReport.id)
                setSelectedExtraReports(
                    extraReports.filter((extraReport) => extraReport && idArray.includes(extraReport.reportType))
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void getContestCosts()
        void fetchExtraReports()
    }, [props.tipologySizeId])

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Costi totali</h1>
                <p>
                    Vogliamo essere trasparenti, ecco le informazioni sui costi relativi alla realizzazione del contest.
                </p>
            </div>
            <div className="contest-creation-items-column">
                <p>{contestPrice?.description}</p>
                <p className="big-text">Costo: {priceToDisplay} €</p>
            </div>
            <Button
                loading={props.loadingCreation}
                disabled={props.loadingCreation}
                onClick={() => {
                    if (contestPrice) {
                        props.onConfirm(
                            contestPrice,
                            selectedExtraReportsIDs.map((extraIDObj) => extraIDObj.id),
                            contestPrice
                        )
                    }
                }}
            >
                Conferma
            </Button>
            <div className="contest-creation-step__text-wrapper">
                <p>oppure richiedi altri elaborati</p>
            </div>
            <Button onClick={() => setModalIsVisible(true)}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
            <div>
                <StaticExtraReportSelector
                    selectedReports={
                        ungroupedAvailableExtraReports
                            ? ungroupedAvailableExtraReports
                                  .filter((extraReport) =>
                                      selectedExtraReportsIDs
                                          .map((extraRepID) => extraRepID.id)
                                          .includes(extraReport.reportType)
                                  )
                                  .map((report) => ({
                                      ...report,
                                      extra: 1,
                                      id: report.reportType,
                                      idContest: props.contest.idContest,
                                      annotation: report.descriptionTypology,
                                      description: report.descriptionTypology,
                                      name: report.nameTypology,
                                  }))
                            : []
                    }
                />
            </div>

            {modalIsVisible && (
                <ExtraReportSelector
                    availableExtraReports={availableExtraReports}
                    contestId={''}
                    selectedReports={
                        selectedExtraReports
                            ? selectedExtraReports.map((extraRep) => ({
                                  ...extraRep,
                                  extra: 1,
                                  id: extraRep.reportType,
                                  idContest: props.contest.idContest,
                                  annotation: extraRep.descriptionTypology,
                                  description: extraRep.descriptionTypology,
                                  name: extraRep.nameTypology,
                              }))
                            : []
                    }
                    idTypologySize={parseInt(props.tipologySizeId, 10)}
                    onSelectionChange={(newExtraIDs) => {
                        setSelectedExtraReportsIDs(
                            newExtraIDs.map((extraID) => ({ id: extraID.id, price: extraID.price }))
                        )
                        const extraIDsOnly = newExtraIDs.map((extraId) => extraId.id)
                        setSelectedExtraReports(
                            ungroupedAvailableExtraReports
                                ? ungroupedAvailableExtraReports.filter((availableExtra) =>
                                      extraIDsOnly.includes(availableExtra.reportType)
                                  )
                                : []
                        )
                        const additionalCosts = newExtraIDs
                            .map((extraID) => extraID.price)
                            .reduce((sum, priceValue) => sum + priceValue, 0)
                        setPriceToDisplay(fixedBasePrice + additionalCosts)
                        setModalIsVisible(false)
                    }}
                    onClose={() => setModalIsVisible(false)}
                />
            )}
        </div>
    )
}

export default ContestRecap
