import React, { useEffect, useState } from 'react'
import ProjectCard from '../../components/ProjectCard/ProjectCard'
import { ProjectSummary } from '../../types/projects'
import '../../styles/pages/projectsPage.sass'
import { getPaginatedProjectsList, getPaginatedProjectsSearch } from '../../resources/api-constants'
import { PaginatedResponse } from '../../types/requests'
import { useAppSelector } from '../../store/reducers/store'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import Button from '../../components/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import TagsContainer from '../../components/TagsContainer/TagsContainer'
import { Tag } from '../../types/data'
import InfiniteScrollList from '../../components/InfiniteScrollList/InfiniteScrollList'

interface ProjectsPageProps {
    isPublicPage?: boolean
}

const ProjectsPage: React.FC<ProjectsPageProps> = (props) => {
    const user = useAppSelector((state) => state.user)
    const data = useAppSelector((state) => state.data)
    const [projectsToDisplay, setProjectsToDisplay] = useState<ProjectSummary[]>([])
    const [availableProjectsLength, setAvailableProjectsLength] = useState<number | null>(null)
    const [hasMore, setHasMore] = useState(true)
    const [currentOffset, setCurrentOffset] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectedSearchTags, setSelectedSearchTags] = useState<Tag[]>([])
    const pageItemsSize = 12
    const navigate = useNavigate()

    useEffect(() => {
        let hasMoreItemsToDownload =
            !!availableProjectsLength &&
            projectsToDisplay.length > 0 &&
            projectsToDisplay.length < availableProjectsLength
        if (availableProjectsLength === null) {
            hasMoreItemsToDownload = true
        } else if (selectedSearchTags.length > 0 && props.isPublicPage) {
            hasMoreItemsToDownload = availableProjectsLength === 0
        }
        setHasMore(hasMoreItemsToDownload)
    }, [projectsToDisplay, availableProjectsLength, selectedSearchTags.length, props.isPublicPage])

    const fetchMoreProjects = async (resetCounters?: boolean) => {
        try {
            const offset = resetCounters ? 0 : currentOffset
            setLoading(true)
            if (!resetCounters) {
                if (!hasMore) return
            }
            let projects: PaginatedResponse<ProjectSummary> | undefined
            if (selectedSearchTags.length > 0) {
                projects = await getPaginatedProjectsSearch(
                    user.accessToken,
                    pageItemsSize,
                    offset,
                    selectedSearchTags.map((tag) => tag.keyTag).join(','),
                    props.isPublicPage
                )
            } else {
                projects = await getPaginatedProjectsList(user.accessToken, pageItemsSize, offset, props.isPublicPage)
            }
            if (!projects) return

            const finalProjects = resetCounters ? [...projects.data] : [...projectsToDisplay, ...projects.data]

            setCurrentOffset(finalProjects.length)
            setAvailableProjectsLength(projects.totalRecordCount)
            setProjectsToDisplay(finalProjects)

            setLoading(false)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const resetProjectListParams = () => {
        setProjectsToDisplay([])
        setCurrentOffset(0)
        setAvailableProjectsLength(0)
    }

    const launchProjectSearch = async () => {
        if (!availableProjectsLength) return
        await fetchMoreProjects(true)
    }

    useEffect(() => {
        if (selectedSearchTags.length) {
            resetProjectListParams()
            void launchProjectSearch()
        } else {
            void fetchMoreProjects(true)
        }
    }, [selectedSearchTags])

    useEffect(() => {
        void fetchMoreProjects()
    }, [])

    return (
        <InfiniteScrollList
            fetchMore={() => {
                if (loading) return
                void fetchMoreProjects()
            }}
            currentDataLength={projectsToDisplay.length}
            hasMore={hasMore}
            isLoading={loading}
            isPrivatePage={!props.isPublicPage}
            emptyListMessage={
                props.isPublicPage ? 'Non ci sono ancora progetti da mostrare.' : 'Non hai ancora creato un progetto.'
            }
        >
            <div className="page-title-container">
                <span className="page-title">{props.isPublicPage ? 'Progetti' : 'I miei progetti'}</span>
            </div>
            <div className="page-list-actions-header">
                {props.isPublicPage ? (
                    // <Search
                    //     onQueryChange={(q) => {
                    //         setQuerySearch(q)
                    //     }}
                    // />
                    <TagsContainer
                        options={data.tags}
                        selectedTags={selectedSearchTags}
                        onTagsChange={(newSearchTags) => setSelectedSearchTags(newSearchTags)}
                    />
                ) : (
                    <Button onClick={() => navigate(`${ROUTES.PERSONAL_GENERIC_PROJECT_DETAIL_ROUTE}new-project`)}>
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                        Crea progetto
                    </Button>
                )}
            </div>
            <div className={`scroll-list-container ${props.isPublicPage ? 'public-compact-list-container' : ''}`}>
                <div className="grid-scroll-list">
                    {projectsToDisplay.map((projectInfo) => (
                        <ProjectCard
                            onClick={(idProject) => {
                                if (props.isPublicPage) {
                                    navigate(`${ROUTES.PROJECT_DETAIL_ROUTE}${idProject}`, {
                                        state: { preview: true, idProject },
                                    })
                                } else {
                                    navigate(`${ROUTES.PERSONAL_GENERIC_PROJECT_DETAIL_ROUTE}${idProject}`, {
                                        state: { preview: true, idProject },
                                    })
                                }
                            }}
                            projectInfo={projectInfo}
                            key={projectInfo.id}
                            isPublic={props.isPublicPage}
                        />
                    ))}
                </div>
            </div>
        </InfiniteScrollList>
    )
}

export default ProjectsPage
