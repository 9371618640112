import React, { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import PasswordInput from '../../components/PasswordInput/PasswordInput'
import TextInput from '../../components/TextInput/TextInput'
import { userSignIn } from '../../resources/api-constants'
import { mailRegex } from '../../resources/other-constants'
import { ROUTES } from '../../resources/routes-constants'
import { UserSigninCredentials } from '../../types/requests'

enum userTypes {
    CUSTOMER = 'CUSTOMER',
    DESIGNER = 'DESIGNER',
    DESIGNER_PRIME = 'DESIGNER_PRIME',
}

const RegisterPage: React.FC = () => {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [signinError, setSigninError] = useState('')
    const [userType, setUserType] = useState(userTypes.DESIGNER)
    const [passwordIsVisible, setPasswordIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const incorrectData = useMemo((): boolean => {
        const fieldsToCheck = [email, username, password, passwordConfirm]
        const failedPassCheck = password !== passwordConfirm
        return fieldsToCheck.findIndex((field) => field === '') !== -1 || failedPassCheck || !mailRegex.test(email)
    }, [email, username, password, passwordConfirm])

    const getUserTypeId = (type: userTypes) => {
        switch (type) {
            case userTypes.DESIGNER:
                return 7
            case userTypes.DESIGNER_PRIME:
                return 6
            case userTypes.CUSTOMER:
                return 10
            default:
                return -1
        }
    }

    const performUserSignin = async () => {
        try {
            setIsLoading(true)
            const signInData: UserSigninCredentials = {
                username,
                email,
                password,
                passwordConfirm,
                idSysGrant: getUserTypeId(userType as userTypes),
            }
            const validOperation = await userSignIn(signInData)
            if (!validOperation) throw validOperation
            setSigninError('')
            navigate(ROUTES.LOGIN_ROUTE, { state: { showSuccessMessage: true } })
        } catch (error) {
            console.error(error)
            setSigninError('Errore: controlla le informazioni inserite e riprova.')
        }
        setIsLoading(false)
    }

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div className="auth-inputs-container">
                <h1 style={{ fontSize: '2em' }}>Registrazione</h1>
                <div className="input-centered-row user-type-selection-row">
                    <label>Sono un</label>
                    <div className="input-centered-row">
                        <div
                            onClick={() => setUserType(userTypes.DESIGNER)}
                            className={`user-role-selection-chip ${userType === userTypes.DESIGNER && 'selected-role'}`}
                        >
                            Designer
                        </div>
                        <div
                            onClick={() => setUserType(userTypes.CUSTOMER)}
                            className={`user-role-selection-chip ${userType === userTypes.CUSTOMER && 'selected-role'}`}
                        >
                            Cliente
                        </div>
                    </div>
                </div>
                <TextInput label="Username" value={username} onChange={(newVal) => setUsername(newVal)} />
                <TextInput label="Email" value={email} onChange={(newVal) => setEmail(newVal)} />
                <PasswordInput
                    label="Password"
                    type={passwordIsVisible ? 'text' : 'password'}
                    value={password}
                    displayIcon
                    autocomplete={false}
                    revealPassword={passwordIsVisible}
                    onChange={(newVal) => setPassword(newVal)}
                    onIconClick={(val) => setPasswordIsVisible(val)}
                />
                <TextInput
                    label="Ripeti password"
                    type={passwordIsVisible ? 'text' : 'password'}
                    value={passwordConfirm}
                    onChange={(newVal) => setPasswordConfirm(newVal)}
                />
                <p>
                    La nuova password deve essere lunga almeno 8 caratteri e al massimo 40 e deve contenere almeno una
                    lettera maiuscola, una minuscola, un numero e un simbolo (.!@#$%^&*_-+=|?).
                </p>
                {signinError && <div className="error-container">{signinError}</div>}
                <Button disabled={isLoading || incorrectData} onClick={async () => void performUserSignin()}>
                    {isLoading ? 'Registrazione in corso...' : 'Registrati'}
                </Button>
                <Link className="auth-link" to={ROUTES.LOGIN_ROUTE}>
                    Accedi
                </Link>
            </div>
        </div>
    )
}

export default RegisterPage
