import React, { useEffect, useState } from 'react'
import '../../../styles/pages/projectsPage.sass'
import { getPaginatedDesignersList, getPaginatedDesignersSearch } from '../../../resources/api-constants'
import { PaginatedResponse } from '../../../types/requests'
import { useAppSelector } from '../../../store/reducers/store'
import { DesignerProfile } from '../../../types/user'
import DesignerCard from '../../../components/DesignerCard/DesignerCard'
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'

const DesignersPage: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const [designersToDisplay, setDesignersToDisplay] = useState<DesignerProfile[]>([])
    const [availableDesignersLength, setAvailableDesignersLength] = useState<number | null>(null)
    const [currentOffset, setCurrentOffset] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const pageItemsSize = 12
    const querySearch = ''
    const navigate = useNavigate()

    useEffect(() => {
        let hasMoreItemsToDownload =
            !!availableDesignersLength &&
            designersToDisplay.length > 0 &&
            designersToDisplay.length < availableDesignersLength
        if (availableDesignersLength === null) {
            hasMoreItemsToDownload = true
        }
        setHasMore(hasMoreItemsToDownload)
    }, [designersToDisplay, availableDesignersLength])

    const fetchMoreProjects = async () => {
        try {
            setLoading(true)
            if (!hasMore) return
            let designers: PaginatedResponse<DesignerProfile> | undefined
            if (querySearch !== '') {
                designers = await getPaginatedDesignersSearch(
                    user.accessToken,
                    pageItemsSize,
                    currentOffset,
                    querySearch
                )
            } else {
                designers = await getPaginatedDesignersList(user.accessToken, pageItemsSize, currentOffset)
            }
            if (!designers) return

            const finalDesigners = [...designersToDisplay, ...designers.data]

            setCurrentOffset(finalDesigners.length)
            setAvailableDesignersLength(designers.totalRecordCount)
            setDesignersToDisplay(finalDesigners)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const resetProjectListParams = () => {
        setDesignersToDisplay([])
        setCurrentOffset(0)
        setAvailableDesignersLength(0)
    }

    const launchProjectSearch = async () => {
        if (!availableDesignersLength || loading) return
        await fetchMoreProjects()
    }

    useEffect(() => {
        resetProjectListParams()
        void launchProjectSearch()
    }, [querySearch])

    useEffect(() => {
        void fetchMoreProjects()
    }, [])

    return (
        <InfiniteScrollList
            fetchMore={() => {
                if (loading) return
                void fetchMoreProjects()
            }}
            hasMore={hasMore}
            isLoading={loading}
            currentDataLength={designersToDisplay.length}
            emptyListMessage="Non ci sono ancora designer da mostrare."
        >
            <div className="page-title-container">
                <span className="page-title">Designer</span>
            </div>
            <div className="empty-space-divider" />
            {/* <div className="page-list-actions-header">
                <Search
                    onQueryChange={(q) => {
                        setQuerySearch(q)
                    }}
                />
            </div> */}
            <div className="scroll-list-container public-compact-list-container">
                <div className="grid-scroll-list">
                    {designersToDisplay.map((designerInfo) => (
                        <DesignerCard
                            key={designerInfo.id}
                            designer={designerInfo}
                            onClick={() =>
                                navigate(`${ROUTES.DESIGNER_DETAIL}${designerInfo.idDesigner}`, {
                                    state: { designerName: designerInfo.name, designerId: designerInfo.idDesigner },
                                })
                            }
                        />
                    ))}
                </div>
            </div>
        </InfiniteScrollList>
    )
}

export default DesignersPage
