import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from '@material-ui/core'
import React, { useEffect, useId, useMemo, useRef, useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import { uploadFile } from '../../resources/api-constants'
import { ResourcesTypes, urlRegex } from '../../resources/other-constants'
import { useAppSelector } from '../../store/reducers/store'
import { handleFileUploadFromInput } from '../../utility/functions'
import './LoadResourceModal.sass'

export interface NewResource {
    idTypeResource: number
    projectFile: string
    typeResource: ResourcesTypes
    visible: number
    description: string
    dataCreations?: string
    id?: number
}

interface LoadResourceModalProps {
    isVisible: boolean
    resourceTypeId: number
    projectCode: string
    onClose: () => void
    onSave: (editedResource: NewResource) => void
}

// const getResourceFileName = (resourceFile: string) => {
//     return resourceFile.slice(resourceFile.lastIndexOf('/') + 1)
// }

const maxAllowedFileSize = 8388608

const LoadResourceModal: React.FC<LoadResourceModalProps> = (props) => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [currentResourceFile, setCurrentResourceFile] = useState('')
    const [resourceDescription, setResourceDescription] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const modalFileInputId = useId()
    const currentResourceRef = useRef<any>(null)

    const availableFileExtension = useMemo((): string => {
        if (props.resourceTypeId === undefined || props.resourceTypeId < 0) return '*'
        const foundResourceType = data.resourceTypes.find((resourceType) => resourceType.id === props.resourceTypeId)
        if (foundResourceType) {
            return foundResourceType.extension
                .split(', ')
                .map((extension) => {
                    if (extension === 'image') return 'image/png, image/jpeg, image/jpg'
                    return ` .${extension}`
                })
                .join(',')
        }
        return '*'
    }, [props.resourceTypeId])

    useEffect(() => {
        setResourceDescription('')
    }, [])

    const saveEditedResource = async () => {
        setIsLoading(true)
        try {
            let finalResourceURL = ''
            if (currentResourceFile !== '' && currentResourceFile !== null && !urlRegex.test(currentResourceFile)) {
                const resourceFormData = new FormData()
                resourceFormData.append('file', currentResourceFile)
                resourceFormData.append('typeResource', props.resourceTypeId.toString())
                resourceFormData.append('code', props.projectCode || '')
                finalResourceURL = await uploadFile(user.accessToken, resourceFormData)
            }
            if (finalResourceURL === '') throw new Error("Error file uplad: couldn't retrieve final URL")
            const editedResource: NewResource = {
                idTypeResource: props.resourceTypeId,
                projectFile: finalResourceURL,
                typeResource: ResourcesTypes.PROJECT_FILE,
                visible: 1,
                description: resourceDescription,
            }
            props.onSave(editedResource)
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const resetAndClose = () => {
        setCurrentResourceFile('')
        setResourceDescription('')
        currentResourceRef.current = null
        props.onClose()
    }

    const getFileExtension = (resourceFile: string): string => {
        return resourceFile.slice(resourceFile.lastIndexOf('.'))
    }

    const isImageFile = (extension: string) => {
        return ['.jpeg', '.jpg', '.png'].includes(extension)
    }

    return (
        <Modal
            disableEscapeKeyDown
            open={props.isVisible}
            onClose={resetAndClose}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div className="modal-box">
                <h2>Carica risorsa</h2>
                <div className="modal-box__body">
                    <div className="resource-media-large-container">
                        <div className="resource-media-uploader">
                            <label
                                className={`resource-media-uploader__cover ${
                                    currentResourceFile !== '' ? 'dynamic-cover' : ''
                                }`}
                                htmlFor={modalFileInputId}
                            >
                                <FontAwesomeIcon icon={faUpload} />
                                <div style={{ textAlign: 'center' }}>
                                    {currentResourceFile === ''
                                        ? 'Clicca qui per aggiungere un file. La dimensione massima consentita è di 8MB.'
                                        : 'Clicca qui per scegliere un nuovo file. La dimensione massima consentita è di 8MB.'}
                                </div>
                            </label>
                            <input
                                style={{ display: 'none' }}
                                id={modalFileInputId}
                                type="file"
                                onChange={(e) => {
                                    const fileSize = e.currentTarget.files ? e.currentTarget.files[0].size : 0
                                    if (fileSize < maxAllowedFileSize) {
                                        handleFileUploadFromInput(e, setCurrentResourceFile, currentResourceRef)
                                    }
                                }}
                                // accept=".doc, .docx, .pdf, image/png, image/jpeg, image/jpg, .mpeg, .mp4, .mov, .dxf, .dwg"
                                accept={availableFileExtension}
                            />
                            <img src="" ref={currentResourceRef} alt="" style={{ borderCollapse: 'collapse' }} />
                            {currentResourceFile !== '' &&
                                !isImageFile(getFileExtension((currentResourceFile as any).name)) && (
                                    <div className="file-extension-tag">
                                        {getFileExtension((currentResourceFile as any).name).slice(1)}
                                    </div>
                            )}
                        </div>
                    </div>
                    <div className="resource-actions-list">
                        {currentResourceFile !== '' && (
                            <div className="current-resource-file-name">{(currentResourceFile as any).name}</div>
                        )}
                        <TextInput
                            label="DESCRIZIONE"
                            value={resourceDescription}
                            onChange={(newVal) => setResourceDescription(newVal)}
                            isTextarea
                            placeholder="Descrivi brevemente il contenuto di questa risorsa..."
                        />
                        <Button
                            disabled={!currentResourceRef || !currentResourceRef.current || isLoading}
                            onClick={() => void saveEditedResource()}
                            loading={isLoading}
                        >
                            Salva
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default LoadResourceModal
