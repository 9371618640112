import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import InfiniteListLoader from '../../components/Loader/InfiniteListLoader'
import { getSingleContest, getSingleContestPlacementQuotes } from '../../resources/api-constants'
import { ROUTES } from '../../resources/routes-constants'
import { useAppSelector } from '../../store/reducers/store'
import { ContestSummary } from '../../types/contests'
import { PlacementQuote } from '../../types/user'
import { sectionObject } from '../../types/data'
import TabsSelector from '../../components/TabsSelector/TabsSelector'
import PublicContestInfoSections from './PublicContestDetailSections/PublicContestInfoSections'
import PublicContestParticipationsSection from './PublicContestDetailSections/PublicContestParticipationsSection'
import ContestMessagesSection from './ContestDetailSections/ContestMessagesSection'

const emptyContest: ContestSummary = {
    ID: -1,
    idContest: '',
    idUser: '',
    title: '',
    description: '',
    dataCreations: '',
    dataStart: '',
    dataEnd: '',
    price: '',
    amountPrize: '',
    ready: 0,
    solved: 0,
    status: 0,
    internalAnnotations: '',
    externalAnnotation: '',
    localization: '',
    category: 0,
    typology: 0,
    MQ: 0,
    tags: [],
    public: 0,
    designerSubscribers: 0,
    designerCompleted: 0,
    expired: 0,
    request: [],
    idTypologySize: 0,
    resource: [],
    myPresentation: 0,
    mySubscribe: 0,
    tempNameDesigner: '',
    remainingDays: null,
    zipFile: '',
}

const PublicContestDetailPage: React.FC = () => {
    const data = useAppSelector((state) => state.data)
    const user = useAppSelector((state) => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [currentContestInfo, setCurrentContestInfo] = useState<ContestSummary>(emptyContest)
    const [placementQuotes, setPlacementQuotes] = useState<PlacementQuote | null>(null)
    // const [currentProjectCover, setCurrentProjectCover] = useState<Resource | null>(null)
    const [selectedSectionId, setSelectedSectionId] = useState('info')
    const { state } = useLocation()

    const fetchProjectData = async () => {
        try {
            setIsLoading(true)
            const project = await getSingleContest(user.accessToken, state.idContest)
            const quotes = await getSingleContestPlacementQuotes(user.accessToken, state.idContest)
            if (project) {
                setCurrentContestInfo(project)
            }
            if (quotes) {
                setPlacementQuotes(quotes)
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (state !== null && state.idContest !== undefined) {
            void fetchProjectData()
        }
    }, [state])

    const contestSections = useMemo((): sectionObject[] => {
        const finalSections: sectionObject[] = []
        const designerSections: sectionObject[] = [
            {
                id: 'info',
                label: 'Informazioni',
            },
        ]
        if (currentContestInfo.status > 1) {
            // Se è un designer o designer-prime
            if (user.userInfo?.idSysGrant === 6 || user.userInfo?.idSysGrant === 7) {
                finalSections.push(...designerSections)
                if (currentContestInfo.status >= 2 && currentContestInfo.mySubscribe === 1) {
                    finalSections.push(
                        {
                            id: 'messages',
                            label: 'Messaggi',
                        },
                        {
                            id: 'participations',
                            label: 'Partecipazioni',
                        }
                    )
                }
            }
        }
        return finalSections
    }, [user, currentContestInfo])

    const getStatusLabel = (currentStatus: number) => {
        if (currentStatus === null || currentStatus === undefined) return ''
        let stat = ''
        stat = data.contestStatusTypes.find((statusType) => statusType.Value === currentStatus)?.statusName || ''

        // TODO: rimuovere fallback per customer che non riescono a scaricare tutti gli stauts con il loro token
        if (!stat) {
            switch (currentStatus) {
                case 0:
                    stat = 'DRAFT'
                    break
                case 1:
                    stat = 'READY TO PAY'
                    break
                default:
                    stat = ''
                    break
            }
        }
        return stat
    }

    const renderActiveSection = useMemo(() => {
        switch (selectedSectionId) {
            case 'info':
                return <PublicContestInfoSections contest={currentContestInfo} placementQuotes={placementQuotes} />
            case 'participations':
                return <PublicContestParticipationsSection contest={currentContestInfo} />
            case 'messages':
                return <ContestMessagesSection isPublicPage currentContest={currentContestInfo} />
            default:
                return
        }
    }, [selectedSectionId, isLoading, currentContestInfo, placementQuotes])

    return (
        <div className="page-main-container detail-page">
            <div className="page-main-header">
                <div className="page-title-container">
                    <span className="page-title">
                        <label className="page-title__link-text">
                            <Link to={ROUTES.PERSONAL_CONTESTS_ROUTE}>Contest</Link> /
                        </label>
                        {currentContestInfo ? currentContestInfo.title : ''}
                        {currentContestInfo && (
                            <>
                                <label className="project-status-label">{currentContestInfo.idContest}</label>
                                <label className="project-status-label">
                                    {currentContestInfo.public ? 'Pubblico' : 'Privato'}
                                </label>
                                <label className="project-status-label">
                                    {getStatusLabel(currentContestInfo.status)}
                                </label>
                            </>
                        )}
                    </span>
                </div>
            </div>
            <div className="page-actions-container" style={{ marginTop: 24 }}>
                <TabsSelector
                    options={contestSections}
                    selectedTabId={selectedSectionId}
                    onSelectedTabChange={(newTab) => setSelectedSectionId(newTab)}
                />
            </div>
            {currentContestInfo && !isLoading ? (
                <>{renderActiveSection}</>
            ) : (
                <InfiniteListLoader text="Sto caricando il progetto" />
            )}
        </div>
    )
}

export default PublicContestDetailPage
