import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../resources/images/drawup_text_logo.png'
import { ROUTES } from '../../resources/routes-constants'
import { LoggedUserInfo } from '../../types/user'
import './PublicHeader.sass'

interface PublicHeaderProps {
    loggedUser?: LoggedUserInfo
}

const PublicHeader: React.FC<PublicHeaderProps> = (props) => {
    const [linksAreHidden, setLinksAreHidden] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setLinksAreHidden(location.pathname.includes(ROUTES.GENERATE_CONTESTS_ROUTE))
    }, [location])

    return (
        <div className="public-header">
            <div className="public-links-header">
                <div className="drawup-text-logo-container" onClick={() => navigate(ROUTES.HOMEPAGE_ROUTE)}>
                    <img src={logo} alt="DrawUp" />
                </div>
                {!linksAreHidden && (
                    <div className="public-links-container">
                        <NavLink to={ROUTES.HOMEPAGE_ROUTE} className="public-link">
                            Homepage
                        </NavLink>
                        <NavLink to={ROUTES.HOW_IT_WORKS} className="public-link">
                            Come funziona
                        </NavLink>
                        {/* <NavLink to={ROUTES.PROJECTS_ROUTE} className="public-link">
                        Progetti
                    </NavLink> */}
                        <NavLink to={ROUTES.DESIGNERS_ROUTE} className="public-link">
                            Designer
                        </NavLink>
                        <NavLink to={ROUTES.CALCULATE_CONTEST_PRICES_ROUTE} className="public-link">
                            Prezzi
                        </NavLink>
                        <NavLink
                            to={
                                props.loggedUser
                                    ? props.loggedUser.idSysGrant === 10 || props.loggedUser.idSysGrant === 0
                                        ? ROUTES.PERSONAL_CONTESTS_ROUTE
                                        : ROUTES.PERSONAL_PROJECTS_ROUTE
                                    : ROUTES.LOGIN_ROUTE
                            }
                            className="public-link"
                        >
                            Area personale
                        </NavLink>
                        <NavLink to={ROUTES.GENERATE_CONTESTS_ROUTE} className="public-link highlighted-link">
                            Crea un contest
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PublicHeader
