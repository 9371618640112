/* eslint-disable indent */
import { faHome, faTrophy, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import { useAppSelector } from '../../store/reducers/store'
import './MainLayout.sass'

interface MainLayoutProps {
    children: ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
    // const graphics = useAppSelector((data) => data.graphics)
    const user = useAppSelector((data) => data.user)
    // const [drawerIsExpanded, setDrawerIsExpanded] = useState(!graphics.drawerIsClosed)
    const currentHour = moment().hour()

    const welcomeMessage = useMemo(() => {
        const welcomeArray = ['Buongiorno', 'Buon pomeriggio', 'Buonasera']
        const selectedWelcomeValue =
            currentHour >= 5 && currentHour <= 12
                ? welcomeArray[0]
                : currentHour >= 12 && currentHour <= 18
                ? welcomeArray[1]
                : welcomeArray[2]
        return `${selectedWelcomeValue} ${user.userInfo?.username}`
    }, [user.userInfo, currentHour])

    const availableRoutes = useMemo(() => {
        let routes = [
            {
                name: 'Contest',
                route: ROUTES.PERSONAL_CONTESTS_ROUTE,
                icon: faTrophy,
            },
            {
                name: 'Profilo',
                route: ROUTES.PROFILE_ROUTE,
                icon: faUserCircle,
            },
        ]
        if (user.userInfo?.idSysGrant === 7 || user.userInfo?.idSysGrant === 6) {
            routes = [
                {
                    name: 'Progetti',
                    route: ROUTES.PERSONAL_PROJECTS_ROUTE,
                    icon: faHome,
                },
                ...routes,
            ]
        }
        return routes
    }, [user.userInfo])

    return (
        <div className="main-layout-container">
            <div className="main-layout-header-wrapper">
                <div className="main-layout-header">
                    <div className="welcome-message-text">{welcomeMessage}</div>
                    <div className="header-right-section">
                        <div className="header-pages-links">
                            {availableRoutes.map((availableRoute) => {
                                return (
                                    <NavLink
                                        className="available-route-element"
                                        key={availableRoute.name}
                                        to={availableRoute.route}
                                    >
                                        <FontAwesomeIcon
                                            aria-label={availableRoute.name}
                                            icon={availableRoute.icon}
                                            color="#fff"
                                            size="lg"
                                        />
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-layout-body">
                {/* <Drawer onExpansionChange={(newStatus) => setDrawerIsExpanded(newStatus)} /> */}
                <div className="children-panel-container">{props.children}</div>
            </div>
        </div>
    )
}

export default MainLayout
