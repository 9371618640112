/* eslint-disable no-restricted-syntax */
import React, { useEffect, useId, useMemo, useRef, useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import { CustomerProfile, DesignerProfile, ExperienceInstance } from '../../types/user'
import '../../styles/pages/profilePage.sass'
import {
    updateDesignerInfo,
    updateUserInfo,
    updateUserPassword,
    updateUserPaymentInfo,
    uploadFile,
} from '../../resources/api-constants'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { ChangePasswordRequestBody, UpdateUserPaymentBody } from '../../types/requests'
import { ibanRegex, mailRegex, ResourcesTypes, urlRegex, USER_GRANTS } from '../../resources/other-constants'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { logout } from '../../store/actions/user'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { handleFileUploadFromInput } from '../../utility/functions'
import { Tag } from '../../types/data'
import pkg from './../../../package.json'
import { ToastError, ToastSuccess } from '../../utility/toast'

interface ProfileInfoProps {
    profileInfo: DesignerProfile | CustomerProfile | null
    profileType: USER_GRANTS
    sendUpdateUser: () => void
}

// interface ProfileAdditionalInfoProps {
//     type: 'job' | 'edu'
//     experiences: ExperienceInstance[]
//     onExperiencesSaved: (newExperiences: ExperienceInstance[]) => void
// }

// interface LocalExperienceInstance extends ExperienceInstance {
//     tempId?: number
// }

// const emptyExperience: ExperienceInstance = {
//     start: '',
//     end: '',
//     role: '',
//     company: '',
//     notes: '',
// }

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
    const user = useAppSelector((state) => state.user)
    const data = useAppSelector((state) => state.data)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [userAvatar, setUserAvatar] = useState('')
    const [userCover, setUserCover] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [ibanUser, setIbanUser] = useState('')
    const [ibanCode, setIbanCode] = useState('')
    const [newConfirmPassword, setNewConfirmPassword] = useState('')
    const [userSkills, setUserSkills] = useState<Tag[]>([])
    const [jobExperiences, setJobExperiences] = useState<ExperienceInstance[]>([])
    const [educationExperiences, setEducationExperiences] = useState<ExperienceInstance[]>([])
    const [billingFieldsAreLocked, setBillingFieldsAreLocked] = useState(false)
    const [isDesigner, setIsDesigner] = useState(false)
    const [idUser, setIdUser] = useState(-1)
    const [changeProfileInfoLoading, setChangeProfileInfoLoading] = useState(false)
    const [changePassLoading, setChangePassLoading] = useState(false)
    const [changeBillingInfoLoading, setChangeBillingInfoLoading] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const id = useId()
    const userLogo = useRef<any>(null)
    const userBanner = useRef<any>(null)

    useEffect(() => {
        if (!props.profileInfo || props.profileInfo.idSysGrant === 0) return
        const profileInfo = props.profileInfo
        setIdUser(profileInfo.id)
        setIsDesigner(props.profileType === USER_GRANTS.DESIGNER)
        setName(profileInfo.name)
        setEmail(profileInfo.email)
        setAddress(profileInfo.address)
        setPhoneNumber(profileInfo.phoneNumber)
        setUserAvatar((profileInfo as DesignerProfile).photoProfile || '')
        setUserCover((profileInfo as DesignerProfile).photoBanner || '')
        setUserSkills((profileInfo as DesignerProfile).skill || [])
        if (profileInfo.myBillingInfo) {
            setIbanUser(JSON.parse(profileInfo.myBillingInfo.information).name)
            setIbanCode(JSON.parse(profileInfo.myBillingInfo.information).iban)
        }
        const curricula = (profileInfo as DesignerProfile).curricula
        if (!curricula) return
        if (curricula.education) {
            setEducationExperiences(curricula.education)
        }
        if (curricula.jobs) {
            setJobExperiences(curricula.jobs)
        }
    }, [])

    useEffect(() => {
        if (isDesigner) {
            const designerProfile = props.profileInfo as DesignerProfile
            if (designerProfile.curricula) {
                setJobExperiences(designerProfile.curricula.jobs)
                setEducationExperiences(designerProfile.curricula.education)
            }
        }
    }, [isDesigner])

    const resetUpdatePassFields = () => {
        setOldPassword('')
        setNewPassword('')
        setNewConfirmPassword('')
    }

    const updatePassword = async () => {
        setChangePassLoading(true)
        try {
            const updatePasswordData: ChangePasswordRequestBody = {
                oldPassword,
                newPassword,
                newPasswordConfirm: newConfirmPassword,
            }
            await updateUserPassword(user.accessToken, updatePasswordData)
            resetUpdatePassFields()
            ToastSuccess('Password aggiornata con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Non è stato possibile aggiornare la password.')
        }
        setChangePassLoading(false)
    }

    const getResourceTypeId = (type: ResourcesTypes) => {
        const foundType = data.resourceTypes.find((resType) => resType.Descrizione === type)
        if (!foundType) return '-1'
        return foundType.id.toString()
    }

    const updateProfileInfo = async () => {
        setChangeProfileInfoLoading(true)
        let userAvatarImage = ''
        let userBannerImage = ''
        const finalJobExperiences = jobExperiences.filter((exp: { [index: string]: any }) => {
            if (!exp.end) exp.end = 'present'
            return Object.keys(exp)
                .filter((key) => key !== 'notes')
                .every((key: string) => (exp[key] as string) !== '')
        })
        const finalEducationExperiences = educationExperiences.filter((exp: { [index: string]: any }) => {
            if (!exp.end) exp.end = 'present'
            return Object.keys(exp)
                .filter((key) => key !== 'notes')
                .every((key: string) => (exp[key] as string) !== '')
        })
        if (userAvatar !== '' && userAvatar !== null && !urlRegex.test(userAvatar)) {
            const avatarFormData = new FormData()
            avatarFormData.append('file', userAvatar)
            avatarFormData.append('typeResource', getResourceTypeId(ResourcesTypes.PHOTO_PROFILE_DESIGNER))
            avatarFormData.append('code', user.userInfo?.codeDrawup || '')
            userAvatarImage = await uploadFile(user.accessToken, avatarFormData)
        }
        if (userCover !== '' && userCover !== null && !urlRegex.test(userCover)) {
            const bannerFormData = new FormData()
            bannerFormData.append('file', userCover)
            bannerFormData.append('typeResource', getResourceTypeId(ResourcesTypes.PHOTO_BANNER_DESIGNER))
            bannerFormData.append('code', user.userInfo?.codeDrawup || '')
            userBannerImage = await uploadFile(user.accessToken, bannerFormData)
        }
        try {
            if (user.userInfo && (user.userInfo.idSysGrant === 6 || user.userInfo.idSysGrant === 7)) {
                const profileData: {
                    id: number
                    idDesigner?: string
                    name: string
                    email: string
                    phoneNumber: string
                    address: string
                    photoProfile: string
                    photoBanner: string
                    curricula?: string
                    skill: string
                } = {
                    id: idUser,
                    idDesigner: user.userInfo?.codeDrawup || '',
                    name,
                    email,
                    phoneNumber,
                    address,
                    photoProfile: userAvatarImage || userAvatar,
                    photoBanner: userBannerImage || userCover,
                    curricula: JSON.stringify({
                        jobs: finalJobExperiences || [],
                        education: finalEducationExperiences || [],
                    }),
                    skill: userSkills.map((skill) => skill.keyTag).join(','),
                }
                if (profileData.curricula === '') {
                    delete profileData.curricula
                }
                await updateDesignerInfo(user.accessToken, profileData).catch(() => {
                    throw new Error('Non è stato possibile aggiornare le informazioni.')
                })
            } else {
                const profileData: {
                    id: number
                    name: string
                    email: string
                    phoneNumber: string
                    address: string
                } = {
                    id: idUser,
                    name,
                    email,
                    phoneNumber,
                    address,
                }
                await updateUserInfo(user.accessToken, profileData).catch(() => {
                    throw new Error('Non è stato possibile aggiornare le informazioni.')
                })
            }

            props.sendUpdateUser()
            ToastSuccess('Informazioni aggiornate con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Non è stato possibile aggiornare le informazioni.')
        }
        setChangeProfileInfoLoading(false)
    }

    const updateUserBillingInfo = async () => {
        setChangeBillingInfoLoading(true)
        try {
            const billingData: UpdateUserPaymentBody = {
                codeDrawup: user.userInfo?.codeDrawup || '',
                information: JSON.stringify({
                    iban: ibanCode,
                    name: ibanUser,
                }),
            }
            await updateUserPaymentInfo(user.accessToken, billingData)
            setBillingFieldsAreLocked(true)
            ToastSuccess('Informazioni fiscali aggiornate con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Non è stato possibile aggiornare le informazioni fiscali.')
        }
        setChangeBillingInfoLoading(false)
    }

    const profileInfoDataIsCorrect = useMemo(() => {
        return [name, email].every((val) => val !== '') && mailRegex.test(email)
    }, [name, email])

    const billingDataIsCorrect = useMemo(() => {
        return [ibanCode, ibanUser].every((val) => val !== '') && ibanRegex.test(ibanCode)
    }, [ibanCode, ibanUser])

    const updatePassDataIsCorrect = useMemo(() => {
        return [oldPassword, newPassword, newConfirmPassword].every((val) => val !== '')
    }, [oldPassword, newPassword, newConfirmPassword])

    return (
        <div className="profile-section">
            <div className="profile-section__wrapper">
                {(user.userInfo?.idSysGrant === 6 ||
                    user.userInfo?.idSysGrant === 7 ||
                    user.userInfo?.idSysGrant === 10) && (
                    <>
                        {(user.userInfo?.idSysGrant === 6 || user.userInfo?.idSysGrant === 7) && (
                            <div className="profile-section__wrapper__images-section">
                                <div className="section-title">Foto</div>
                                <div className="user-cover-wrapper">
                                    <label className="edit-cover" htmlFor={id}>
                                        <FontAwesomeIcon icon={faPen} size="2x" />
                                    </label>
                                    <input
                                        style={{ display: 'none' }}
                                        id={id}
                                        type="file"
                                        onChange={(e) => handleFileUploadFromInput(e, setUserCover, userBanner)}
                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                    />
                                    {userCover ? (
                                        <img src={userCover} alt={name} ref={userBanner} />
                                    ) : (
                                        <div className="empty-image-box" />
                                    )}
                                </div>
                                <div className="user-avatar-wrapper">
                                    <label className="edit-cover" htmlFor={`${id}_avatar`}>
                                        <FontAwesomeIcon icon={faPen} size="2x" />
                                    </label>
                                    <input
                                        style={{ display: 'none' }}
                                        id={`${id}_avatar`}
                                        type="file"
                                        onChange={(e) => handleFileUploadFromInput(e, setUserAvatar, userLogo)}
                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                    />
                                    {userAvatar ? (
                                        <img src={userAvatar || ''} alt={name} ref={userLogo} />
                                    ) : (
                                        <div className="empty-image-box" />
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="input-row">
                            <TextInput label="NOME E COGNOME*" value={name} onChange={(newVal) => setName(newVal)} />
                            <TextInput label="EMAIL*" disabled value={email} onChange={(newVal) => setEmail(newVal)} />
                        </div>
                        <div className="input-row" style={{ marginTop: 16 }}>
                            <TextInput label="INDIRIZZO" value={address} onChange={(newVal) => setAddress(newVal)} />
                            <TextInput
                                label="TELEFONO"
                                value={phoneNumber}
                                onChange={(newVal) => setPhoneNumber(newVal)}
                            />
                        </div>
                        {/*
                    <>
                        <div className="user-exp-section">
                            <div className="section-title">Esperienze lavorative</div>
                            <ProfileAdditionalInfo
                                type="job"
                                experiences={jobExperiences}
                                onExperiencesSaved={(newExp) => setJobExperiences(newExp)}
                            />
                            <div className="section-title">Istruzione</div>
                            <ProfileAdditionalInfo
                                type="edu"
                                experiences={educationExperiences}
                                onExperiencesSaved={(newExp) => setEducationExperiences(newExp)}
                            />
                        </div>
                        <div className="user-skills-section">
                            <div className="section-title">Skill</div>
                            <TagsContainer
                                selectedTags={userSkills}
                                options={data.tags.filter((tag) => tag.category === 'Skill')}
                                onTagsChange={(newSkills) => setUserSkills(newSkills)}
                            />
                        </div>
                    </>
                        ) */}
                        <div style={{ marginTop: 16 }}>
                            <Button
                                disabled={changeProfileInfoLoading || !profileInfoDataIsCorrect}
                                onClick={() => void updateProfileInfo()}
                                loading={changeProfileInfoLoading}
                            >
                                Salva modifiche
                            </Button>
                        </div>

                        {(user.userInfo?.idSysGrant === 6 || user.userInfo?.idSysGrant === 7) && (
                            <>
                                <hr />
                                <div className="profile-section__wrapper__billing-info">
                                    <div className="section-title">Informazioni di pagamento</div>
                                    <div className="input-row">
                                        <TextInput
                                            disabled={billingFieldsAreLocked}
                                            label="INTESTATARIO IBAN"
                                            canAutocomplete={false}
                                            value={ibanUser}
                                            placeholder="Mario Rossi"
                                            onChange={(newVal) => setIbanUser(newVal)}
                                        />
                                        <TextInput
                                            disabled={billingFieldsAreLocked}
                                            label="CODICE IBAN"
                                            canAutocomplete={false}
                                            value={ibanCode}
                                            placeholder="IT00X00000000000000000000000"
                                            onChange={(newVal) => setIbanCode(newVal)}
                                        />
                                    </div>
                                    <div className="input-row">
                                        <Button
                                            disabled={!billingDataIsCorrect || changeBillingInfoLoading}
                                            onClick={() => void updateUserBillingInfo()}
                                            loading={changeBillingInfoLoading}
                                        >
                                            Salva
                                        </Button>
                                        {billingFieldsAreLocked && (
                                            <Button type="secondary" onClick={() => setBillingFieldsAreLocked(false)}>
                                                Modifica
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        <hr />

                        <div className="profile-section__wrapper__password-change">
                            <div className="section-title">Cambio password</div>
                            <div className="input-row">
                                <TextInput
                                    label="VECCHIA PASSWORD"
                                    canAutocomplete={false}
                                    value={oldPassword}
                                    onChange={(newVal) => setOldPassword(newVal)}
                                    type="password"
                                />
                                <TextInput
                                    label="NUOVA PASSWORD"
                                    canAutocomplete={false}
                                    value={newPassword}
                                    onChange={(newVal) => setNewPassword(newVal)}
                                    type="password"
                                />
                                <TextInput
                                    label="CONFERMA PASSWORD"
                                    canAutocomplete={false}
                                    value={newConfirmPassword}
                                    onChange={(newVal) => setNewConfirmPassword(newVal)}
                                    type="password"
                                />
                            </div>
                            <Button
                                disabled={changePassLoading || !updatePassDataIsCorrect}
                                type="secondary"
                                onClick={() => void updatePassword()}
                                loading={changePassLoading}
                            >
                                Invia
                            </Button>
                        </div>
                        <hr />
                    </>
                )}
                <div className="profile-section__wrapper__danger-links">
                    <Button
                        type="error"
                        onClick={() => {
                            dispatch(logout())
                            navigate(ROUTES.LOGIN_ROUTE)
                        }}
                    >
                        Disconnetti
                    </Button>
                    <p style={{ opacity: 0.8, fontWeight: 500, fontSize: '.9em' }}>DrawUp v{pkg.version}</p>
                    {/* <Button type="error" onClick={() => null}>
                        Richiedi eliminazione profilo
                    </Button> */}
                </div>
            </div>
        </div>
    )
}

export default ProfileInfo

// const ProfileAdditionalInfo: React.FC<ProfileAdditionalInfoProps> = (props) => {
//     const [experiencesArray, setExperiencesArray] = useState<LocalExperienceInstance[]>([])

//     useEffect(() => {
//         if (props.experiences.length > 0) {
//             setExperiencesArray(
//                 props.experiences.map((exp, i) => {
//                     return { ...exp, tempId: i }
//                 })
//             )
//         } else {
//             setExperiencesArray([{ ...emptyExperience, tempId: 0 }])
//         }
//     }, [])

//     const removeExperienceFromArray = (expId?: number) => {
//         if (expId === undefined || expId === null) return
//         setExperiencesArray([...experiencesArray.filter((exp) => exp.tempId !== expId)])
//     }

//     const saveExperiencesEdits = () => {
//         props.onExperiencesSaved(experiencesArray)
//     }

//     useEffect(() => {
//         saveExperiencesEdits()
//     }, [experiencesArray])

//     const allDataIsValid = useMemo(() => {
//         if (experiencesArray.length === 0) return false
//         return experiencesArray.every((experience) =>
//             Object.keys(experience)
//                 .map((expKey) => {
//                     if (expKey !== 'notes') return experience[expKey as keyof LocalExperienceInstance] !== ''
//                     return true
//                 })
//                 .every((val) => val)
//         )
//     }, [experiencesArray])

//     return (
//         <div className="experiences-wrapper-box">
//             <span>
//                 Compila i campi obbligatori (*) per validare una scheda. <b>Solo le schede valide verranno salvate.</b>
//             </span>
//             <Button
//                 type="secondary"
//                 disabled={!allDataIsValid}
//                 onClick={() =>
//                     setExperiencesArray([
//                         { ...emptyExperience, tempId: experiencesArray.length + 1 },
//                         ...experiencesArray,
//                     ])
//                 }
//             >
//                 Aggiungi
//             </Button>
//             <div className="experiences-cards-container">
//                 {experiencesArray.map((experience, index) => (
//                     <div className="user-experience-card" key={experience.tempId}>
//                         <div className="user-experience-card__header">
//                             <TextInput
//                                 placeholder="Inizio*"
//                                 value={experience.start}
//                                 onChange={(newVal) =>
//                                     setExperiencesArray(
//                                         experiencesArray.map((jobExperience, i) => {
//                                             if (i === index) return { ...jobExperience, start: newVal }
//                                             return jobExperience
//                                         })
//                                     )
//                                 }
//                             />
//                             <TextInput
//                                 placeholder="Fine*"
//                                 value={experience.end}
//                                 onChange={(newVal) =>
//                                     setExperiencesArray(
//                                         experiencesArray.map((jobExperience, i) => {
//                                             if (i === index) return { ...jobExperience, end: newVal }
//                                             return jobExperience
//                                         })
//                                     )
//                                 }
//                             />
//                             {experiencesArray.length > 1 && (
//                                 <IconButton
//                                     onClick={() => removeExperienceFromArray(experience.tempId)}
//                                     icon={faTrash}
//                                     color="rgba(255, 65, 54, 1)"
//                                 />
//                             )}
//                         </div>
//                         <TextInput
//                             placeholder="Ruolo*"
//                             value={experience.role}
//                             onChange={(newVal) =>
//                                 setExperiencesArray(
//                                     experiencesArray.map((jobExperience, i) => {
//                                         if (i === index) return { ...jobExperience, role: newVal }
//                                         return jobExperience
//                                     })
//                                 )
//                             }
//                         />
//                         <TextInput
//                             placeholder={props.type === 'edu' ? 'Presso istituto...*' : 'Presso azienda...*'}
//                             value={experience.company}
//                             onChange={(newVal) =>
//                                 setExperiencesArray(
//                                     experiencesArray.map((jobExperience, i) => {
//                                         if (i === index) return { ...jobExperience, company: newVal }
//                                         return jobExperience
//                                     })
//                                 )
//                             }
//                         />
//                         <TextInput
//                             placeholder="Note aggiuntive"
//                             isTextarea
//                             value={experience.notes}
//                             onChange={(newVal) =>
//                                 setExperiencesArray(
//                                     experiencesArray.map((jobExperience, i) => {
//                                         if (i === index) return { ...jobExperience, notes: newVal }
//                                         return jobExperience
//                                     })
//                                 )
//                             }
//                         />
//                     </div>
//                 ))}
//             </div>
//         </div>
//     )
// }
