import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ResourceCard from '../../components/ResourceCard/ResourceCard'
import { addNewResourceToProject, editPrevProjectResource } from '../../resources/api-constants'
import { ResourcesTypes } from '../../resources/other-constants'
import { useAppSelector } from '../../store/reducers/store'
import { Resource } from '../../types/data'
import { ToastError, ToastSuccess } from '../../utility/toast'
import EditResourceModal from '../EditResourceModal/EditResourceModal'
import LoadResourceModal, { NewResource } from '../LoadResourceModal/LoadResourceModal'
import './ResourcesSection.sass'

interface ResourcesSectionProps {
    disabled: boolean
    projectCodeDrawup: string
    attachments: Resource[]
    mainResource: Resource | null
}

const ResourcesSection: React.FC<ResourcesSectionProps> = (props) => {
    const data = useAppSelector((data) => data.data)
    const user = useAppSelector((data) => data.user)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [loadResourceModalIsOpen, setLoadResourceModalIsOpen] = useState(false)
    const [selectedResource, setSelectedResource] = useState<Resource | null>(null)
    const [selectedResourceTypeId, setSelectedResourceTypeId] = useState<number>(-1)
    const [resourcesList, setResourcesList] = useState<(Resource | NewResource)[]>([])

    useEffect(() => {
        setSelectedResourceTypeId(
            data.resourceTypes.find((resType) => resType.Descrizione === ResourcesTypes.PROJECT_FILE)?.id || -1
        )
        setResourcesList(props.attachments)
    }, [])

    const exploreResource = (res: Resource | null) => {
        setSelectedResource(res)
        setModalIsOpen(true)
    }

    const resetModalData = () => {
        setModalIsOpen(false)
        setLoadResourceModalIsOpen(false)
        setSelectedResource(null)
    }

    const saveNewProjectResource = async (resource: any) => {
        try {
            const resourceData = {
                idProject: props.projectCodeDrawup,
                projectFile: resource.projectFile,
                idTypeResource: 13,
                description: resource.description,
            }
            const res = await addNewResourceToProject(user.accessToken, resourceData)
            if (!res) throw new Error('Non è stato possibile salvare la risorsa.')
            ToastSuccess('Risorsa salvata con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Non è stato possibile salvare la risorsa.')
        }
    }

    const editProjectResource = async (resource: any) => {
        try {
            const resourceData = {
                id: resource.id,
                projectFile: resource.projectFile,
                idTypeResource: 13,
                description: resource.description,
                visible: 1,
            }
            const res = await editPrevProjectResource(user.accessToken, resourceData)
            if (!res) throw new Error('Non è stato possibile salvare la risorsa.')
            ToastSuccess('Risorsa modificata con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Non è stato possibile salvare la risorsa.')
        }
    }

    return (
        <>
            <EditResourceModal
                projectCode={props.projectCodeDrawup}
                resource={selectedResource}
                onClose={() => resetModalData()}
                onSave={async (updatedResource, isDelete) => {
                    let finalResourcesList = resourcesList
                    if (updatedResource) {
                        if (isDelete) {
                            finalResourcesList = resourcesList.filter((resource) => resource.id !== updatedResource.id)
                        } else {
                            finalResourcesList = resourcesList.map((resource) => {
                                if (resource.id === updatedResource.id) return updatedResource
                                return resource
                            })
                        }
                    }
                    setResourcesList(finalResourcesList)
                    resetModalData()
                    if (!isDelete) {
                        await editProjectResource(updatedResource)
                    }
                }}
                isVisible={modalIsOpen}
            />
            <LoadResourceModal
                projectCode={props.projectCodeDrawup}
                resourceTypeId={selectedResourceTypeId}
                onClose={() => resetModalData()}
                onSave={async (updatedResource) => {
                    const finalResourcesList = [...resourcesList, updatedResource]
                    setResourcesList(finalResourcesList)
                    resetModalData()
                    await saveNewProjectResource(updatedResource)
                }}
                isVisible={loadResourceModalIsOpen}
            />
            <div className="project-resources-container">
                {/* {!!props.mainResource && (
                    <>
                        <span>Copertina</span>
                        <ResourceCard
                            onClick={() => exploreResource(props.mainResource)}
                            resourceData={props.mainResource}
                        />
                        <hr />
                    </>
                )} */}
                <div className="project-resources-container__attachments">
                    {resourcesList.length < 16 && (
                        <div
                            className={`add-resource-button ${props.disabled ? 'add-resource-is-disabled' : ''}`}
                            onClick={() => {
                                if (!props.disabled) setLoadResourceModalIsOpen(true)
                            }}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} color={'#bbbbbb'} size="2x" />
                            {props.disabled && (
                                <span style={{ textAlign: 'center', width: '70%', marginTop: 16 }}>
                                    Per aggiungere nuove risorse abilita la modifica del progetto premendo sul
                                    lucchetto.
                                </span>
                            )}
                        </div>
                    )}
                    {resourcesList
                        .filter((res) => res.typeResource !== ResourcesTypes.PROJECT_FILE_BANNER_IMAGE)
                        .map((resource) => (
                            <ResourceCard
                                onClick={() => {
                                    if (!props.disabled) exploreResource(resource as Resource)
                                }}
                                key={resource.id}
                                resourceData={resource as Resource}
                            />
                        ))}
                </div>
            </div>
        </>
    )
}

export default ResourcesSection
