import { ContestStatusType, ResourceTypology, TipologySize } from './../../types/data'
import { createReducer } from '@reduxjs/toolkit'
import { Category, Tag, Tipology } from '../../types/data'
import {
    setCategories,
    setContestStatusTypes,
    setResourceTipologies,
    setTags,
    setTipologies,
    setTipologiesSizes,
} from '../actions/data'

interface DataReducer {
    categories: Category[]
    tipologies: Tipology[]
    tipologySizes: TipologySize[]
    tags: Tag[]
    resourceTypes: ResourceTypology[]
    contestStatusTypes: ContestStatusType[]
}

const initialState: DataReducer = {
    categories: [],
    tipologies: [],
    tipologySizes: [],
    tags: [],
    resourceTypes: [],
    contestStatusTypes: [],
}

const dataReducer = createReducer<DataReducer>(initialState, (builder) => {
    builder.addCase(setTags, (state, action) => {
        state.tags = action.payload
    })
    builder.addCase(setCategories, (state, action) => {
        state.categories = action.payload
    })
    builder.addCase(setTipologies, (state, action) => {
        state.tipologies = action.payload
    })
    builder.addCase(setTipologiesSizes, (state, action) => {
        state.tipologySizes = action.payload
    })
    builder.addCase(setResourceTipologies, (state, action) => {
        state.resourceTypes = action.payload
    })
    builder.addCase(setContestStatusTypes, (state, action) => {
        state.contestStatusTypes = action.payload
    })
})

export default dataReducer
