import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import '../../styles/pages/ContestCreationWidget.sass'

interface Props {
    onConfirm: (contestName: string) => void
}

const DefineContestName: React.FC<Props> = (props) => {
    const [contestName, setContestName] = useState('')

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Dai un titolo al tuo Progetto</h1>
                <p>In questo modo aiuterai i nostri architetti a riconoscerlo immediatamente</p>
            </div>
            <div style={{ width: '100%' }}>
                <TextInput
                    value={contestName}
                    placeholder="Inserisci qui il titolo (es: 'La mia casa dei sogni')"
                    onChange={(newVal) => setContestName(newVal)}
                />
                <div style={{ height: 24, width: 1 }} />
                <Button disabled={!contestName} onClick={() => props.onConfirm(contestName)}>
                    Avanti
                </Button>
            </div>
        </div>
    )
}

export default DefineContestName
