import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import { ContestSummary, SingleReportToAdd } from '../../types/contests'
import DefineContestCategory from './DefineContestCategory'
import DefineContestDescription from './DefineContestDescription'
import DefineContestSpaceSize from './DefineContestSpaceSize'
import DefineContestType from './DefineContestType'
import DefineCustomerEmail from './DefineCustomerEmail'
import DefineCustomerName from './DefineCustomerName'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { getCategories, getTipologies, getTipologySizes, performUserLogin } from '../../store/actions/thunk_actions'
import { setTipologies, setTipologiesSizes } from '../../store/actions/data'
import DefineContestName from './DefineContestName'
import DefineCustomerPassword from './DefineCustomerPassword'
import DefineContestDuration from './DefineContestDuration'
import ContestRecap from './ContestRecap'
import {
    addContestSingleReport,
    createNewContest,
    updateContestExtraReports,
    userLogin,
    userSignIn,
} from '../../resources/api-constants'
import { UserSigninCredentials } from '../../types/requests'
import { LoginCredentials } from '../../types/user'
import { ToastError, ToastSuccess } from '../../utility/toast'
import DefineContestLocalization from './DefineContestLocalization'
import { PriceSizeTypology } from '../../types/data'

interface TemporaryUser {
    name: string
    lastName: string
    username: string
    email: string
    password: string
    passwordConfirm: string
}

const emptyContest: ContestSummary = {
    ID: -1,
    idContest: '',
    idUser: '',
    title: '',
    description: '',
    dataCreations: '',
    dataStart: '',
    dataEnd: '',
    price: '',
    amountPrize: '',
    ready: 0,
    solved: 0,
    status: 0,
    internalAnnotations: '',
    externalAnnotation: '',
    localization: '',
    category: 0,
    typology: 0,
    MQ: 0,
    tags: [],
    public: 0,
    designerSubscribers: 0,
    designerCompleted: 0,
    expired: 0,
    request: [],
    idTypologySize: 0,
    resource: [],
    myPresentation: 0,
    mySubscribe: 0,
    tempNameDesigner: '',
    remainingDays: null,
    zipFile: '',
}

const emptyTempUser: TemporaryUser = {
    name: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    passwordConfirm: '',
}

const CreateContestPage: React.FC = () => {
    const data = useAppSelector((data) => data.data)
    const user = useAppSelector((data) => data.user)
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [currentContextData, setCurrentContextData] = useState<ContestSummary>(emptyContest)
    const [currentCustomer, setCurrentCustomer] = useState<TemporaryUser>(emptyTempUser)
    const [isNewCustomer, setIsNewCustomer] = useState(false)
    const [creationIsLoading, setCreationIsLoading] = useState(false)
    const [typologySizeId, setTipologySizeId] = useState('')
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const loadGeneralInfo = async () => {
        try {
            await dispatch(getCategories(user.accessToken))
        } catch (error) {
            console.error(error)
        }
    }

    const updateTypologies = async () => {
        try {
            if (currentContextData && currentContextData.category !== undefined) {
                await dispatch(setTipologies([]))
                await dispatch(getTipologies({ authToken: user.accessToken, idCategory: currentContextData.category }))
            }
        } catch (error) {
            console.error(error)
        }
    }

    const updateTypologiesSizes = async () => {
        try {
            if (currentContextData && currentContextData.typology !== undefined) {
                await dispatch(setTipologiesSizes([]))
                await dispatch(
                    getTipologySizes({ authToken: user.accessToken, idTypology: currentContextData.typology })
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void updateTypologies()
    }, [currentContextData.category])

    useEffect(() => {
        void updateTypologiesSizes()
    }, [currentContextData.typology])

    useEffect(() => {
        void loadGeneralInfo()
    }, [currentContextData])

    const createContest = async (
        calculatedPrice: PriceSizeTypology,
        newExtraReportIDs: number[],
        baseReportObj: PriceSizeTypology
    ) => {
        setCreationIsLoading(true)
        try {
            let token = ''
            if (!user.userInfo || !user.userInfo.authToken) {
                if (isNewCustomer) {
                    const signInData: UserSigninCredentials = {
                        username: currentCustomer.username,
                        email: currentCustomer.email,
                        password: currentCustomer.password,
                        passwordConfirm: currentCustomer.passwordConfirm,
                        idSysGrant: 10,
                    }
                    const validOperation = await userSignIn(signInData)
                    if (!validOperation) throw validOperation
                    token = validOperation.authToken
                }
                const authData: LoginCredentials = {
                    grantType: 'password',
                    email: currentCustomer.email,
                    password: currentCustomer.password,
                }
                const res = await dispatch(performUserLogin(authData)).catch((e) => {
                    return e
                })
                if (res.error) throw res.error
                const loginRes = await userLogin(authData)
                if (!loginRes) throw new Error('DFError - Error during user login.')
                token = loginRes.authToken
            } else {
                token = user.userInfo?.authToken || ''
            }
            const data = {
                title: currentContextData.title,
                MQ: currentContextData.MQ,
                duration: currentContextData.duration,
                description: currentContextData.description,
                price: 0,
                category: currentContextData.category,
                typology: currentContextData.typology,
                localization: currentContextData.localization,
                idTypologySize: parseInt(baseReportObj.idTypologySize, 10),
            }
            const newContest = await createNewContest(token, data)
            if (!newContest) throw new Error('Errore durante la prima fase di creazione del contest')

            const baseReportData: SingleReportToAdd = {
                idContest: newContest.idContest,
                reportType: parseInt(baseReportObj.idTypologySize, 10),
                annotation: baseReportObj.description,
                extra: 0,
                price: baseReportObj.price,
            }

            await addContestSingleReport(user.accessToken, baseReportData)

            const extraReportsData = {
                idContest: newContest.idContest,
                report: newExtraReportIDs.map((id) => ({ reportType: id })),
            }

            await updateContestExtraReports(user.accessToken, extraReportsData)

            navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)
            ToastSuccess('Contest creato con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante la creazione del contest')
        }
        setCreationIsLoading(false)
    }

    const getCurrentCreationStep = () => {
        switch (currentStepIndex) {
            case 0:
                return (
                    <DefineContestCategory
                        options={data.categories.map((cat) => ({ id: cat.IdCategory, key: cat.keyName }))}
                        onConfirm={(idCat) => {
                            setCurrentContextData({ ...currentContextData, category: parseInt(idCat, 10) })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 1:
                return (
                    <DefineContestType
                        options={data.tipologies.map((type) => ({ id: type.idTypology, key: type.name }))}
                        onConfirm={(idType) => {
                            setCurrentContextData({ ...currentContextData, typology: parseInt(idType, 10) })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 2:
                return (
                    <DefineContestSpaceSize
                        options={data.tipologySizes.map((type) => {
                            const words = type.name.split(' ')
                            return {
                                id: type.idTypologySize,
                                title: words[0],
                                subtitle: words.slice(1).join(' ') || '',
                                maxSize: type.maxSize,
                            }
                        })}
                        onConfirm={(sizeData) => {
                            setCurrentContextData({ ...currentContextData, MQ: sizeData.preciseContestSize })
                            setTipologySizeId(sizeData.idTypologySize)
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 3:
                return (
                    <DefineContestDescription
                        onConfirm={(contestDesc) => {
                            setCurrentContextData({ ...currentContextData, description: contestDesc })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 4:
                return (
                    <DefineContestName
                        onConfirm={(contestName) => {
                            setCurrentContextData({ ...currentContextData, title: contestName })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 5:
                return (
                    <DefineContestDuration
                        onConfirm={(duration) => {
                            setCurrentContextData({ ...currentContextData, duration })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 6:
                return (
                    <DefineCustomerName
                        alreadyLoggedUser={
                            !!user.userInfo && user.userInfo.authToken !== '' && user.userInfo.idSysGrant === 10
                        }
                        onConfirm={([name, lastName, username]) => {
                            setCurrentCustomer({ ...currentCustomer, name, lastName, username })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 7:
                return (
                    <DefineCustomerEmail
                        alreadyLoggedUser={
                            !!user.userInfo && user.userInfo.authToken !== '' && user.userInfo.idSysGrant === 10
                        }
                        onConfirm={(email, createAccount) => {
                            setCurrentCustomer({ ...currentCustomer, email })
                            setIsNewCustomer(!!createAccount)
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 8:
                return (
                    <DefineCustomerPassword
                        newCustomer={isNewCustomer}
                        alreadyLoggedUser={
                            !!user.userInfo && user.userInfo.authToken !== '' && user.userInfo.idSysGrant === 10
                        }
                        onConfirm={([password, passwordConfirm]) => {
                            setCurrentCustomer({ ...currentCustomer, password, passwordConfirm })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 9:
                return (
                    <DefineContestLocalization
                        onConfirm={(localization) => {
                            setCurrentContextData({ ...currentContextData, localization })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 10:
                return (
                    <ContestRecap
                        tipologySizeId={typologySizeId}
                        contest={currentContextData}
                        onConfirm={(calculatedPrice, selectedExtraIDs, baseReportObj) => {
                            void createContest(calculatedPrice, selectedExtraIDs, baseReportObj)
                        }}
                        loadingCreation={creationIsLoading}
                    />
                )
            default:
                return null
        }
    }

    return (
        <div className="page-main-container">
            <div className="page-title-container">
                <span className="page-title">Crea un contest</span>
            </div>
            <div className="page-main-header" style={{ marginTop: 16 }}>
                <Button
                    type="ghost"
                    onClick={() => {
                        navigate(ROUTES.HOMEPAGE_ROUTE)
                    }}
                >
                    Annulla
                </Button>
                {currentStepIndex > 0 && (
                    <Button
                        type="ghost"
                        onClick={() => {
                            if (currentStepIndex) {
                                if (currentStepIndex === 9 && !!user.userInfo && user.userInfo.authToken !== '') {
                                    setCurrentStepIndex(5)
                                } else {
                                    setCurrentStepIndex(currentStepIndex - 1)
                                }
                            }
                        }}
                    >
                        Indietro
                    </Button>
                )}
            </div>
            {getCurrentCreationStep()}
        </div>
    )
}

export default CreateContestPage
