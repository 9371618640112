// eslint-disable-next-line no-control-regex
export const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
// export const ibanRegex = /\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/
export const ibanRegex = /[A-Za-z]{2}[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}/
// eslint-disable-next-line
export const urlRegex = /([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?(\/.*)?/

export enum ResourcesTypes {
    ICON_IMAGE_CATEGORY = 'ICON_IMAGE_CATEGORY',
    MAIN_IMAGE_CATEGORY = 'MAIN_IMAGE_CATEGORY',
    BANNER_IMAGE_CATEGORY = 'BANNER_IMAGE_CATEGORY',
    PHOTO_PROFILE_DESIGNER = 'PHOTO_PROFILE_DESIGNER',
    PHOTO_PROFILE_CUSTOMER = 'PHOTO_PROFILE_CUSTOMER',
    LOGO_IMAGE_CUSTOMER = 'LOGO_IMAGE_CUSTOMER',
    PHOTO_BANNER_DESIGNER = 'PHOTO_BANNER_DESIGNER',
    LOGO_IMAGE_DESIGNER = 'LOGO_IMAGE_DESIGNER',
    DOCUMENT_PERSONAL_DESIGNER = 'DOCUMENT_PERSONAL_DESIGNER',
    DOCUMENT_GENERIC = 'DOCUMENT_GENERIC',
    RESOURCE_FILE_CONTEST = 'RESOURCE_FILE_CONTEST',
    REPORT_FILE_CONTEST = 'REPORT_FILE_CONTEST',
    PROJECT_FILE = 'PROJECT_FILE',
    DOWNLOAD_FILE = 'DOWNLOAD_FILE',
    DOCUMENT_PERSONAL_CUSTOMER = 'DOCUMENT_PERSONAL_CUSTOMER',
    MESSAGE_ATTACH_FILE = 'MESSAGE_ATTACH_FILE',
    ICON_IMAGE_TYPOLOGY = 'ICON_IMAGE_TYPOLOGY',
    MAIN_IMAGE_TYPOLOGY = 'MAIN_IMAGE_TYPOLOGY',
    BANNER_IMAGE_TYPOLOGY = 'BANNER_IMAGE_TYPOLOGY',
    ICON_IMAGE_TYPOLOGY_SIZE = 'ICON_IMAGE_TYPOLOGY_SIZE',
    MAIN_IMAGE_TYPOLOGY_SIZE = 'MAIN_IMAGE_TYPOLOGY_SIZE',
    BANNER_IMAGE_TYPOLOGY_SIZE = 'BANNER_IMAGE_TYPOLOGY_SIZE',
    ICON_IMAGE_TYPOLOGY_REPORT = 'ICON_IMAGE_TYPOLOGY_REPORT',
    MAIN_IMAGE_TYPOLOGY_REPORT = 'MAIN_IMAGE_TYPOLOGY_REPORT',
    BANNER_IMAGE_TYPOLOGY_REPORT = 'BANNER_IMAGE_TYPOLOGY_REPORT',
    PROJECT_FILE_MAIN_IMAGE = 'PROJECT_FILE_MAIN_IMAGE',
    PROJECT_FILE_BANNER_IMAGE = 'PROJECT_FILE_BANNER_IMAGE',
}

export enum USER_GRANTS {
    CUSTOMER = 'Customer',
    DESIGNER = 'Designer',
    DESIGNER_PRIME = 'Designer prime',
}
