/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { ContestParticipation, ContestSummary } from '../../../types/contests'
import ContestProposalSection from '../../../customComponents/ContestProposalSection/ContestProposalSection'
import { Resource } from '../../../types/data'
import { ResourcesTypes } from '../../../resources/other-constants'

interface PublicContestParticipationsSectionProps {
    contest: ContestSummary
}

const PublicContestParticipationsSection: React.FC<PublicContestParticipationsSectionProps> = (props) => {
    const [formattedAttachments, setFormattedAttachments] = useState<Resource[]>([])

    const getResourceElement = (resource: Resource | ContestParticipation) => {
        const fileType = resource.projectFile.slice(resource.projectFile.lastIndexOf('.') + 1)
        switch (fileType) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return (
                    <a
                        className="resource-image-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={resource.projectFile}
                            alt={resource.projectFile.slice(resource.projectFile.lastIndexOf('/'))}
                        />
                    </a>
                )
            default:
                return (
                    <a
                        className="resource-document-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="resource-type-extension">{fileType}</span>
                    </a>
                )
        }
    }

    useEffect(() => {
        const attachmentsResource: Resource[] =
            props.contest.result && props.contest.result.length > 0
                ? props.contest.result?.map((result) => ({
                      id: result.ID,
                      idAuthor: result.idAuthor,
                      idContest: result.idContest,
                      idTypeResource: result.typeFile,
                      dataCreations: result.dataCreations,
                      description: '',
                      visible: result.visible,
                      projectFile: result.projectFile,
                      typeFile: result.typeFile,
                      typeResource: ResourcesTypes.REPORT_FILE_CONTEST,
                  }))
                : []
        setFormattedAttachments(attachmentsResource)
    }, [])

    return (
        <div style={{ width: '100%', maxWidth: '1340px' }}>
            <div style={{ width: '100%', justifyContent: 'flex-start' }}>
                <div className="section-title">Le mie proposte</div>
            </div>
            {props.contest && props.contest.status < 5 ? (
                <ContestProposalSection
                    disabled={false}
                    contestCodeDrawup={props.contest.idContest}
                    mainResource={null}
                    attachments={formattedAttachments}
                    additionIsDisabled={props.contest && props.contest.status > 4}
                />
            ) : props.contest.result ? (
                <>
                    <div className="public-project-resources-container" style={{ alignSelf: 'flex-start' }}>
                        {props.contest.result.map((res) => (
                            <div key={res.ID} className="public-project-resources-container__resource-box">
                                {getResourceElement(res)}
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default PublicContestParticipationsSection
