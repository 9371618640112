import { ContestStatusType, ResourceTypology, TipologySize } from './../../types/data'
import { createAction } from '@reduxjs/toolkit'
import { Category, Tag, Tipology } from '../../types/data'

export const setTags = createAction<Tag[]>('data/setTags')
export const setCategories = createAction<Category[]>('data/setCategories')
export const setTipologies = createAction<Tipology[]>('data/setTipologies')
export const setTipologiesSizes = createAction<TipologySize[]>('data/setTipologiesSizes')
export const setResourceTipologies = createAction<ResourceTypology[]>('data/setResourceTipologies')
export const setContestStatusTypes = createAction<ContestStatusType[]>('data/setContestStatusTypes')
