import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ProjectSummary } from '../../types/projects'
import './ProjectCard.sass'
import { truncateText } from '../../utility/functions'

interface ProjectCardProps {
    projectInfo: ProjectSummary
    isPublic?: boolean
    onClick: (projectId: string) => void
}

const projectPlaceholder = 'https://drawup.s3.eu-west-1.amazonaws.com/ProjectPlaceholder.png'

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
    return (
        <div className="project-summary-card" onClick={() => props.onClick(props.projectInfo.idProject)}>
            <div className="project-summary-card__tags-container">
                {props.projectInfo.tags.length > 0 ? (
                    <span className="project-card-tag">{props.projectInfo.tags[0].keyName}</span>
                ) : (
                    <div />
                )}
                <span className="project-card-tag">
                    <FontAwesomeIcon icon={faHeart} color="#ef476f" /> {props.projectInfo.likes || 0}
                </span>
            </div>
            <div className="project-summary-card__image-container">
                <div className="gradient-dark-cover" />
                <div className="project-summary-card__image-container__wrapper">
                    <img src={props.projectInfo.projectFile || projectPlaceholder} alt={props.projectInfo.name} />
                </div>
            </div>
            <div className="project-summary-card__text-container">
                <span className="project-title">{truncateText(props.projectInfo.title, 40)}</span>
                {/* {props.isPublic && (
                    <div className="project-designer-card">
                        <span>
                            <label className="project-labels">Designer:</label> {props.projectInfo.name}
                        </span>
                        <span>
                            <label className={`rank-level ${getRankLevel(props.projectInfo.myRank)}-rank-level`}>
                                {props.projectInfo.myRank}
                            </label>
                        </span>
                    </div>
                )}
                {!!props.projectInfo.isContest && <div>Contest info</div>} */}
            </div>
        </div>
    )
}

export default ProjectCard
