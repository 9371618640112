import { Checkbox } from '@material-ui/core'
import React, { ReactNode } from 'react'
import './Checkbox.sass'

interface CheckboxProps {
    value: boolean
    onChange: (newValue: boolean) => void
    children?: string | ReactNode
    title?: string
    disabled?: boolean
}

const CheckBox: React.FC<CheckboxProps> = (props) => {
    return (
        <div className="checkbox-container">
            <Checkbox
                disabled={!!props.disabled}
                color="default"
                title={props.title}
                checked={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
            />
            <div className="checkbox-children">{props.children}</div>
        </div>
    )
}

export default CheckBox
