/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomerProfile, DesignerProfile, LoggedUserInfo, LoginCredentials, PlacementQuote } from './../types/user'
import {
    ChangePasswordRequestBody,
    EmailCanBeUsedResponse,
    LikeProjectBody,
    PaginatedResponse,
    ResetPasswordRequestBody,
    UpdateUserPaymentBody,
    UserSigninCredentials,
} from './../types/requests'
import { MainProjectInfo, ProjectSummary } from '../types/projects'
import CustomAxios from '../utility/customAxios'
import { getRequestHeader } from '../utility/functions'
import {
    AddBlockReport,
    ContestMessage,
    ContestMessageToSend,
    ContestPlacement,
    ContestSummary,
    PlacementsRequestObject,
    SendMentonBodyObject,
    SingleReportToAdd,
} from '../types/contests'
import axios from 'axios'
import { PriceSizeTypology, ReportTypology, Resource } from '../types/data'

export let baseUrl = ''
export let frontendBaseUrl = ''

switch (process.env.REACT_APP_RELEASE_ENV) {
    case 'development':
        baseUrl = 'http://localhost:3001/'
        frontendBaseUrl = '/'
        break
    case 'staging':
        baseUrl = 'http://54.220.244.11:3002/'
        frontendBaseUrl = '/'
        break
    case 'production':
        baseUrl = 'https://api-prod.drawup.it/'
        frontendBaseUrl = '/'
}

// --- AUTH ---

export const userSignIn = async (signInCredentials: UserSigninCredentials): Promise<LoggedUserInfo | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}auth/signin`, signInCredentials).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const userLogin = async (loginCredentials: LoginCredentials): Promise<LoggedUserInfo | undefined> => {
    try {
        const loggedUserData: LoggedUserInfo = await CustomAxios.post(`${baseUrl}auth/login`, loginCredentials).then(
            (response) => response.data
        )
        return loggedUserData
    } catch (e) {
        console.error(e)
        return
    }
}

export const userResetPasswordRequest = async (resetPasswordRequestBody: ResetPasswordRequestBody): Promise<void> => {
    try {
        await CustomAxios.post(`${baseUrl}auth/reset-password`, resetPasswordRequestBody)
    } catch (error) {
        console.error(error)
    }
}

export const updateUserPassword = async (
    authToken: string,
    changePasswordRequestBody: ChangePasswordRequestBody
): Promise<void> => {
    try {
        await CustomAxios.post(`${baseUrl}auth/change-password`, changePasswordRequestBody, getRequestHeader(authToken))
    } catch (error) {
        console.error(error)
    }
}

export const checkEmailCanBeUsed = async (email: string): Promise<EmailCanBeUsedResponse | undefined> => {
    try {
        const res: EmailCanBeUsedResponse = await CustomAxios.get(`${baseUrl}usersemailexist/${email}`).then(
            (response) => response.data.data[0]
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- PROJECTS ---

export const getDesigerPaginatedProjects = async (
    authToken: string,
    limit: number,
    offset: number,
    designerId: string
): Promise<PaginatedResponse<ProjectSummary> | undefined> => {
    try {
        const url = `${baseUrl}designerdisplay/${designerId}?limit=${limit}&offset=${offset}`
        const res: PaginatedResponse<ProjectSummary> = await CustomAxios.get(url, getRequestHeader(authToken)).then(
            (response) => response.data[0]
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getPaginatedProjectsList = async (
    authToken: string,
    limit: number,
    offset: number,
    isPublicAPI?: boolean
): Promise<PaginatedResponse<ProjectSummary> | undefined> => {
    try {
        const url = `${baseUrl}${isPublicAPI ? 'projectlist' : 'myprojectlist'}?limit=${limit}&offset=${offset}`
        const res: PaginatedResponse<ProjectSummary> = await CustomAxios.get(url, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getPaginatedProjectsSearch = async (
    authToken: string,
    limit: number,
    offset: number,
    searchQuery: string,
    isPublicAPI?: boolean
): Promise<PaginatedResponse<ProjectSummary> | undefined> => {
    try {
        const url = `${baseUrl}${
            isPublicAPI ? 'projectsearchbytags/' : 'myprojectsearch/'
        }${searchQuery}?limit=${limit}&offset=${offset}`
        const res: PaginatedResponse<ProjectSummary> = await CustomAxios.get(url, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getSingleProject = async (authToken: string, projectId: string): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.get(
            `${baseUrl}projectinfo/${projectId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data[0])
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleProject = async (
    authToken: string,
    projectId: string
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.delete(
            `${baseUrl}project/${projectId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data[0])
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createNewProject = async (
    authToken: string,
    newProjectData: any
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.post(
            `${baseUrl}project`,
            newProjectData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleProject = async (
    authToken: string,
    updateProjectData: any
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.put(
            `${baseUrl}project`,
            updateProjectData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const likeProject = async (
    authToken: string,
    likeProjectData: LikeProjectBody
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.post(
            `${baseUrl}projectliked`,
            likeProjectData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const addNewResourceToProject = async (
    authToken: string,
    newResource: any
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.post(
            `${baseUrl}projectresource`,
            newResource,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const editPrevProjectResource = async (
    authToken: string,
    resource: any
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.put(
            `${baseUrl}projectresource`,
            resource,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteProjectResource = async (
    authToken: string,
    projectId: string,
    resourceId: number
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.delete(
            `${baseUrl}projectresource/${projectId}/${resourceId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- CONTEST ---

export const addNewResourceToContest = async (
    authToken: string,
    newResource: any
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.post(
            `${baseUrl}contestresource`,
            newResource,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const editPrevContestResource = async (
    authToken: string,
    resource: any
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.put(
            `${baseUrl}contestresource`,
            resource,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteContestResource = async (
    authToken: string,
    contestId: string,
    resourceId: number
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.delete(
            `${baseUrl}contestresource/${contestId}/${resourceId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getContestMessages = async (
    authToken: string,
    contestId: string
): Promise<PaginatedResponse<ContestMessage> | undefined> => {
    try {
        const res: PaginatedResponse<ContestMessage> = await CustomAxios.get(
            `${baseUrl}messageslist/${contestId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const sendContestMessage = async (
    authToken: string,
    messageData: ContestMessageToSend
): Promise<any | undefined> => {
    try {
        const res: any = await CustomAxios.post(`${baseUrl}message`, messageData, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getUserTempContestName = async (
    authToken: string,
    contestId: string
): Promise<{ tempNameDesigner: string } | undefined> => {
    try {
        const res: any = await CustomAxios.get(
            `${baseUrl}tempnamedesigneroncontest/${contestId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getContestPlacements = async (
    authToken: string,
    contestId: string
): Promise<ContestPlacement[] | undefined> => {
    try {
        const res: ContestPlacement[] = await CustomAxios.get(
            `${baseUrl}contestlistplacement/${contestId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const sendContestMention = async (
    authToken: string,
    mentionData: SendMentonBodyObject
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.post(
            `${baseUrl}contestmention`,
            mentionData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const sendContestResults = async (
    authToken: string,
    placementData: PlacementsRequestObject
): Promise<MainProjectInfo | undefined> => {
    try {
        const res: MainProjectInfo = await CustomAxios.post(
            `${baseUrl}contestplacement`,
            placementData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- DESIGNERS ---

export const getPaginatedDesignersList = async (
    authToken: string,
    limit: number,
    offset: number
): Promise<PaginatedResponse<DesignerProfile> | undefined> => {
    try {
        const url = `${baseUrl}designers?limit=${limit}&offset=${offset}`
        const res: PaginatedResponse<DesignerProfile> = await CustomAxios.get(url, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getPaginatedDesignersSearch = async (
    authToken: string,
    limit: number,
    offset: number,
    searchQuery: string
): Promise<PaginatedResponse<DesignerProfile> | undefined> => {
    try {
        const url = `${baseUrl}designersearch/${searchQuery}?limit=${limit}&offset=${offset}`
        const res: PaginatedResponse<DesignerProfile> = await CustomAxios.get(url, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- CONTESTS ---

export const getPaginatedContestsList = async (
    authToken: string,
    limit: number,
    offset: number
): Promise<PaginatedResponse<ContestSummary> | undefined> => {
    try {
        const res: PaginatedResponse<ContestSummary> = await CustomAxios.get(
            `${baseUrl}contestlist?limit=${limit}&offset=${offset}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getPaginatedContestsSearch = async (
    authToken: string,
    limit: number,
    offset: number,
    searchQuery: string
): Promise<PaginatedResponse<ContestSummary> | undefined> => {
    try {
        const res: PaginatedResponse<ContestSummary> = await CustomAxios.get(
            `${baseUrl}contestsearch/${searchQuery}?limit=${limit}&offset=${offset}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getSingleContest = async (authToken: string, contestId: string): Promise<ContestSummary | undefined> => {
    try {
        const res: ContestSummary = await CustomAxios.get(
            `${baseUrl}contestinfo/${contestId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data[0])
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createNewContest = async (authToken: string, contestData: any): Promise<any> => {
    try {
        const res: ContestSummary = await CustomAxios.post(
            `${baseUrl}contest`,
            contestData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateExistingContest = async (authToken: string, contestData: any): Promise<any> => {
    try {
        const res: ContestSummary = await CustomAxios.put(
            `${baseUrl}contest`,
            contestData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const addContestSingleReport = async (authToken: string, reportData: SingleReportToAdd): Promise<any> => {
    try {
        const res: ContestSummary = await CustomAxios.put(
            `${baseUrl}contestaddreport`,
            reportData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateContestExtraReports = async (authToken: string, extraReportsData: AddBlockReport): Promise<any> => {
    try {
        const res: ContestSummary = await CustomAxios.put(
            `${baseUrl}contestaddblockreport`,
            extraReportsData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getSingleContestPlacementQuotes = async (
    authToken: string,
    contestId: string
): Promise<PlacementQuote | undefined> => {
    try {
        const res: PlacementQuote = await CustomAxios.get(
            `${baseUrl}/contestquoteplacement/${contestId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data[0])
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getSingleContestResources = async (
    authToken: string,
    contestId: string
): Promise<Resource[] | undefined> => {
    try {
        const res: Resource[] = await CustomAxios.get(
            `${baseUrl}/contestresourceslist/${contestId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getFinalPrice = async (typologySizeId: string): Promise<PriceSizeTypology | undefined> => {
    try {
        const res: PriceSizeTypology = await CustomAxios.get(
            `${baseUrl}pricesizetypology?id_typology_size=${typologySizeId}`
        ).then((response) => response.data[0])
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getReportsOfTypology = async (typologySizeId: string): Promise<ReportTypology[] | undefined> => {
    try {
        const res: ReportTypology[] = await CustomAxios.get(
            `${baseUrl}priceaddonreport?id_typology_size=${typologySizeId}`
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const activatePayedContest = async (authToken: string, contestData: any): Promise<any> => {
    try {
        const res: ContestSummary = await CustomAxios.put(
            `${baseUrl}contestactive`,
            contestData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const subscribeDesignerToContest = async (authToken: string, contestData: any): Promise<any> => {
    try {
        const res: ContestSummary = await CustomAxios.post(
            `${baseUrl}designersubuscribe`,
            contestData,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- USER ---

export const getUserDetailedInfo = async (
    authToken: string
): Promise<{ grant: string; profileInfo: CustomerProfile | DesignerProfile } | undefined> => {
    try {
        const res: { grant: string; profileInfo: CustomerProfile | DesignerProfile } = await CustomAxios.get(
            `${baseUrl}userio`,
            getRequestHeader(authToken)
        ).then((response) => {
            return { grant: response.data.grant, profileInfo: response.data.profileInfo }
        })
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateDesignerInfo = async (authToken: string, updateDesignerInfoBody: any): Promise<void> => {
    try {
        await CustomAxios.put(`${baseUrl}designer`, updateDesignerInfoBody, getRequestHeader(authToken))
    } catch (error) {
        console.error(error)
    }
}

export const updateUserInfo = async (authToken: string, updateUserInfoBody: any): Promise<void> => {
    try {
        await CustomAxios.put(`${baseUrl}customer`, updateUserInfoBody, getRequestHeader(authToken))
    } catch (error) {
        console.error(error)
    }
}

export const updateUserPaymentInfo = async (
    authToken: string,
    updateUserPaymentBody: UpdateUserPaymentBody
): Promise<void> => {
    try {
        await CustomAxios.post(`${baseUrl}userbilling`, updateUserPaymentBody, getRequestHeader(authToken))
    } catch (error) {
        console.error(error)
    }
}

// --- FILE MANAGEMENT ---

export const uploadFile = async (authToken: string, uploadFileDataBody: FormData): Promise<string> => {
    try {
        const res = await axios
            .post(`${baseUrl}uploadresource`, uploadFileDataBody, getRequestHeader(authToken, 'multipart/form-data'))
            .then((response) => response.data.file)
        return res
    } catch (error) {
        console.error(error)
        return ''
    }
}
