/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import TextInput from '../../../components/TextInput/TextInput'
import { useAppSelector } from '../../../store/reducers/store'
import Button from '../../../components/Button/Button'
import { ContestMessage, ContestMessageToSend, ContestSummary } from '../../../types/contests'
import { getContestMessages, getUserTempContestName, sendContestMessage } from '../../../resources/api-constants'
import MessageCard from '../../../components/MessageCard/MessageCard'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import IconButton from '../../../components/IconButton/IconButton'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface ContestMessagesSectionProps {
    isPublicPage: boolean
    currentContest: ContestSummary
}

const ContestMessagesSection: React.FC<ContestMessagesSectionProps> = (props) => {
    const user = useAppSelector((data) => data.user)
    const [newMessage, setNewMessage] = useState('')
    const [loadingSendMessage, setLoadingSendMessage] = useState(false)
    const [sentMessages, setSentMessages] = useState<ContestMessage[]>([])
    const [userContestTempName, setUserContestTempName] = useState('')
    const [selectedReplyTo, setSelectedReplyTo] = useState<number>(-1)
    const [selectedUserReplyTo, setSelectedUserReplyTo] = useState('')

    const fetchMessagesList = async () => {
        try {
            const messages = await getContestMessages(user.accessToken, props.currentContest.idContest)
            if (messages && messages.data) {
                setSentMessages(messages.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchUserContestAlias = async () => {
        try {
            const tempNameObj = await getUserTempContestName(user.accessToken, props.currentContest.idContest)
            if (tempNameObj) {
                setUserContestTempName(tempNameObj.tempNameDesigner)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (user.userInfo?.idSysGrant === 6 || user.userInfo?.idSysGrant === 7) {
            void fetchUserContestAlias()
        }
    }, [user])

    useEffect(() => {
        void fetchMessagesList()
    }, [])

    const sendNewMessage = async () => {
        setLoadingSendMessage(true)
        try {
            const messageData: ContestMessageToSend = {
                idMessage: props.currentContest.idContest,
                textMessage: newMessage,
                idReceiver: selectedUserReplyTo || null,
                replyTo: selectedReplyTo !== -1 ? selectedReplyTo : null,
            }
            if (!messageData.replyTo) delete messageData.replyTo
            const res = await sendContestMessage(user.accessToken, messageData)
            if (!res) {
                throw new Error('DFError - Unable to send message.')
            }
            await fetchMessagesList()
            setNewMessage('')
            setSelectedReplyTo(-1)
            setSelectedUserReplyTo('')
            ToastSuccess('Messaggio inviato con successo!')
        } catch (error) {
            console.error(error)
            ToastError("Errore durante l'invio del messaggio.")
        }
        setLoadingSendMessage(false)
    }

    const userCanReplyToMessage = (message: ContestMessage) => {
        // designer
        if (user.userInfo?.idSysGrant === 7 || user.userInfo?.idSysGrant === 6) {
            // return message.idSender !== user.userInfo?.codeDrawup && message.idSender.includes('CST_')
            return false
        }
        // customer, admin, other
        return message.idSender !== `CST_${props.currentContest.idContest}`
    }

    return (
        <div style={{ width: '100%', maxWidth: '1340px' }}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: 16,
                }}
            >
                <div className="messages-flow-container">
                    {sentMessages.length > 0 ? (
                        <>
                            {sentMessages.map((message) => (
                                <MessageCard
                                    key={message.id}
                                    message={message.textMessage}
                                    sender={message.idSender}
                                    myMessage={
                                        message.idSender === userContestTempName ||
                                        (message.idSender.includes('CST_') && user.userInfo?.idSysGrant === 10)
                                    }
                                    canReply={userCanReplyToMessage(message) && props.currentContest.status < 5}
                                    replyTo={message.idReceiver !== null ? message.idReceiver : ''}
                                    timestamp={message.date}
                                    onSelectReplyTo={() => {
                                        setSelectedReplyTo(message.id)
                                        setSelectedUserReplyTo(
                                            message.idSender.includes('CST_') ? '' : message.idSender
                                        )
                                    }}
                                />
                            ))}
                        </>
                    ) : (
                        <p className="empty-messages-list-text">Nessun messaggio è stato inviato.</p>
                    )}
                </div>
                {props.currentContest.status < 5 && (
                    <div className="message-send-wrapper">
                        {selectedUserReplyTo && (
                            <div className="reply-to-message-header">
                                <IconButton
                                    icon={faTimes}
                                    onClick={() => {
                                        setSelectedReplyTo(-1)
                                        setSelectedUserReplyTo('')
                                    }}
                                />
                                <span className="empty-messages-list-text">
                                    Stai rispondendo al messaggio di {selectedUserReplyTo}.
                                </span>
                            </div>
                        )}
                        <TextInput
                            isTextarea
                            value={newMessage}
                            onChange={(newVal) => setNewMessage(newVal)}
                            placeholder="Invia un messaggio in forma anonima per richiedere chiarimenti, specifiche e/o ulteriori risorse relative al contest."
                        />
                        <Button
                            loading={loadingSendMessage}
                            disabled={!newMessage || loadingSendMessage}
                            onClick={() => void sendNewMessage()}
                        >
                            Invia
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ContestMessagesSection
