import React from 'react'
import './SkeletonCard.sass'

const SkeletonCard: React.FC = () => {
    return (
        <div className="skeleton-card">
            <div className="skeleton-card-body skeleton" />
            <div className="skeleton-card-bottom skeleton" />
        </div>
    )
}

export default SkeletonCard
