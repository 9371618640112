import React, { useMemo, useState } from 'react'
import '../../styles/pages/ContestCreationWidget.sass'
import Button from '../../components/Button/Button'

interface AvailableSizesOption {
    id: string
    title: string
    subtitle: string
    maxSize: number | null
}

interface ContestSizeInfo {
    preciseContestSize: number
    idTypologySize: string
}

interface Props {
    options: AvailableSizesOption[]
    onConfirm: (sizeInfo: ContestSizeInfo) => void
}

const DefineContestSpaceSize: React.FC<Props> = (props) => {
    const [selectedIdTypologySize, setSelectedIdTypologySize] = useState('')
    const [contestPreciseSize, setContestPreciseSize] = useState<number>(NaN)
    const [sizeErrorIsShown, setSizeErrorIsShown] = useState(false)

    const dataIsNotValid = useMemo(() => {
        let maxValueCheck = true
        const selectedTypologyMaxValue = props.options.find((opt) => opt.id === selectedIdTypologySize)?.maxSize || null
        if (selectedTypologyMaxValue && contestPreciseSize) {
            maxValueCheck = contestPreciseSize <= selectedTypologyMaxValue
        }
        const finalCheck = !contestPreciseSize || !maxValueCheck
        setSizeErrorIsShown(finalCheck)
        return finalCheck
    }, [props.options, selectedIdTypologySize, contestPreciseSize])

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Quanto è grande il tuo spazio?</h1>
                <p>
                    Questo passaggio serve per darci un’idea di massima sulla grandezza del tuo spazio, così da poterti
                    suggerire un budget adeguato al tuo progetto.
                </p>
            </div>
            <div className="contest-creation-items-row">
                {props.options.map((typeSize) => (
                    <div
                        key={typeSize.id}
                        className={`interactive-box ${
                            selectedIdTypologySize === typeSize.id ? 'selected-interactive-box' : ''
                        }`}
                        onClick={() => {
                            setSelectedIdTypologySize(typeSize.id)
                        }}
                    >
                        <h2 style={{ marginTop: 8 }}>{typeSize.title}</h2>
                        <span>{typeSize.subtitle}</span>
                    </div>
                ))}
            </div>
            <div style={{ width: '100%', marginTop: 24 }}>
                <div className="text-input-container">
                    <div className="text-input-label">Grandezza precisa in metri quadrati (MQ)</div>
                    <input
                        min={0}
                        placeholder="Inserisci qui una metratura più precisa"
                        className="interactive text-input-input-element"
                        type="number"
                        value={contestPreciseSize}
                        onChange={(e) => {
                            if (parseInt(e.target.value, 10) > 99999) return
                            setContestPreciseSize(
                                Number.isNaN(parseInt(e.target.value, 10)) ? NaN : parseInt(e.target.value, 10)
                            )
                        }}
                    />
                </div>
                <div style={{ height: 24, width: 1 }} />

                {sizeErrorIsShown && !Number.isNaN(contestPreciseSize) && (
                    <p className="subtitle-text">
                        Il valore che hai inserito non è conforme alla scelta della dimensione. Per favore riporta un
                        valore valido minore del massimo indicato.
                    </p>
                )}
                <div style={{ height: 24, width: 1 }} />
                <Button
                    disabled={dataIsNotValid}
                    onClick={() =>
                        props.onConfirm({
                            preciseContestSize: contestPreciseSize,
                            idTypologySize: selectedIdTypologySize,
                        })
                    }
                >
                    Avanti
                </Button>
            </div>
        </div>
    )
}

export default DefineContestSpaceSize
