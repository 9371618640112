import React from 'react'
import { DesignerProfile } from '../../types/user'
import './DesignerCard.sass'
import { truncateText } from '../../utility/functions'

interface DesignerCardProps {
    designer: DesignerProfile
    onClick: () => void
}

const coverPlaceholder = 'https://drawup.s3.eu-west-1.amazonaws.com/placeHolderCard.png'
const avatarPlaceholder = 'https://drawup.s3.eu-west-1.amazonaws.com/userAvatarPlaceholder.png'

const DesignerCard: React.FC<DesignerCardProps> = (props) => {
    return (
        <div className="designer-card" onClick={() => props.onClick()}>
            <div className="designer-card__image-container">
                <div className="gradient-dark-cover" />
                <div className="designer-card__image-container__wrapper">
                    <img src={props.designer.photoBanner || coverPlaceholder} alt={props.designer.name} />
                </div>
            </div>
            <div className="designer-card__avatar-container">
                <img src={props.designer.photoProfile || avatarPlaceholder} alt={props.designer.name} />
            </div>
            <div className="designer-card__text-container">
                <span className="designer-name">{truncateText(props.designer.name, 40)}</span>
            </div>
        </div>
    )
}

export default DesignerCard
