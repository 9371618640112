import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../resources/api-constants'
import { useQueryParamsFromURL } from '../../resources/hooks'
import CustomAxios from '../../utility/customAxios'

interface BasicRouteParams {
    code?: string
    token?: string
}

const ConfirmEmailPage: React.FC = () => {
    const [displayError, setDisplayError] = useState('')
    const [displaySuccess, setDisplaySuccess] = useState('')
    const routeParams = useQueryParamsFromURL<BasicRouteParams>(['token', 'code'])

    const verifyUserEmail = async (): Promise<void> => {
        try {
            if (routeParams && Object.values(routeParams).every((value: string) => value !== '')) {
                const res = await CustomAxios.get(
                    `${baseUrl}confirmemail/${routeParams.code || ''}/${routeParams.token || ''}`
                ).then((response) => response)

                if (res.status !== 200) throw (res as any).response.data.message
                else setDisplaySuccess('Email confermata con successo, torna al login e accedi a DrawUp.')
            } else throw 'Parametri URL non validi.'
        } catch (error) {
            setDisplayError(typeof error === 'string' ? (error as string) : (error as any).response.data.message)
            console.error(error)
        }
    }

    useEffect(() => {
        setDisplaySuccess('')
        if (!routeParams) return
        void verifyUserEmail()
    }, [routeParams])

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {displayError !== '' && (
                <p title={displayError} className="error-container">
                    {displayError}
                </p>
            )}
            {displaySuccess !== '' && (
                <p title={displaySuccess} className="success-container">
                    {displaySuccess}
                </p>
            )}
        </div>
    )
}

export default ConfirmEmailPage
