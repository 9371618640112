import React, { ReactNode, useEffect, useRef, useState } from 'react'
import './InfiniteScrollList.sass'
import InfiniteListLoader from '../Loader/InfiniteListLoader'

interface InfiniteScrollListProps {
    fetchMore: () => void
    children: ReactNode
    hasMore: boolean
    isLoading: boolean
    isPrivatePage?: boolean
    currentDataLength?: number
    emptyListMessage: string
}

const InfiniteScrollList: React.FC<InfiniteScrollListProps> = (props) => {
    const listInnerRef = useRef<HTMLDivElement>(null)
    const [currPage, setCurrPage] = useState(1)
    const [prevPage, setPrevPage] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            if (props.hasMore) {
                setPrevPage(currPage)
                props.fetchMore()
            }
        }
        if (prevPage !== currPage) {
            void fetchData()
        }
    }, [currPage, prevPage])

    const onListScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
            const currentHeight = scrollTop + clientHeight
            if (currentHeight > scrollHeight - 64) {
                setCurrPage(currPage + 1)
            }
        }
    }

    return (
        <div
            onScroll={() => onListScroll()}
            ref={listInnerRef}
            className={`page-main-container ${props.isPrivatePage && 'private-page-main-container'}`}
        >
            {props.children}
            {props.isLoading && <InfiniteListLoader text="Caricando i progetti..." />}
            {!props.isLoading && !props.hasMore && !props.currentDataLength && (
                <p style={{ textAlign: 'center' }}>
                    <b>{props.emptyListMessage}</b>
                </p>
            )}
        </div>
    )
}

export default InfiniteScrollList
