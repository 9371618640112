import React from 'react'
import './MessageCard.sass'
import IconButton from '../IconButton/IconButton'
import { faReply } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from '../../store/reducers/store'

interface MessageCardProps {
    sender: string
    message: string
    myMessage: boolean
    canReply: boolean
    replyTo: string
    timestamp: string
    onSelectReplyTo: () => void
}

const MessageCard: React.FC<MessageCardProps> = (props) => {
    const user = useAppSelector((data) => data.user)

    const parseTimestampValue = (timestamp: string) => {
        const date = new Date(timestamp)
        const minutes = date.getMinutes()
        return `${date.toLocaleDateString()}, ${date.getHours() + 2}:${
            minutes.toString().length > 1 ? minutes : `0${minutes}`
        }`
    }

    return (
        <div className={`contest-message-card ${props.myMessage ? 'personal-message-card' : ''}`}>
            <div className="contest-message-card__header">
                <div className="contest-message-card__header__left-section">
                    <div>
                        {props.sender.includes('CST_') ? (
                            <label className="project-status-label no-margin-label">CUSTOMER</label>
                        ) : (
                            <span>{props.sender}</span>
                        )}
                        {user.userInfo?.idSysGrant !== 10 && <b>{props.myMessage ? ' (Io)' : ''}</b>}
                    </div>
                    {props.replyTo && (
                        <span className="reply-to-text">
                            Risposta a {props.replyTo.includes('CST_') ? 'CUSTOMER' : props.replyTo}
                        </span>
                    )}
                    {props.canReply && <IconButton icon={faReply} onClick={() => props.onSelectReplyTo()} />}
                </div>
                <div className="contest-message-card__header__right-section">
                    <label className="timestamp-string">{parseTimestampValue(props.timestamp)}</label>
                </div>
            </div>
            <div className="contest-message-card__body">{props.message}</div>
        </div>
    )
}

export default MessageCard
