import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import { useAppSelector } from '../../../store/reducers/store'
import { ContestSummary } from '../../../types/contests'
import Button from '../../../components/Button/Button'
import StaticExtraReportSelector from '../../../components/ExtraReportSelector/StaticExtraReportSelector'
import { Resource } from '../../../types/data'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { subscribeDesignerToContest } from '../../../resources/api-constants'
import { PlacementQuote } from '../../../types/user'

interface PublicContestInfoSectionsProps {
    contest: ContestSummary
    placementQuotes: PlacementQuote | null
}

const PublicContestInfoSections: React.FC<PublicContestInfoSectionsProps> = (props) => {
    const user = useAppSelector((state) => state.user)
    const data = useAppSelector((state) => state.data)
    const [currentContestInfo, setCurrentContestInfo] = useState<ContestSummary>(props.contest)
    const [placementQuotes, setPlacementQuotes] = useState<PlacementQuote | null>(null)
    // const [currentProjectCover, setCurrentProjectCover] = useState<Resource | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        setCurrentContestInfo(props.contest)
        setPlacementQuotes(props.placementQuotes)
    }, [props.contest])

    const subscribeDesigner = async () => {
        try {
            const data = {
                idContest: currentContestInfo.idContest,
            }
            const res = await subscribeDesignerToContest(user.userInfo?.authToken || '', data)
            if (!res) return
            navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)
            ToastSuccess('Iscrizione avvenuta con successo!')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un problema durante l'iscrizione al contest")
        }
    }

    const getResourceElement = (resource: Resource) => {
        const fileType = resource.projectFile.slice(resource.projectFile.lastIndexOf('.') + 1)
        switch (fileType) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return (
                    <a
                        className="resource-image-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={resource.projectFile}
                            alt={resource.projectFile.slice(resource.projectFile.lastIndexOf('/'))}
                        />
                    </a>
                )
            default:
                return (
                    <a
                        className="resource-document-container"
                        href={resource.projectFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="resource-type-extension">{fileType}</span>
                    </a>
                )
        }
    }
    return (
        <div className="detail-body">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 48,
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                }}
            >
                {!!placementQuotes && (
                    <div>
                        <div className="section-title">Premi e riconoscimenti</div>
                        <div className="contest-placement-quotes-container">
                            <div className="contest-placement-quotes-container__quote">
                                <span>1° Posto</span>
                                <span className="contest-placement-quotes-container__quote__value">
                                    €{placementQuotes.p1}
                                </span>
                            </div>
                            <div className="contest-placement-quotes-container__quote">
                                <span>2° Posto</span>
                                <span className="contest-placement-quotes-container__quote__value">
                                    €{placementQuotes.p2}
                                </span>
                            </div>
                            <div className="contest-placement-quotes-container__quote">
                                <span>3° Posto</span>
                                <span className="contest-placement-quotes-container__quote__value">
                                    €{placementQuotes.p3}
                                </span>
                            </div>
                            <div className="contest-placement-quotes-container__quote">
                                <span>4° Posto</span>
                                <span className="contest-placement-quotes-container__quote__value">
                                    €{placementQuotes.p4}
                                </span>
                            </div>
                            <div className="contest-placement-quotes-container__quote">
                                <span>5° Posto</span>
                                <span className="contest-placement-quotes-container__quote__value">
                                    €{placementQuotes.p5}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <div className="section-title">Partecipazioni</div>
                    <div className="contest-placement-quotes-container">
                        <div className="contest-placement-quotes-container__quote">
                            <span>Partecipanti</span>
                            <span className="contest-placement-quotes-container__quote__value">
                                {currentContestInfo.designerSubscribers || 0}
                            </span>
                        </div>
                        <div className="contest-placement-quotes-container__quote">
                            <span>Scadenza</span>
                            <span className="contest-placement-quotes-container__quote__value">
                                {new Date(currentContestInfo.dataEnd).toLocaleDateString()}
                            </span>
                        </div>
                        {currentContestInfo.mySubscribe === 0 && (
                            <Button onClick={() => void subscribeDesigner()}>Partecipa</Button>
                        )}
                    </div>
                </div>
            </div>
            <div className="section-title">Informazioni contest</div>
            <div
                style={{
                    marginTop: 16,
                    marginBottom: 16,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 16,
                }}
            >
                <div className="rounded-chip">
                    {data.categories.find((cat) => cat.id === currentContestInfo.category)?.keyName || 'Contest'}
                </div>
                {data.tipologies.length > 0 && (
                    <div className="rounded-chip">
                        {data.tipologies.find((type) => parseInt(type.idTypology, 10) === currentContestInfo.typology)
                            ?.name || ''}
                    </div>
                )}
                <div className="rounded-chip">{currentContestInfo.MQ || 0} MQ</div>
            </div>
            <div>
                <p className="subtitle-text">{currentContestInfo.description}</p>
                <p className="subtitle-text">{currentContestInfo.externalAnnotation}</p>
            </div>
            <div className="section-title">{user.userInfo?.idSysGrant === 10 ? 'Costi' : 'Elaborati richiesti'}</div>
            <div className="contest-placement-quotes-container" style={{ width: '50%', minWidth: 200 }}>
                <div className="contest-placement-quotes-container__quote">
                    <span>{currentContestInfo.request.find((req) => req.extra === 0)?.description || ''}</span>
                </div>
                {user.userInfo?.idSysGrant === 10 && (
                    <div className="contest-placement-quotes-container__quote">
                        <span>Totale</span>
                        <span className="contest-placement-quotes-container__quote__value">
                            €{currentContestInfo.price}
                        </span>
                    </div>
                )}
                <div className="how-to-horizontal-divider" style={{ width: '100%' }} />
                <div className="contest-placement-quotes-container__quote" style={{ marginTop: 16 }}>
                    <span>Extra</span>
                </div>
                {!currentContestInfo.request || currentContestInfo.request.length < 2 ? (
                    <div className="contest-placement-quotes-container__quote">
                        <i>Nessun extra richiesto</i>
                    </div>
                ) : (
                    <StaticExtraReportSelector
                        selectedReports={currentContestInfo.request.filter((req) => req.extra !== 0)}
                    />
                )}
            </div>
            {/* <div className="section-title">Tag</div>
        <TagsContainer
            options={data.tags}
            selectedTags={currentContestInfo.tags}
            onTagsChange={(newTags) => setCurrentContestInfo({ ...currentContestInfo, tags: newTags })}
        /> */}
            <div className="section-title">Risorse</div>
            <div className="public-project-resources-container">
                {currentContestInfo.resource.map((res) => (
                    <div key={res.id} className="public-project-resources-container__resource-box">
                        {getResourceElement(res)}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PublicContestInfoSections
