import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ContestCard from '../../components/ContestCard/ContestCard'
import { getPaginatedContestsList, getPaginatedContestsSearch } from '../../resources/api-constants'
import { ROUTES } from '../../resources/routes-constants'
import { useAppSelector } from '../../store/reducers/store'
import { ContestSummary } from '../../types/contests'
import { PaginatedResponse } from '../../types/requests'
import { Tag } from '../../types/data'
import InfiniteScrollList from '../../components/InfiniteScrollList/InfiniteScrollList'

interface ContestPageProps {
    isPublicPage?: boolean
}

const ContestPage: React.FC<ContestPageProps> = (props) => {
    const user = useAppSelector((state) => state.user)
    const [contestsToDisplay, setContestsToDisplay] = useState<ContestSummary[]>([])
    const [availableContestsLength, setAvailableContestsLength] = useState<number | null>(null)
    const [currentOffset, setCurrentOffset] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(false)
    const [selectedSearchTags, setSelectedSearchTags] = useState<Tag[]>([])
    const navigate = useNavigate()
    const pageItemsSize = 12

    useEffect(() => {
        let hasMoreItemsToDownload =
            !!availableContestsLength &&
            contestsToDisplay.length > 0 &&
            contestsToDisplay.length < availableContestsLength
        if (availableContestsLength === null) {
            hasMoreItemsToDownload = true
        } else if (selectedSearchTags.length > 0 && props.isPublicPage) {
            hasMoreItemsToDownload = availableContestsLength === 0
        }
        setHasMore(hasMoreItemsToDownload)
    }, [contestsToDisplay, availableContestsLength, selectedSearchTags.length])

    const fetchMoreContests = async () => {
        try {
            setLoading(true)
            if (!hasMore) {
                setLoading(false)
                return
            }
            let contests: PaginatedResponse<ContestSummary> | undefined
            if (selectedSearchTags.length > 0) {
                contests = await getPaginatedContestsSearch(
                    user.accessToken,
                    pageItemsSize,
                    currentOffset,
                    selectedSearchTags.map((tag) => tag.keyTag).join(',')
                )
            } else {
                contests = await getPaginatedContestsList(user.accessToken, pageItemsSize, currentOffset)
            }

            if (!contests) return

            const finalContest = [...contestsToDisplay, ...contests.data]

            setCurrentOffset(finalContest.length)
            setAvailableContestsLength(contests.totalRecordCount)
            setContestsToDisplay(finalContest)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const resetContestListParams = () => {
        setContestsToDisplay([])
        setCurrentOffset(0)
        setAvailableContestsLength(0)
    }

    const launchContestsSearch = async () => {
        if (!availableContestsLength || loading) return
        await fetchMoreContests()
    }

    useEffect(() => {
        resetContestListParams()
        void launchContestsSearch()
    }, [selectedSearchTags.length])

    useEffect(() => {
        setSelectedSearchTags([]) // TODO: remove
        void fetchMoreContests()
    }, [])

    return (
        <InfiniteScrollList
            fetchMore={() => {
                if (loading) return
                void fetchMoreContests()
            }}
            hasMore={hasMore}
            isLoading={loading}
            currentDataLength={contestsToDisplay.length}
            isPrivatePage={!props.isPublicPage}
            emptyListMessage="Non ci sono ancora contest da mostrare."
        >
            <div className="page-title-container">
                <span className="page-title">I miei contest</span>
            </div>
            <div className="page-list-actions-header">
                {/* <TagsContainer
                    options={data.tags}
                    selectedTags={selectedSearchTags}
                    onTagsChange={(newSearchTags) => setSelectedSearchTags(newSearchTags)}
                /> */}
            </div>
            <div className="scroll-list-container">
                <div className="grid-scroll-list">
                    {contestsToDisplay.map((contestInfo) => (
                        <ContestCard
                            contestInfo={contestInfo}
                            key={contestInfo.idContest}
                            onClick={(idContest) => {
                                if (user.userInfo?.idSysGrant !== 10 && user.userInfo?.idSysGrant !== 0) {
                                    navigate(`${ROUTES.CONTESTS_DETAIL_ROUTE}${idContest}`, {
                                        state: { preview: true, idContest },
                                    })
                                } else {
                                    navigate(`${ROUTES.PERSONAL_GENERIC_CONTESTS_DETAIL_ROUTE}${idContest}`, {
                                        state: { preview: true, idContest },
                                    })
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        </InfiniteScrollList>
    )
}

export default ContestPage
