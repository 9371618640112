import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import '../../styles/pages/ContestCreationWidget.sass'

interface Props {
    onConfirm: (contestLocalization: string) => void
}

const DefineContestLocalization: React.FC<Props> = (props) => {
    const [contestLocalization, setContestLocalization] = useState('')

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Dove si trova questo contest?</h1>
                <p>Facci sapere a che città è destinato questo progetto.</p>
            </div>
            <div style={{ width: '100%' }}>
                <TextInput
                    value={contestLocalization}
                    placeholder="Inserisci qui dove si trova"
                    onChange={(newVal) => setContestLocalization(newVal)}
                />
                <div style={{ height: 24, width: 1 }} />
                <Button disabled={!contestLocalization} onClick={() => props.onConfirm(contestLocalization)}>
                    Avanti
                </Button>
            </div>
        </div>
    )
}

export default DefineContestLocalization
