export const ROUTES = {
    LOGIN_ROUTE: '/login',
    REGISTER_ROUTE: '/register',
    RESET_PASS_REQUEST_ROUTE: '/reset-password-request',
    HOW_IT_WORKS: '/how-it-works',
    CONFIRM_EMAIL_ROUTE: '/confirm-email',
    HOMEPAGE_ROUTE: '/',

    DESIGNERS_ROUTE: '/designers',
    DESIGNER_DETAIL: '/designer/:id',

    PROJECTS_ROUTE: '/projects',
    PROJECT_DETAIL_ROUTE: '/projects/:id',
    GENERIC_PROJECT_DETAIL_ROUTE: '/projects/',

    PERSONAL_CONTESTS_ROUTE: '/mycontests',
    PERSONAL_CONTESTS_DETAIL_ROUTE: '/mycontests/:id',
    PERSONAL_GENERIC_CONTESTS_DETAIL_ROUTE: '/mycontests/',

    PERSONAL_PROJECTS_ROUTE: '/myprojects',
    PERSONAL_PROJECT_DETAIL_ROUTE: '/myprojects/:id',
    PERSONAL_GENERIC_PROJECT_DETAIL_ROUTE: '/myprojects/',

    GENERATE_CONTESTS_ROUTE: '/generate-contest',
    CALCULATE_CONTEST_PRICES_ROUTE: '/contest-pricing',

    CONTESTS_ROUTE: '/contests',
    CONTESTS_DETAIL_ROUTE: '/contests/:id',
    GENERIC_CONTESTS_DETAIL_ROUTE: '/contests/',

    PROFILE_ROUTE: '/profile',
}
