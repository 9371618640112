import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import '../../styles/pages/ContestCreationWidget.sass'

interface Props {
    alreadyLoggedUser: boolean
    onConfirm: (userIdentity: string[]) => void
}

const DefineCustomerName: React.FC<Props> = (props) => {
    const [customerName, setCustomerName] = useState('')
    const [customerLastName, setCustomerLastName] = useState('')
    const [customerUsername, setCustomerUsername] = useState('')

    useEffect(() => {
        if (props.alreadyLoggedUser) props.onConfirm(['', '', ''])
    }, [props.alreadyLoggedUser])

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Il tuo nome e cognome</h1>
                <p>
                    Scrivi qui il tuo nome e il tuo cognome per creare il tuo profilo e collegarlo al Progetto che hai
                    appena creato.
                </p>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 24,
                    width: '100%',
                }}
            >
                <TextInput
                    value={customerName}
                    placeholder="Scrivi qui il tuo nome"
                    onChange={(newVal) => setCustomerName(newVal)}
                />
                <TextInput
                    value={customerLastName}
                    placeholder="Scrivi qui il tuo cognome"
                    onChange={(newVal) => setCustomerLastName(newVal)}
                />
                <TextInput
                    value={customerUsername}
                    placeholder="Scrivi qui il tuo nome utente"
                    onChange={(newVal) => setCustomerUsername(newVal)}
                />
            </div>
            <div style={{ height: 24, width: 1 }} />
            <Button
                disabled={!customerName || !customerLastName}
                onClick={() => props.onConfirm([customerName, customerLastName, customerUsername])}
            >
                Avanti
            </Button>
        </div>
    )
}

export default DefineCustomerName
