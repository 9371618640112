import React from 'react'
import './TextInput.sass'

interface InputTextProps {
    value: string
    label?: string
    type?: string
    placeholder?: string
    maxLength?: number
    disabled?: boolean
    isTextarea?: boolean
    canAutocomplete?: boolean
    onChange: (newString: string) => void
}

const TextInput: React.FC<InputTextProps> = (props) => {
    return (
        <div className="text-input-container">
            {props.label && <div className="text-input-label">{props.label}</div>}
            {props.isTextarea ? (
                <textarea
                    disabled={!!props.disabled}
                    placeholder={props.placeholder || ''}
                    className="interactive text-input-textarea-element"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                />
            ) : (
                <input
                    maxLength={props.maxLength || undefined}
                    disabled={!!props.disabled}
                    placeholder={props.placeholder || ''}
                    className="interactive text-input-input-element"
                    type={props.type || 'text'}
                    value={props.value}
                    autoComplete={props.canAutocomplete ? undefined : 'new-password'}
                    onChange={(e) => props.onChange(e.target.value)}
                />
            )}
        </div>
    )
}

export default TextInput
