import React from 'react'
import './AlertModal.sass'
import Button from '../Button/Button'

interface AlertModalProps {
    message: string
    onConfirm: () => void
    onCancel: () => void
}

const AlertModal: React.FC<AlertModalProps> = (props) => {
    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" />
            <div className="alert-modal-card">
                <div className="alert-modal-card__body">
                    <p>{props.message}</p>
                </div>
                <div className="alert-modal-card__footer">
                    <Button type="secondary" onClick={() => props.onCancel()}>
                        Annulla
                    </Button>
                    <Button onClick={() => props.onConfirm()}>Conferma</Button>
                </div>
            </div>
        </div>
    )
}

export default AlertModal
