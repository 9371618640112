import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../resources/api-constants'
import { Category, ContestStatusType, ResourceTypology, Tag, Tipology, TipologySize } from '../../types/data'
import { LoggedUserInfo, LoginCredentials } from '../../types/user'
import CustomAxios from '../../utility/customAxios'
import { getRequestHeader } from '../../utility/functions'
import { AppDispatch } from '../reducers/store'
import {
    setCategories,
    setContestStatusTypes,
    setResourceTipologies,
    setTags,
    setTipologies,
    setTipologiesSizes,
} from './data'
import { setAccessToken, setLoggedUserInfo } from './user'

// --- AUTH ---

export const performUserLogin = createAsyncThunk<void, LoginCredentials, { dispatch: AppDispatch }>(
    'groupedActions/performUserLogin',
    async (loginCredentials, { dispatch }) => {
        try {
            const loggedUserData: LoggedUserInfo = await CustomAxios.post(
                `${baseUrl}auth/login`,
                loginCredentials
            ).then((response) => response.data)
            dispatch(setLoggedUserInfo(loggedUserData))
            dispatch(setAccessToken(loggedUserData.authToken))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- USER ---

// export const updateUserData = createAsyncThunk<void, LoginCredentials, { dispatch: AppDispatch }>(
//     'groupedActions/updateUserData',
//     async (loginCredentials, { dispatch }) => {
//         try {
//             const updatedUser: LoggedUserInfo = await CustomAxios.put(
//                 `${baseUrl}user`,
//                 loginCredentials
//             ).then((response) => response.data)

//             dispatch(setLoggedUserInfo(updatedUser))
//         } catch (e) {
//             console.error(e)
//             throw e
//         }
//     }
// )

// --- DATA ---

export const getTags = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getTags',
    async (authToken, { dispatch }) => {
        try {
            let tags: Tag[] = []
            if (!authToken) {
                tags = await CustomAxios.get(`${baseUrl}tagslist`).then((response) => response.data.data)
            }

            tags = await CustomAxios.get(`${baseUrl}tagslist`, getRequestHeader(authToken)).then(
                (response) => response.data.data
            )

            dispatch(setTags(tags))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const getCategories = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getCategories',
    async (authToken, { dispatch }) => {
        try {
            const categories: Category[] = await CustomAxios.get(
                `${baseUrl}categories`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setCategories(categories.sort((a, b) => (a.orderList > b.orderList ? 1 : -1))))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const getTipologies = createAsyncThunk<
    void,
    { authToken: string; idCategory: number },
    { dispatch: AppDispatch }
>('groupedActions/getTipologies', async ({ authToken, idCategory }, { dispatch }) => {
    try {
        const tipologies: Tipology[] = await CustomAxios.get(
            `${baseUrl}typologies?id_category=${idCategory}&lang=IT`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        dispatch(setTipologies(tipologies.sort((a, b) => (a.orderList > b.orderList ? 1 : -1))))
    } catch (e) {
        console.error(e)
        throw e
    }
})

export const getTipologySizes = createAsyncThunk<
    void,
    { authToken: string; idTypology: number },
    { dispatch: AppDispatch }
>('groupedActions/getTipologySizes', async ({ authToken, idTypology }, { dispatch }) => {
    try {
        const sizes: TipologySize[] = await CustomAxios.get(
            `${baseUrl}sizetypology?id_typology=${idTypology}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        dispatch(setTipologiesSizes(sizes.sort((a, b) => (a.orderList > b.orderList ? 1 : -1))))
    } catch (e) {
        console.error(e)
        throw e
    }
})

export const getResourceTypes = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getResourceTypes',
    async (authToken, { dispatch }) => {
        try {
            const resTypes: ResourceTypology[] = await CustomAxios.get(
                `${baseUrl}resourcetype`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setResourceTipologies(resTypes))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const getContestStatusTypes = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getContestStatusTypes',
    async (authToken, { dispatch }) => {
        try {
            const resTypes: ContestStatusType[] = await CustomAxios.get(
                `${baseUrl}contestlisttype`,
                getRequestHeader(authToken)
            ).then((response) => response.data.data)
            dispatch(setContestStatusTypes(resTypes))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const loadGeneralData = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/loadGeneralData',
    async (authToken, { dispatch }) => {
        try {
            await dispatch(getTags(authToken))
            // await dispatch(getCategories(authToken))
            // await dispatch(getTipologies({ authToken, idCategory: 1 }))
            await dispatch(getResourceTypes(authToken))
            await dispatch(getContestStatusTypes(authToken))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)
