import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import { ContestSummary } from '../../types/contests'
import DefineContestCategory from './DefineContestCategory'
import DefineContestSpaceSize from './DefineContestSpaceSize'
import DefineContestType from './DefineContestType'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { getCategories, getTipologies, getTipologySizes } from '../../store/actions/thunk_actions'
import { setTipologies, setTipologiesSizes } from '../../store/actions/data'
import DefineContestDuration from './DefineContestDuration'
import ContestRecap from './ContestRecap'

const emptyContest: ContestSummary = {
    ID: -1,
    idContest: '',
    idUser: '',
    title: '',
    description: '',
    dataCreations: '',
    dataStart: '',
    dataEnd: '',
    price: '',
    amountPrize: '',
    ready: 0,
    solved: 0,
    status: 0,
    internalAnnotations: '',
    externalAnnotation: '',
    localization: '',
    category: 0,
    typology: 0,
    MQ: 0,
    tags: [],
    public: 0,
    designerSubscribers: 0,
    designerCompleted: 0,
    expired: 0,
    request: [],
    idTypologySize: 0,
    resource: [],
    myPresentation: 0,
    mySubscribe: 0,
    tempNameDesigner: '',
    remainingDays: null,
    zipFile: '',
}

const ContestPricingPage: React.FC = () => {
    const data = useAppSelector((data) => data.data)
    const user = useAppSelector((data) => data.user)
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [currentContextData, setCurrentContextData] = useState<ContestSummary>(emptyContest)
    const [typologySizeId, setTipologySizeId] = useState('')
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const loadGeneralInfo = async () => {
        try {
            await dispatch(getCategories(user.accessToken))
        } catch (error) {
            console.error(error)
        }
    }

    const updateTypologies = async () => {
        try {
            if (currentContextData && currentContextData.category !== undefined) {
                await dispatch(setTipologies([]))
                await dispatch(getTipologies({ authToken: user.accessToken, idCategory: currentContextData.category }))
            }
        } catch (error) {
            console.error(error)
        }
    }

    const updateTypologiesSizes = async () => {
        try {
            if (currentContextData && currentContextData.typology !== undefined) {
                await dispatch(setTipologiesSizes([]))
                await dispatch(
                    getTipologySizes({ authToken: user.accessToken, idTypology: currentContextData.typology })
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void updateTypologies()
    }, [currentContextData.category])

    useEffect(() => {
        void updateTypologiesSizes()
    }, [currentContextData.typology])

    useEffect(() => {
        void loadGeneralInfo()
    }, [currentContextData])

    const getCurrentCreationStep = () => {
        switch (currentStepIndex) {
            case 0:
                return (
                    <DefineContestCategory
                        options={data.categories.map((cat) => ({ id: cat.IdCategory, key: cat.keyName }))}
                        onConfirm={(idCat) => {
                            setCurrentContextData({ ...currentContextData, category: parseInt(idCat, 10) })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 1:
                return (
                    <DefineContestType
                        options={data.tipologies.map((type) => ({ id: type.idTypology, key: type.name }))}
                        onConfirm={(idType) => {
                            setCurrentContextData({ ...currentContextData, typology: parseInt(idType, 10) })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 2:
                return (
                    <DefineContestSpaceSize
                        options={data.tipologySizes.map((type) => {
                            const words = type.name.split(' ')
                            return {
                                id: type.idTypologySize,
                                title: words[0],
                                subtitle: words.slice(1).join(' ') || '',
                                maxSize: type.maxSize,
                            }
                        })}
                        onConfirm={(sizeData) => {
                            setCurrentContextData({ ...currentContextData, MQ: sizeData.preciseContestSize })
                            setTipologySizeId(sizeData.idTypologySize)
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )
            case 3:
                return (
                    <DefineContestDuration
                        onConfirm={(duration) => {
                            setCurrentContextData({ ...currentContextData, duration })
                            setCurrentStepIndex(currentStepIndex + 1)
                        }}
                    />
                )

            case 4:
                return <ContestRecap tipologySizeId={typologySizeId} contest={currentContextData} />
            default:
                return null
        }
    }

    return (
        <div className="page-main-container">
            <div className="page-title-container">
                <span className="page-title">Calcola i costi del tuo progetto</span>
            </div>
            <div className="page-main-header" style={{ marginTop: 16 }}>
                <Button
                    type="ghost"
                    onClick={() => {
                        navigate(ROUTES.HOMEPAGE_ROUTE)
                    }}
                >
                    Annulla
                </Button>
                {currentStepIndex > 0 && currentStepIndex < 4 && (
                    <Button
                        type="ghost"
                        onClick={() => {
                            if (currentStepIndex) {
                                setCurrentStepIndex(currentStepIndex - 1)
                            }
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {currentStepIndex === 4 && (
                    <Button
                        onClick={() => {
                            navigate(ROUTES.GENERATE_CONTESTS_ROUTE)
                        }}
                    >
                        Crea un contest
                    </Button>
                )}
            </div>
            {getCurrentCreationStep()}
        </div>
    )
}

export default ContestPricingPage
