import React, { useEffect, useState } from 'react'
import { Resource } from '../../types/data'
import { truncateText } from '../../utility/functions'
import './ResourceCard.sass'

interface ResourceCardProps {
    resourceData: Resource
    onClick: () => void
}

const ResourceCard: React.FC<ResourceCardProps> = (props) => {
    const [resource, setResource] = useState<Resource>(props.resourceData)

    useEffect(() => {
        setResource(props.resourceData)
    }, [props.resourceData])

    const getResourceMediaFilePreview = (resourceFile: string) => {
        const extension = resourceFile.slice(resourceFile.lastIndexOf('.'))
        const filename = resourceFile.slice(resourceFile.lastIndexOf('/') + 1)
        switch (extension) {
            case '.jpeg':
            case '.jpg':
            case '.png':
                return <img src={resourceFile} alt={filename} />
            default:
                return (
                    <div className="file-extension-display">
                        <span className="file-extension-display__extension">{extension.slice(1)}</span>
                        <span className="file-extension-display__filename">{truncateText(filename, 50)}</span>
                    </div>
                )
        }
    }

    return (
        <div className="resource-card" onClick={() => props.onClick()}>
            <div className="resource-card__image-container">{getResourceMediaFilePreview(resource.projectFile)}</div>
        </div>
    )
}

export default ResourceCard
