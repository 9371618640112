import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import './Button.sass'

interface ButtonProps {
    children?: string | ReactNode
    type?: 'primary' | 'secondary' | 'error' | 'ghost'
    disabled?: boolean
    loading?: boolean
    onClick: () => void
}

const Button: React.FC<ButtonProps> = (props) => {
    return (
        <button
            className={`o-button ${props.type ? `o-${props.type}-button` : 'o-primary-button'}`}
            onClick={() => props.onClick()}
            disabled={props.disabled}
        >
            {props.loading && (
                <div className="loading-button-wrapper">
                    <div className="loading-button-wrapper__loader rotating">
                        <FontAwesomeIcon icon={faSpinner} size="2x" />
                    </div>
                </div>
            )}
            {props.children}
        </button>
    )
}

export default Button
