import React from 'react'
import './ExtraReportSelector.sass'
import { ReportRequest } from '../../types/contests'

interface Props {
    selectedReports: ReportRequest[]
}

const StaticExtraReportSelector: React.FC<Props> = (props) => {
    return (
        <>
            {props.selectedReports.map((selectedReport) => (
                <div key={selectedReport.id} style={{ margin: '8px 0' }}>
                    <div className="contest-placement-quotes-container__quote">
                        <span className="contest-placement-quotes-container__quote__value">{selectedReport.name}</span>
                        {/* <span className="contest-placement-quotes-container__quote__value">
                            €{selectedReport.price}
                        </span> */}
                    </div>
                    <div className="contest-placement-quotes-container__quote">
                        <span>{selectedReport.description}</span>
                    </div>
                </div>
            ))}
        </>
    )
}

export default StaticExtraReportSelector
