import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useQueryParamsFromURL = <T>(acceptedParams: (keyof T)[]): T | undefined => {
    const [queryParamsFromURL] = useSearchParams()
    const [routeParams, setRouteParams] = useState<T | undefined>()

    useEffect(() => {
        const temporaryParams: any = {}
        acceptedParams.forEach((param) => {
            const singleParam = queryParamsFromURL.get(param as string)
            temporaryParams[param] = singleParam !== null ? singleParam : ''
        })
        setRouteParams(temporaryParams)
        // eslint-disable-next-line
    }, [queryParamsFromURL])

    return routeParams
}