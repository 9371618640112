import React, { useEffect, useMemo, useState } from 'react'
import TabsSelector from '../../components/TabsSelector/TabsSelector'
import { getUserDetailedInfo } from '../../resources/api-constants'
import { USER_GRANTS } from '../../resources/other-constants'
import { useAppSelector } from '../../store/reducers/store'
import { CustomerProfile, DesignerProfile } from '../../types/user'
import ProfileInfo from './ProfileInfo'
import ProfileStats from './ProfileStats'
import '../../styles/pages/profilePage.sass'
import InfiniteListLoader from '../../components/Loader/InfiniteListLoader'
import { sectionObject } from '../../types/data'

const ProfilePage: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedSectionId, setSelectedSectionId] = useState('info')
    const [profileType, setProfileType] = useState(USER_GRANTS.DESIGNER)
    const [customerUserInfo, setCustomerUserInfo] = useState<CustomerProfile | null>(null)
    const [designerUserInfo, setDesignerUserInfo] = useState<DesignerProfile | null>(null)

    const profileSections = useMemo((): sectionObject[] => {
        const finalSections: sectionObject[] = [
            {
                id: 'info',
                label: 'Informazioni',
            },
        ]
        const designerSections: sectionObject[] = [
            {
                id: 'stats',
                label: 'Statistiche',
            },
        ]
        const customerSections: sectionObject[] = []
        if (profileType === USER_GRANTS.CUSTOMER) {
            finalSections.push(...customerSections)
        }
        if (profileType === USER_GRANTS.DESIGNER) {
            finalSections.push(...designerSections)
        }
        return finalSections
    }, [profileType])

    // useEffect(() => {
    //     setSelectedSectionId(profileSections[0].id)
    // }, [profileSections])

    const renderProfileSection = useMemo(() => {
        switch (selectedSectionId) {
            case 'stats':
                return <ProfileStats profileInfo={designerUserInfo} />
            case 'info':
            default:
                return (
                    <ProfileInfo
                        profileInfo={customerUserInfo || designerUserInfo}
                        profileType={profileType}
                        sendUpdateUser={() => null}
                    />
                )
        }
    }, [selectedSectionId, customerUserInfo, designerUserInfo])

    const getUserPersonalInfo = async () => {
        setIsLoading(true)
        try {
            const userInfo = await getUserDetailedInfo(user.accessToken)
            if (!userInfo) return
            if (userInfo.grant === USER_GRANTS.CUSTOMER) {
                setCustomerUserInfo(userInfo.profileInfo as CustomerProfile)
            }
            if (userInfo.grant === USER_GRANTS.DESIGNER || userInfo.grant === USER_GRANTS.DESIGNER_PRIME) {
                const curricula = JSON.parse((userInfo.profileInfo as DesignerProfile).curricula as any) || null
                setDesignerUserInfo({ ...(userInfo.profileInfo as DesignerProfile), curricula })
            }
            setProfileType(userInfo.grant as USER_GRANTS)
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void getUserPersonalInfo()
    }, [])

    return (
        <div className="page-main-container detail-page">
            <div className="page-title-container">
                <span className="page-title">Profilo</span>
            </div>
            {isLoading ? (
                <InfiniteListLoader text="caricamento profilo" />
            ) : (
                <>
                    {profileSections.length > 1 && (
                        <div className="page-list-actions-header">
                            <TabsSelector
                                options={profileSections}
                                selectedTabId={selectedSectionId}
                                onSelectedTabChange={(newTab) => setSelectedSectionId(newTab)}
                            />
                        </div>
                    )}
                    {renderProfileSection}
                </>
            )}
        </div>
    )
}

export default ProfilePage
