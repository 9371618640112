import { createReducer } from '@reduxjs/toolkit'
import { LoggedUserInfo } from '../../types/user'
import { logout, setAccessToken, setLoggedUserInfo, setUserTablePageSize } from '../actions/user'

interface UserReducer {
    userInfo: LoggedUserInfo | null
    accessToken: string
    userTablePageSize: string
}

const initialState: UserReducer = {
    userInfo: null,
    accessToken: '',
    userTablePageSize: '10',
}

const userReducer = createReducer<UserReducer>(initialState, (builder) => {
    builder.addCase(setLoggedUserInfo, (state, action) => {
        state.userInfo = action.payload
    })
    builder.addCase(setAccessToken, (state, action) => {
        state.accessToken = action.payload
    })
    builder.addCase(setUserTablePageSize, (state, action) => {
        state.userTablePageSize = action.payload
    })
    builder.addCase(logout, (state) => {
        state.userInfo = null
    })
})

export default userReducer
