import { faChevronLeft, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import InfiniteListLoader from '../../components/Loader/InfiniteListLoader'
import TagsContainer from '../../components/TagsContainer/TagsContainer'
import TextInput from '../../components/TextInput/TextInput'
import ResourcesSection from '../../customComponents/ResourcesSection/ResourcesSection'
import {
    createNewProject,
    deleteSingleProject,
    getSingleProject,
    updateSingleProject,
} from '../../resources/api-constants'
import { ResourcesTypes } from '../../resources/other-constants'
import { ROUTES } from '../../resources/routes-constants'
import { useAppSelector } from '../../store/reducers/store'
import { Resource, Tag } from '../../types/data'
import { MainProjectInfo } from '../../types/projects'
import { ToastError, ToastSuccess } from '../../utility/toast'
import AlertModal from '../../components/AlertModal/AlertModal'

const emptyProject: MainProjectInfo = {
    id: -1,
    idProject: '',
    idDesigner: '',
    title: '',
    description: '',
    tags: [],
    isContest: 0,
    enabled: 0,
    dataCreations: '',
    resource: [],
    likes: 0,
    name: '',
}

const ProjectDetailPage: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const data = useAppSelector((state) => state.data)
    const [isPreviewMode, setIsPreviewMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [saveProjectIsLoading, setSaveProjectIsLoading] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [currentProjectInfo, setCurrentProjectInfo] = useState<MainProjectInfo>(emptyProject)
    const [currentProjectCover, setCurrentProjectCover] = useState<Resource | null>(null)
    const [mainTag, setMainTag] = useState<Tag[] | null>(null)
    const { state } = useLocation()
    const navigate = useNavigate()
    // const publishProjectMessage =
    //     "Rendi questo progetto visibile a tutti, posizionandolo all'interno della mia vetrina."

    const fetchProjectData = async () => {
        try {
            setIsLoading(true)
            const project = await getSingleProject(user.accessToken, state.idProject)
            if (project) {
                setCurrentProjectInfo(project)
                setCurrentProjectCover(
                    project.resource.find(
                        (resource) => resource.typeResource === ResourcesTypes.PROJECT_FILE_BANNER_IMAGE
                    ) || null
                )
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (state !== null && state.idProject !== undefined) {
            void fetchProjectData()
            setIsPreviewMode(!!state.preview)
        }
    }, [state])

    // const deleteProject = async () => {
    //     try {
    //         await deleteSingleProject(user.accessToken, state.idProject)
    //         navigate(ROUTES.PERSONAL_PROJECTS_ROUTE)
    //         ToastSuccess('Progetto eliminato con successo!')
    //     } catch (error) {
    //         console.error(error)
    //         ToastError('Non è stato possibile eliminare il progetto.')
    //     }
    // }

    const saveProject = async () => {
        try {
            setSaveProjectIsLoading(true)
            const projectData = {
                title: currentProjectInfo.title,
                description: currentProjectInfo.description,
                idDesigner: user.userInfo?.codeDrawup,
                tags: `${mainTag && mainTag[0] ? `${mainTag[0].keyTag},` : ''}${currentProjectInfo.tags
                    .map((tag) => tag.keyTag)
                    .join(',')}`,
                enabled: 1,
            }
            if (!state) {
                const newProject = await createNewProject(user.accessToken, projectData)
                if (!newProject) throw new Error('Invalid Project')
                navigate(`${ROUTES.PERSONAL_GENERIC_PROJECT_DETAIL_ROUTE}${newProject.idProject}`, {
                    state: { preview: true, idProject: newProject.idProject },
                })
            } else {
                await updateSingleProject(user.accessToken, {
                    ...projectData,
                    idProject: state.idProject,
                    id: currentProjectInfo.id,
                })
                navigate(`${ROUTES.PERSONAL_GENERIC_PROJECT_DETAIL_ROUTE}${state.idProject}`, {
                    state: { preview: true, idProject: state.idProject },
                })
            }
            ToastSuccess('Progetto salvato con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante il salvataggio del progetto.')
        }
        setSaveProjectIsLoading(false)
    }

    const deleteProject = async () => {
        setShowAlertModal(false)
        setSaveProjectIsLoading(true)
        try {
            const res = await deleteSingleProject(user.accessToken, state.idProject)
            if (!res) {
                throw new Error('DFError - Unable to delete project.')
            }
            navigate(`${ROUTES.PERSONAL_PROJECTS_ROUTE}`)
            ToastSuccess('Progetto eliminato con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante la cancellazione del progetto.')
        }
        setSaveProjectIsLoading(false)
    }

    return (
        <>
            {showAlertModal && (
                <AlertModal
                    message="Sei sicuro di voler eliminare il progetto? Questa azione è irreversibile."
                    onCancel={() => setShowAlertModal(false)}
                    onConfirm={() => void deleteProject()}
                />
            )}
            <div className="page-main-container detail-page">
                <div className="page-main-header">
                    <div className="page-title-container">
                        <span className="page-title">
                            <label className="page-title__link-text">
                                <Link to={ROUTES.PERSONAL_PROJECTS_ROUTE}>Progetti</Link> /
                            </label>
                            {currentProjectInfo && state ? currentProjectInfo.title : 'Nuovo progetto'}
                            {currentProjectInfo && (
                                <label className="project-status-label">
                                    {currentProjectInfo.enabled ? 'Pubblico' : 'Privato'}
                                </label>
                            )}
                        </span>
                    </div>
                    <div className="page-actions-container">
                        {isPreviewMode ? (
                            <>
                                <Button type="secondary" onClick={() => navigate(ROUTES.PERSONAL_PROJECTS_ROUTE)}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </Button>
                                <Button onClick={() => setIsPreviewMode(false)}>
                                    <FontAwesomeIcon icon={faLockOpen} />
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button type="secondary" onClick={() => navigate(ROUTES.PERSONAL_PROJECTS_ROUTE)}>
                                    Annulla
                                </Button>
                                {state && (
                                    <Button type="error" onClick={() => setShowAlertModal(true)}>
                                        Elimina
                                    </Button>
                                )}
                                <Button
                                    loading={saveProjectIsLoading}
                                    disabled={currentProjectInfo.title === '' || saveProjectIsLoading}
                                    onClick={() => void saveProject()}
                                >
                                    Salva
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                {currentProjectInfo && !isLoading ? (
                    <div className="detail-body">
                        <div className="section-title">Generali</div>
                        <div className="project-general-form">
                            <TextInput
                                label="NOME*"
                                maxLength={50}
                                disabled={isPreviewMode}
                                value={currentProjectInfo.title}
                                onChange={(newVal) => setCurrentProjectInfo({ ...currentProjectInfo, title: newVal })}
                            />
                            <div />
                            {/* <Checkbox
                            disabled={isPreviewMode || currentProjectInfo.resource.length === 0}
                            value={!!currentProjectInfo.enabled}
                            onChange={(newValue) =>
                                setCurrentProjectInfo({ ...currentProjectInfo, enabled: newValue ? 1 : 0 })
                            }
                            title={publishProjectMessage}
                        >
                            {publishProjectMessage}
                            <b>
                                {currentProjectInfo.resource.length === 0 &&
                                    ' Carica almeno una risorsa per poter pubblicare il progetto.'}
                            </b>
                        </Checkbox> */}
                            <TextInput
                                label="DESCRIZIONE"
                                isTextarea
                                disabled={isPreviewMode}
                                value={currentProjectInfo.description}
                                onChange={(newVal) =>
                                    setCurrentProjectInfo({ ...currentProjectInfo, description: newVal })
                                }
                            />
                        </div>
                        <div className="section-title">Tag principale</div>
                        <TagsContainer
                            disabled={isPreviewMode}
                            singleVal
                            fixAdornment={currentProjectInfo.tags.length > 0}
                            options={data.tags.filter((tag) => tag.category === 'Main')}
                            selectedTags={mainTag ? [...mainTag] : []}
                            onTagsChange={(newTags) => setMainTag(newTags)}
                        />
                        <div className="section-title">Tag secondari</div>
                        <TagsContainer
                            disabled={isPreviewMode}
                            fixAdornment={currentProjectInfo.tags.length > 0}
                            options={data.tags.filter((tag) => tag.category !== 'Main')}
                            selectedTags={currentProjectInfo.tags}
                            onTagsChange={(newTags) => setCurrentProjectInfo({ ...currentProjectInfo, tags: newTags })}
                        />
                        {state && (
                            <>
                                <div className="section-title">Risorse</div>
                                <ResourcesSection
                                    disabled={isPreviewMode}
                                    projectCodeDrawup={currentProjectInfo.idProject}
                                    mainResource={currentProjectCover}
                                    attachments={currentProjectInfo.resource}
                                />
                            </>
                        )}
                    </div>
                ) : (
                    <InfiniteListLoader text="Sto caricando il progetto" />
                )}
            </div>
        </>
    )
}

export default ProjectDetailPage
