import React from 'react'
import '../../styles/pages/profilePage.sass'
import { DesignerProfile, PlacementsObject } from '../../types/user'

interface ProfileStatsProps {
    profileInfo: DesignerProfile | null
}

const ProfileStats: React.FC<ProfileStatsProps> = (props) => {
    const renderPlacements = () => {
        const placements: PlacementsObject | undefined = props.profileInfo?.placement
        if (!placements) return ''
        return Object.values(placements).map((placementValue: number, i) => (
            <div className="placements-section" key={i.toFixed()}>
                <span className="placement-value">{placementValue}</span>
                <div className="placement-footer">{i + 1}°</div>
            </div>
        ))
    }

    return (
        <div className="profile-section">
            <div className="profile-section__wrapper">
                <div className="stats-cards-container">
                    <div className="stats-card">
                        <div className="stats-card-header">Ranking</div>
                        <div className="stats-card-body">
                            <div className="stats-card-body__placements">{renderPlacements()}</div>
                            <div className="stats-card-body__points">
                                <div className="stats-card-body__points__points-counter">
                                    <span>Rank</span>
                                    <label>{props.profileInfo?.myRank}</label>
                                </div>
                                <div className="stats-card-body__points__points-counter">
                                    <span>Punti</span>
                                    <label>{props.profileInfo?.points}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="stats-card">
                        <div className="stats-card-header">Profitti</div>
                        <div className="stats-card-body">
                            <div className="stats-card-body__profits">
                                <span>
                                    {props.profileInfo?.profits?.amountCredit || 0}
                                    <label>€</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileStats
