import React from 'react'
import './Footer.sass'

const Footer: React.FC = () => {
    return (
        <div className="dark-background-section footer">
            <div className="footer__text">
                <span>
                    Leonardo Srl - Via Modena 119 - 44122 Ferrara - <a href="mailto:info@drawup.it">info@drawup.it</a>
                </span>
                <div className="footer__text__bottom-section">
                    <div>
                        <a href="https://22hbg.com/privacy-policy/" target="_blank" rel="noreferrer">
                            Privacy Policy
                        </a>
                    </div>
                    <div className="links-row">
                        <a href="https://fb.watch/j_p4KXX-Uu/" target="_blank" rel="noreferrer">
                            Facebook
                        </a>
                        <a href="https://instagram.com/_drawup_?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                            Instagram
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
