import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button/Button'
import Footer from '../components/Footer/Footer'
import { ROUTES } from '../resources/routes-constants'
import '../styles/pages/Homepage.sass'
import isometricHouse from '../resources/images/isometric_house.png'
import { ProjectSummary } from '../types/projects'
import { getPaginatedDesignersList, getPaginatedProjectsList } from '../resources/api-constants'
import { PaginatedResponse } from '../types/requests'
import ProjectCard from '../components/ProjectCard/ProjectCard'
import { DesignerProfile } from '../types/user'
import DesignerCard from '../components/DesignerCard/DesignerCard'
import twelfth from './../resources/images/avatars/twelfth.png'
import eigth from './../resources/images/avatars/eigth.png'
import ninth from './../resources/images/avatars/ninth.png'
import SkeletonCard from '../components/SkeletonCard/SkeletonCard'

const HomePage: React.FC = () => {
    const [visibleProjects, setVisibleProjects] = useState<ProjectSummary[]>([])
    const [visibleDesigners, setVisibleDesigners] = useState<DesignerProfile[]>([])
    const navigate = useNavigate()

    const fetchProjects = async () => {
        try {
            const projects: PaginatedResponse<ProjectSummary> | undefined = await getPaginatedProjectsList(
                '',
                3,
                0,
                true
            )
            if (projects) {
                setVisibleProjects(projects.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchDesigners = async () => {
        try {
            const designers: PaginatedResponse<DesignerProfile> | undefined = await getPaginatedDesignersList('', 3, 0)
            if (designers) {
                setVisibleDesigners(designers.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void fetchProjects()
        void fetchDesigners()
    }, [])

    return (
        <div className="page-main-container">
            <div className="homepage-intro-box align-left-box">
                <div className="homepage-intro-box__pattern" />
                <div className="homepage-intro-box__text-container">
                    <div className="homepage-intro-box__text-container__text">
                        <h1>Con DrawUp potrai avere una vera e propria esplosione di idee</h1>
                        <p>Stili e background diversi in competizione per l’ispirazione che non ti aspettavi</p>
                        <Button onClick={() => navigate(ROUTES.GENERATE_CONTESTS_ROUTE)}>Inizia subito</Button>
                    </div>
                </div>
                <div className="isometric-house-container">
                    <img src={isometricHouse} alt="DrawUp" />
                </div>
            </div>
            <div className="dark-gradient-background-section">
                <div className="dark-gradient-background-section__text-container">
                    <div className="dark-gradient-background-section__text-container__text">
                        <h1>Scopri di più</h1>
                        <p>Esplora nuove opportunità di design e lasciati contaminare dai progetti</p>
                    </div>
                </div>
            </div>
            <div className="cards-wrapper negative-margin-top">
                <div className="grid-scroll-list">
                    {visibleProjects && visibleProjects.length > 0 ? (
                        visibleProjects.map((projectInfo) => (
                            <ProjectCard
                                onClick={(idProject) => {
                                    navigate(`${ROUTES.PROJECT_DETAIL_ROUTE}${idProject}`, {
                                        state: { preview: true, idProject },
                                    })
                                }}
                                projectInfo={projectInfo}
                                key={projectInfo.id}
                                isPublic
                            />
                        ))
                    ) : (
                        <>
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                        </>
                    )}
                </div>
                <Button
                    type="secondary"
                    onClick={() => navigate(ROUTES.PROJECTS_ROUTE, { state: { isPublicPage: true } })}
                >
                    Vedi altro
                </Button>
            </div>
            <div className="how-to-horizontal-divider" />
            <div className="homepage-section-wrapper">
                <div className="homepage-section-wrapper__text">
                    <h1>I nostri designer</h1>
                    <div className="section-header-row">
                        <p>Migliaia di professionisti da tutto il mondo.</p>
                        <a href={ROUTES.REGISTER_ROUTE}>Diventa un designer</a>
                    </div>
                </div>
                <div className="cards-wrapper">
                    <div className="grid-scroll-list">
                        {visibleDesigners && visibleDesigners.length > 0 ? (
                            visibleDesigners.map((designerInfo) => (
                                <DesignerCard
                                    onClick={() =>
                                        navigate(`${ROUTES.DESIGNER_DETAIL}${designerInfo.idDesigner}`, {
                                            state: {
                                                designerName: designerInfo.name,
                                                designerId: designerInfo.idDesigner,
                                            },
                                        })
                                    }
                                    designer={designerInfo}
                                    key={designerInfo.id}
                                />
                            ))
                        ) : (
                            <>
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                            </>
                        )}
                    </div>
                    <Button type="secondary" onClick={() => navigate(ROUTES.DESIGNERS_ROUTE)}>
                        Vedi altro
                    </Button>
                </div>
            </div>
            <div className="how-to-horizontal-divider" />
            <div className="homepage-section-wrapper">
                <div className="homepage-section-wrapper__text">
                    <h1>Come funziona</h1>
                    <div className="section-header-row">
                        <p>DrawUp come hub per dare un valore aggiunto al patrimonio immobiliare in pochi passaggi.</p>
                    </div>
                </div>
                <div className="info-cards-wrapper">
                    <div className="home-info-card">
                        <div className="home-info-card__image-container">
                            <img src={twelfth} alt="DrawUp" />
                        </div>
                        <h1>Descrivi il tuo progetto</h1>
                        <p>
                            Esponi le tue esigenze: i tuoi obiettivi o i vincoli che hai, indica cosa vorresti ottenere
                            e cosa evitare, aggiungi eventuali commenti, riferimenti e così via.
                        </p>
                    </div>
                    <div className="home-info-card">
                        <div className="home-info-card__image-container">
                            <img src={eigth} alt="DrawUp" />
                        </div>
                        <h1>Interagisci con i Designer</h1>
                        <p>
                            Durante il contest sfrutta la chat personale per rispondere alle domande dei designer: così
                            facendo i progetti ricevuti saranno ancora di più in linea con le tue aspettative.
                        </p>
                    </div>
                    <div className="home-info-card dark-info-card">
                        <div className="home-info-card__image-container">
                            <img src={ninth} alt="DrawUp" />
                        </div>
                        <h1>Scegli e premia i vincitori</h1>
                        <p>Dopo aver visionato i progetti ricevuti, dai la tua valutazione e scegli i vincitori.</p>
                    </div>
                </div>
                <Button onClick={() => navigate(ROUTES.GENERATE_CONTESTS_ROUTE)}>Inizia subito</Button>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage
