import React, { useEffect, useMemo } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PublicHeader from './components/PublicHeader/PublicHeader'
import LoginPage from './pages/auth_pages/LoginPage'
import RegisterPage from './pages/auth_pages/RegisterPage'
import ResetPasswordPage from './pages/auth_pages/ResetPasswordPage'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import ProfilePage from './pages/Profile/ProfilePage'
import ProjectDetailPage from './pages/Projects/ProjectDetailPage'
import ProjectsPage from './pages/Projects/ProjectsPage'
import { ROUTES } from './resources/routes-constants'
import { getTags, loadGeneralData } from './store/actions/thunk_actions'
import { useAppDispatch, useAppSelector } from './store/reducers/store'
import './styles/main.sass'
import MainLayout from './components/MainLayout/MainLayout'
import DesignersPage from './pages/PublicPages/Designers/DesignersPage'
import PublicProjectDetailPage from './pages/Projects/PublicProjectDetailPage'
import WhatsappButton from './components/WhatsappButton/WhatsappButton'
import ConfirmEmailPage from './pages/auth_pages/ConfirmEmailPage'
import HowItWorks from './pages/HowItWorks'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { frontendBaseUrl } from './resources/api-constants'
import CreateContestPage from './pages/CreateContestWizard.tsx/CreateContestPage'
import ContestPage from './pages/Contests/ContestPage'
import ContestDetailPage from './pages/Contests/ContestDetailPage'
import PublicContestDetailPage from './pages/Contests/PublicContestDetailPage'
import DesignerDetailPage from './pages/PublicPages/Designers/DesignerDetailPage'
import ContestPricingPage from './pages/CalculatePrices/ContestPricingPage'

const RootComponent: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()

    useEffect(() => {
        void dispatch(getTags(''))
        if (!user.userInfo || !user.userInfo.authToken) return
        void dispatch(loadGeneralData(user.userInfo.authToken))
    }, [user])

    const getPublicRoutes = useMemo(() => {
        return (
            <>
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                <Route path={ROUTES.PROJECTS_ROUTE} element={<ProjectsPage isPublicPage />} />
                <Route path={ROUTES.PROJECT_DETAIL_ROUTE} element={<PublicProjectDetailPage />} />
                <Route path={ROUTES.DESIGNERS_ROUTE} element={<DesignersPage />} />
                <Route path={ROUTES.DESIGNER_DETAIL} element={<DesignerDetailPage />} />
                <Route path={ROUTES.CONFIRM_EMAIL_ROUTE} element={<ConfirmEmailPage />} />
                <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorks />} />
                <Route path={ROUTES.GENERATE_CONTESTS_ROUTE} element={<CreateContestPage />} />
                <Route path={ROUTES.CALCULATE_CONTEST_PRICES_ROUTE} element={<ContestPricingPage />} />
            </>
        )
    }, [])

    return (
        <Router basename={frontendBaseUrl}>
            <PublicHeader loggedUser={user.userInfo || undefined} />
            <ToastContainer />
            <WhatsappButton />
            {user.userInfo && user.userInfo.authToken !== '' ? (
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route
                        path={ROUTES.PERSONAL_PROJECTS_ROUTE}
                        element={
                            <MainLayout>
                                <ProjectsPage />
                            </MainLayout>
                        }
                    />
                    <Route
                        path={ROUTES.PERSONAL_PROJECT_DETAIL_ROUTE}
                        element={
                            <MainLayout>
                                <ProjectDetailPage />
                            </MainLayout>
                        }
                    />
                    <Route
                        path={ROUTES.PERSONAL_CONTESTS_ROUTE}
                        element={
                            <MainLayout>
                                <ContestPage />
                            </MainLayout>
                        }
                    />
                    <Route
                        path={ROUTES.PERSONAL_CONTESTS_DETAIL_ROUTE}
                        element={
                            <MainLayout>
                                <ContestDetailPage />
                            </MainLayout>
                        }
                    />
                    <Route
                        path={ROUTES.CONTESTS_DETAIL_ROUTE}
                        element={
                            <MainLayout>
                                <PublicContestDetailPage />
                            </MainLayout>
                        }
                    />
                    <Route
                        path={ROUTES.PROFILE_ROUTE}
                        element={
                            <MainLayout>
                                <ProfilePage />
                            </MainLayout>
                        }
                    />
                    {getPublicRoutes}
                </Routes>
            ) : (
                <Routes>
                    <Route path={ROUTES.LOGIN_ROUTE} element={<LoginPage />} />
                    <Route path={ROUTES.REGISTER_ROUTE} element={<RegisterPage />} />
                    <Route path={ROUTES.RESET_PASS_REQUEST_ROUTE} element={<ResetPasswordPage />} />
                    {getPublicRoutes}
                </Routes>
            )}
        </Router>
    )
}

export default RootComponent
