import React from 'react'
import whatsappLogo from '../../resources/images/whatsapp.png'
import './WhatsappButton.sass'

const newChatLink =
    'https://api.whatsapp.com/send/?phone=393398189891&text=Ciao%2C%0D%0Avorrei+avere+maggiori+informazioni+su+DrawUp.&type=phone_number&app_absent=0'

const WhatsappButton: React.FC = () => {
    return (
        <div className="whatsapp-button-container">
            <a className="whatsapp-button" href={newChatLink} target="_blank" rel="noreferrer">
                <img alt="WhatsApp" src={whatsappLogo} />
            </a>
        </div>
    )
}

export default WhatsappButton
