import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import './PasswordInput.sass'

interface InputTextProps {
    value: string
    label?: string
    type?: string
    placeholder?: string
    disabled?: boolean
    revealPassword?: boolean
    displayIcon?: boolean
    autocomplete?: boolean
    onChange: (newString: string) => void
    onIconClick?: (newVal: boolean) => void
}

const PasswordInput: React.FC<InputTextProps> = (props) => {
    const [hideText, setHideText] = useState(false)

    useEffect(() => {
        setHideText(!!props.revealPassword)
    }, [props.revealPassword])

    return (
        <div className="password-input-container">
            {props.label && <div className="password-input-label">{props.label}</div>}
            <div className="password-input-input-wrapper">
                <input
                    tabIndex={0}
                    className="interactive password-input-input-element"
                    type={props.type || 'text'}
                    value={props.value}
                    autoComplete={props.autocomplete ? 'on' : 'new-password'}
                    onChange={(e) => props.onChange(e.target.value)}
                />
                {props.displayIcon && (
                    <div tabIndex={1} className="interactive password-input-icon">
                        <FontAwesomeIcon
                            icon={hideText ? faEyeSlash : faEye}
                            onClick={() => {
                                if (props.onIconClick) props.onIconClick(!hideText)
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default PasswordInput
