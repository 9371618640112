import React from 'react'
import { ContestSummary } from '../../types/contests'
import './ContestCard.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from '../../store/reducers/store'
import Button from '../Button/Button'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { activatePayedContest, subscribeDesignerToContest, updateExistingContest } from '../../resources/api-constants'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'

interface ContestCardProps {
    contestInfo: ContestSummary
    onClick: (idContest: string) => void
}

const ContestCard: React.FC<ContestCardProps> = (props) => {
    const data = useAppSelector((data) => data.data)
    const user = useAppSelector((data) => data.user)
    const navigate = useNavigate()

    const getStatusLabel = (currentStatus: number) => {
        if (currentStatus === null || currentStatus === undefined) return ''
        let stat = ''
        stat = data.contestStatusTypes.find((statusType) => statusType.Value === currentStatus)?.statusName || ''

        // TODO: rimuovere fallback per customer che non riescono a scaricare tutti gli stauts con il loro token
        if (!stat) {
            switch (currentStatus) {
                case 0:
                    stat = 'DRAFT'
                    break
                case 1:
                    stat = 'READY TO PAY'
                    break
                default:
                    stat = ''
                    break
            }
        }
        return stat
    }

    const activateContest = async () => {
        try {
            const data = {
                idContest: props.contestInfo.idContest,
            }
            await activatePayedContest(user.userInfo?.authToken || '', data)
            navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)
            ToastSuccess('Contest attivato con successo!')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un problema durante l'attivazione del contest")
        }
    }

    const upgradeDraftProject = async () => {
        try {
            const data = {
                status: 1,
            }
            await updateExistingContest(user.userInfo?.authToken || '', data)
            navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)
            ToastSuccess('Contest inviato con successo!')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un problema durante l'invio per l'approvazione del contest")
        }
    }

    const subscribeDesigner = async () => {
        try {
            const data = {
                idContest: props.contestInfo.idContest,
            }
            const res = await subscribeDesignerToContest(user.userInfo?.authToken || '', data)
            if (!res) return
            navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)
            ToastSuccess('Iscrizione avvenuta con successo!')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un problema durante l'iscrizione al contest")
        }
    }

    const renderActionButton = () => {
        // pulsante per promuovere da draft a ready to pay (customer)
        if (user.userInfo?.idSysGrant === 10 && props.contestInfo.status === 0) {
            return <Button onClick={() => void upgradeDraftProject()}>Invia per attivazione</Button>
        }

        // pulsante per iscriversi ad un contest (designer)
        if (
            (user.userInfo?.idSysGrant === 6 || user.userInfo?.idSysGrant === 7) &&
            props.contestInfo.status >= 2 &&
            props.contestInfo.status < 5 && props.contestInfo.mySubscribe === 0
        ) {
            return <Button onClick={() => void subscribeDesigner()}>Partecipa</Button>
        }

        // pulsante avvia contest dopo pagamento (admin)
        if (user.userInfo?.idSysGrant === 0 && props.contestInfo.status === 1) {
            return <Button onClick={() => void activateContest()}>Attiva</Button>
        }
        return <div style={{ minWidth: 190, height: 1, background: 'rgba(0,0,0,.07)' }} />
    }

    return (
        <div className="contest-summary-card" onClick={() => props.onClick(props.contestInfo.idContest)}>
            <div className="contest-summary-card__text-container">
                <span className="contest-title">
                    [{props.contestInfo.idContest}] {props.contestInfo.title}
                </span>
                {/* <p className="contest-description">{truncateText(props.contestInfo.description, 180)}</p> */}
                <div className="contest-designer-card">
                    {props.contestInfo.dataEnd !== null && (
                        <span>
                            <label className="contest-labels">Scadenza:</label>{' '}
                            {new Date(props.contestInfo.dataEnd).toLocaleDateString()}
                        </span>
                    )}
                    {user.userInfo?.idSysGrant === 10 || user.userInfo?.idSysGrant === 0 ? (
                        <span>
                            <label className="contest-labels">Costo:</label> {props.contestInfo.price}€
                        </span>
                    ) : (
                        <span>
                            <label className="contest-labels">Montepremi:</label> {props.contestInfo.amountPrize}€
                        </span>
                    )}
                </div>
            </div>
            <div style={{ marginLeft: '-8px' }}>
                <label className="project-status-label">{getStatusLabel(props.contestInfo.status)}</label>
            </div>
            <div className="contest-summary-card__tags-container" style={{ minWidth: 220 }}>
                {props.contestInfo.tags.length > 0 && (
                    <span className="project-card-tag">{props.contestInfo.tags[0].keyName}</span>
                )}
                {props.contestInfo.MQ !== null && <span className="project-card-tag">{props.contestInfo.MQ} MQ</span>}
                <span className="project-card-tag">
                    <FontAwesomeIcon icon={faUsers} color="#fecb47" /> {props.contestInfo.designerSubscribers || 0}
                </span>
            </div>

            <div>{renderActionButton()}</div>
        </div>
    )
}

export default ContestCard
