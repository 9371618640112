import React, { useEffect, useMemo, useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import '../../styles/pages/ContestCreationWidget.sass'

interface Props {
    newCustomer: boolean
    alreadyLoggedUser: boolean
    onConfirm: (passwordData: string[]) => void
}

const DefineCustomerPassword: React.FC<Props> = (props) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const checkInvalidData = useMemo(() => {
        const failedPassCheck = props.newCustomer ? password !== confirmPassword : password === ''
        return failedPassCheck
    }, [password, confirmPassword, props.newCustomer])

    useEffect(() => {
        if (props.alreadyLoggedUser) props.onConfirm(['', ''])
    }, [props.alreadyLoggedUser])

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>La tua area riservata</h1>
                {props.newCustomer ? (
                    <p>
                        Per tenere sotto controllo i tuoi Progetti ti abbiamo preparato un’area riservata Premium
                        protetta con una password. Crea ora la tua password per accedervi.
                    </p>
                ) : (
                    <p>Per tenere sotto controllo i tuoi Progetti accedi all’area riservata protetta da password.</p>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 24,
                    width: '100%',
                }}
            >
                {props.newCustomer ? (
                    <>
                        <TextInput
                            value={password}
                            placeholder="Scrivi qui la tua nuova password"
                            onChange={(newVal) => setPassword(newVal)}
                        />
                        <TextInput
                            value={confirmPassword}
                            placeholder="Conferma la tua nuova password"
                            onChange={(newVal) => setConfirmPassword(newVal)}
                        />
                    </>
                ) : (
                    <>
                        <TextInput
                            value={password}
                            placeholder="Scrivi qui la tua password"
                            onChange={(newVal) => setPassword(newVal)}
                        />
                    </>
                )}
            </div>
            <div style={{ height: 24, width: 1 }} />
            <Button disabled={checkInvalidData} onClick={() => props.onConfirm([password, confirmPassword])}>
                Avanti
            </Button>
        </div>
    )
}

export default DefineCustomerPassword
