import React from 'react'
import '../../styles/pages/ContestCreationWidget.sass'

interface Props {
    onConfirm: (selectedDuration: number) => void
}

const options = [
    {
        id: 0,
        value: 30,
        key: '1 mese',
    },
    {
        id: 1,
        value: 60,
        key: '2 mesi',
    },
]

const DefineContestDuration: React.FC<Props> = (props) => {
    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>Quanto tempo durerà il contest?</h1>
                <p>
                    Seleziona la durata che desideri impostare al contest che stai creando, una volta raggiunto il
                    numero minimo di partecipanti verrà impostata una data di inizo e di fine.
                </p>
            </div>
            <div className="contest-creation-items-row">
                {options.map((contestDuration) => (
                    <div
                        key={contestDuration.id}
                        className="interactive-box"
                        onClick={() => props.onConfirm(contestDuration.value)}
                    >
                        {contestDuration.key}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DefineContestDuration
