/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import {
    getSingleContest,
    getSingleContestPlacementQuotes,
    updateContestExtraReports,
    updateExistingContest,
} from '../../resources/api-constants'
import { ROUTES } from '../../resources/routes-constants'
import { useAppSelector } from '../../store/reducers/store'
import { ContestSummary } from '../../types/contests'
import { PlacementQuote } from '../../types/user'
import '../../styles/pages/contestPage.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { sectionObject } from '../../types/data'
import { ToastError, ToastSuccess } from '../../utility/toast'
import TabsSelector from '../../components/TabsSelector/TabsSelector'
import ContestInfoSection from './ContestDetailSections/ContestInfoSection'
import ContestParticipationsSection from './ContestDetailSections/ContestParticipationsSection'
import ContestMessagesSection from './ContestDetailSections/ContestMessagesSection'

const emptyContest: ContestSummary = {
    ID: -1,
    idContest: '',
    idUser: '',
    title: '',
    description: '',
    dataCreations: '',
    dataStart: '',
    dataEnd: '',
    price: '',
    amountPrize: '',
    ready: 0,
    solved: 0,
    status: 0,
    internalAnnotations: '',
    externalAnnotation: '',
    localization: '',
    category: 0,
    typology: 0,
    MQ: 0,
    tags: [],
    public: 0,
    designerSubscribers: 0,
    designerCompleted: 0,
    expired: 0,
    request: [],
    idTypologySize: 0,
    resource: [],
    myPresentation: 0,
    mySubscribe: 0,
    tempNameDesigner: '',
    remainingDays: null,
    zipFile: '',
}

const ContestDetailPage: React.FC = () => {
    const user = useAppSelector((state) => state.user)
    const data = useAppSelector((state) => state.data)
    const [isPreviewMode, setIsPreviewMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [currentContestInfo, setCurrentContestInfo] = useState<ContestSummary>(emptyContest)
    const [placementQuotes, setPlacementQuotes] = useState<PlacementQuote | null>(null)
    const [saveContestInfoIsLoading, setSaveContestInfoIsLoading] = useState(false)
    const [selectedSectionId, setSelectedSectionId] = useState('info')
    const { state } = useLocation()
    const navigate = useNavigate()

    const contestSections = useMemo((): sectionObject[] => {
        const finalSections: sectionObject[] = []
        const customerSections: sectionObject[] = [
            {
                id: 'info',
                label: 'Informazioni',
            },
            {
                id: 'messages',
                label: 'Messaggi',
            },
        ]
        const designerSections: sectionObject[] = [
            {
                id: 'info',
                label: 'Informazioni',
            },
            // {
            //     id: 'stats',
            //     label: 'Messaggi',
            // },
        ]
        if (currentContestInfo.status > 1) {
            // Se è un designer o designer-prime
            if (user.userInfo?.idSysGrant === 6 || user.userInfo?.idSysGrant === 7) {
                finalSections.push(...designerSections)
            } else {
                finalSections.push(...customerSections)
                if (currentContestInfo.status > 4) {
                    finalSections.push(
                        {
                            id: 'participations',
                            label: 'Partecipazioni',
                        }
                    )
                }
            }
        }
        return finalSections
    }, [user, currentContestInfo])

    const fetchContestData = async () => {
        try {
            setIsLoading(true)
            const project = await getSingleContest(user.accessToken, state.idContest)
            const quotes = await getSingleContestPlacementQuotes(user.accessToken, state.idContest)
            // const resources = await getSingleContestResources(user.accessToken, state.idContest)
            if (project) {
                setCurrentContestInfo(project)
            }
            if (quotes) {
                setPlacementQuotes(quotes)
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (state !== null && state.idContest !== undefined) {
            void fetchContestData()
            setIsPreviewMode(!!state.preview)
        }
    }, [state])

    const renderActiveSection = useMemo(() => {
        switch (selectedSectionId) {
            case 'info':
                return (
                    <ContestInfoSection
                        isPublicPage={false}
                        contestInfo={currentContestInfo}
                        loading={isLoading}
                        onContestSave={(updatedContest: ContestSummary | null, newExtraReportIDs: number[]) => {
                            if (updatedContest) {
                                void saveContest(updatedContest, newExtraReportIDs)
                            }
                        }}
                        resources={currentContestInfo.resource}
                        placementQuotes={placementQuotes}
                    />
                )
            case 'participations':
                return (
                    <ContestParticipationsSection
                        contest={currentContestInfo}
                        participations={currentContestInfo.result || []}
                        prizeAmounts={
                            placementQuotes
                                ? [
                                      placementQuotes.p1,
                                      placementQuotes.p2,
                                      placementQuotes.p3,
                                      placementQuotes.p4,
                                      placementQuotes.p5,
                                  ]
                                : []
                        }
                    />
                )
            case 'messages':
                return <ContestMessagesSection isPublicPage={false} currentContest={currentContestInfo} />
            default:
                return
        }
    }, [selectedSectionId, isLoading, currentContestInfo, placementQuotes])

    const saveContest = async (updatedContest: ContestSummary, newExtraReportIDs: number[]) => {
        try {
            setSaveContestInfoIsLoading(true)
            // Modifica del contest se ancora in DRAFT

            const data = {
                idContest: currentContestInfo.idContest,
                report: newExtraReportIDs.map((id) => ({ reportType: id })),
            }

            await updateContestExtraReports(user.accessToken, data)

            const contestData = {
                title: updatedContest.title,
                description: updatedContest.description,
                externalAnnotation: updatedContest.externalAnnotation,
                tags: updatedContest.tags.map((tag) => tag.keyTag).join(','),
                public: updatedContest.public,
                localization: updatedContest.localization,
            }
            if (!state) {
                // const newProject = await createNewProject(user.accessToken, projectData)
                // if (!newProject) throw new Error('Invalid Project')
                // navigate(`${ROUTES.PERSONAL_GENERIC_PROJECT_DETAIL_ROUTE}${newProject.idProject}`, {
                //     state: { preview: true, idProject: newProject.idProject },
                // })
                console.error('DFError - At this point the project should already exist')
            } else {
                await updateExistingContest(user.accessToken, {
                    ...contestData,
                    idContest: updatedContest.idContest,
                })
                navigate(`${ROUTES.PERSONAL_GENERIC_CONTESTS_DETAIL_ROUTE}${state.idContest}`, {
                    state: { preview: true, idContest: state.idContest },
                })
            }
            ToastSuccess('Contest salvato con successo!')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante il salvataggio del contest.')
        }
        setSaveContestInfoIsLoading(false)
    }

    const getStatusLabel = (currentStatus: number) => {
        if (currentStatus === null || currentStatus === undefined) return ''
        let stat = ''
        stat = data.contestStatusTypes.find((statusType) => statusType.Value === currentStatus)?.statusName || ''

        // TODO: rimuovere fallback per customer che non riescono a scaricare tutti gli stauts con il loro token
        if (!stat) {
            switch (currentStatus) {
                case 0:
                    stat = 'DRAFT'
                    break
                case 1:
                    stat = 'READY TO PAY'
                    break
                default:
                    stat = ''
                    break
            }
        }
        return stat
    }

    return (
        <div className="page-main-container detail-page">
            <div className="page-main-header">
                <div className="page-title-container">
                    <span className="page-title">
                        <label className="page-title__link-text">
                            <Link to={ROUTES.PERSONAL_CONTESTS_ROUTE}>Contest</Link> /
                        </label>
                        {currentContestInfo ? currentContestInfo.title : 'Nuovo contest'}
                        {currentContestInfo && (
                            <>
                                <label className="project-status-label">{currentContestInfo.idContest}</label>
                                <label className="project-status-label">
                                    {currentContestInfo.public ? 'Pubblico' : 'Privato'}
                                </label>
                                <label className="project-status-label">
                                    {getStatusLabel(currentContestInfo.status)}
                                </label>
                            </>
                        )}
                    </span>
                </div>
                <div className="page-actions-container">
                    {isPreviewMode ? (
                        <>
                            <Button type="secondary" onClick={() => navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button type="secondary" onClick={() => navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)}>
                                Annulla
                            </Button>
                            {/* {state && (
                                <Button type="error" onClick={() => void deleteProject()}>
                                    Elimina
                                </Button>
                            )} */}
                            <Button
                                loading={saveContestInfoIsLoading}
                                disabled={currentContestInfo.title === '' || saveContestInfoIsLoading}
                                onClick={() => null}
                            >
                                Salva
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <div className="page-actions-container" style={{ marginTop: 24 }}>
                {contestSections.length > 1 && currentContestInfo.status > 1 && (
                    <TabsSelector
                        options={contestSections}
                        selectedTabId={selectedSectionId}
                        onSelectedTabChange={(newTab) => setSelectedSectionId(newTab)}
                    />
                )}
            </div>
            {renderActiveSection}
        </div>
    )
}

export default ContestDetailPage
