import React from 'react'
import './../styles/pages/HowItWorks.sass'
import firstAvatar from './../resources/images/avatars/first.png'
import secondAvatar from './../resources/images/avatars/second.png'
import thirdAvatar from './../resources/images/avatars/third.png'
import fourthAvatar from './../resources/images/avatars/fourth.png'
import fifthAvatar from './../resources/images/avatars/fifth.png'
import sixthAvatar from './../resources/images/avatars/sixth.png'
import seventhAvatar from './../resources/images/avatars/seventh.png'
import eighthAvatar from './../resources/images/avatars/eigth.png'
import ninthAvatar from './../resources/images/avatars/ninth.png'
import tenthAvatar from './../resources/images/avatars/tenth.png'
import eleventhAvatar from './../resources/images/avatars/eleventh.png'
import twelfthAvatar from './../resources/images/avatars/twelfth.png'
import thirteenthAvatar from './../resources/images/avatars/thirteenth.png'
import elegantAvatar from './../resources/images/avatars/elegantAvatar.png'
import glassesAvatar from './../resources/images/avatars/glassesAvatar.png'
import sweaterAvatar from './../resources/images/avatars/sweaterAvatar.png'
import Footer from '../components/Footer/Footer'
import Button from '../components/Button/Button'
import { ROUTES } from '../resources/routes-constants'
import { useNavigate } from 'react-router-dom'

const HowItWorks: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div className="page-main-container">
            <div className="intro-box align-left-box">
                <div className="intro-box__pattern" />
                <div className="intro-box__text">
                    <h1>Scopri come funziona</h1>
                    <span>
                        Esplora nuove opportunità di design e metti in mostra il tuo talento sulla piattaforma di
                        progettazione online dove designer e clienti si incontrano per dare vita a nuove idee.
                    </span>
                    <p className="uppercase-subtitle">
                        Con un unico Contest ad un unico prezzo, avrai decine di progetti.
                    </p>
                </div>
            </div>
            <div className="dark-background-section">
                <div className="dark-background-section__text">
                    Hai bisogno di progettarne uno spazio da zero o di rinnovarlo? Drawup può aiutarti! Organizza la tua
                    gara di progettazione, stabilisci la sua durata e, al suo completamento, seleziona tra i numerosi
                    progetti di alta qualità ricevuti.
                </div>
            </div>
            <span className="howto-title-section">DrawUp è facile, veloce e molto intuitivo</span>
            <div className="two-columns-box">
                <div className="how-to-image-container">
                    <img src={firstAvatar} alt="DrawUp" />
                </div>
                <div className="two-columns-box__text-col">
                    <h2>Una soluzione a portata di click</h2>
                    <span>
                        Andrea vuole rinnovare un suo spazio. Potrebbe rivolgersi ad alcuni professionisti della sua
                        zona ma quello che riuscirebbe ad ottene sarebbero solo preventivi stratosferici ed elaborati
                        simili. Stanco di perdere tempo e soldi, Andrea decide di usare DrawUp in cerca di ispirazione.
                    </span>
                </div>
            </div>
            <div className="two-columns-box">
                <div className="two-columns-box__text-col">
                    <h2>Semplicemente lancia un contest</h2>
                    <span>
                        Soddisfatto dell’opportunità offerta da DrawUp, Andrea si iscrive, e seguendo le istruzioni, in
                        pochi minuti, la sua richiesta arriva alla community di designer DrawUp. Il tutto comodamente da
                        casa sua.
                    </span>
                </div>
                <div className="how-to-image-container wide-image-container">
                    <img src={secondAvatar} alt="DrawUp" />
                    <img src={elegantAvatar} alt="DrawUp" />
                    <img src={glassesAvatar} alt="DrawUp" />
                    <img src={sweaterAvatar} alt="DrawUp" />
                </div>
            </div>
            <div className="two-columns-box">
                <div className="how-to-image-container">
                    <img src={thirdAvatar} alt="DrawUp" />
                </div>
                <div className="two-columns-box__text-col">
                    <h2>Con un unico prezzo, ottiene decine di progetti e soluzioni</h2>
                    <span>
                        Nel tempo indicato Andrea riceve decine di progetti ideati sulla base delle sue richieste, da
                        architetti e designer di tutto il mondo. Può quindi visionare le proposte e scegliere un
                        vincitore per iniziare la ristrutturazione.
                    </span>
                </div>
            </div>
            <div className="how-to-horizontal-divider" />
            <span className="howto-title-section">
                Cosa ti serve sapere per lanciare il tuo contest su DrawUp, passo dopo passo
            </span>
            <div className="two-columns-box">
                <div className="how-to-image-container">
                    <img src={fourthAvatar} alt="DrawUp" />
                </div>
                <div className="two-columns-box__text-col">
                    <h2>Esponi le tue esigenze</h2>
                    <span>
                        Descrivi il tuo progetto: le tue necessità, obiettivi o i vincoli che hai, indica cosa vorresti
                        ottenere e cosa evitare, aggiungi eventuali commenti, riferimenti e così via. Ovviamente più
                        informazioni fornisci ai designers, migliori saranno i progetti e i risultati che otterrai.
                    </span>
                </div>
            </div>
            <div className="two-columns-box">
                <div className="two-columns-box__text-col">
                    <h2>Carica le immagini</h2>
                    <span>
                        Scatta delle foto della situazione attuale e arricchisci la tua descrizione con dei particolari
                        di cui tenere conto, inserisci anche immagini che ti piacciono, così da essere di riferimento,
                        così da fornire ai designer un suggerimento sui tuoi gusti. Se le hai, aggiungi le piantine con
                        le misure o quanto ritieni utile per un migliore risultato del contest.
                    </span>
                </div>
                <div className="how-to-image-container">
                    <img src={fifthAvatar} alt="DrawUp" />
                </div>
            </div>
            <div className="two-columns-box">
                <div className="how-to-image-container">
                    <img src={sixthAvatar} alt="DrawUp" />
                </div>
                <div className="two-columns-box__text-col">
                    <h2>Lancia il Contest</h2>
                    <span>
                        Subito dopo il lancio, il tuo Contest con tutti i dettagli sarà visibile ai designer che
                        potranno proporre decine di progetti sorprendenti realizzati su misura per il tuo spazio e
                        secondo le tue direttive.
                    </span>
                </div>
            </div>
            <div className="two-columns-box">
                <div className="two-columns-box__text-col">
                    <h2>Interagisci con i Designer</h2>
                    <span>
                        Durante il contest sfrutta la chat personale per rispondere alle domande dei designer: così
                        facendo i progetti ricevuti saranno ancora di più in linea con le tue aspettative.
                    </span>
                </div>
                <div className="how-to-image-container">
                    <img src={seventhAvatar} alt="DrawUp" />
                </div>
            </div>
            <div className="two-columns-box">
                <div className="how-to-image-container">
                    <img src={eighthAvatar} alt="DrawUp" />
                </div>
                <div className="two-columns-box__text-col">
                    <h2>Valuta Tu i progetti ricevuti</h2>
                    <span>
                        Al termine del Contest potrai vedere tutte le proposte e valutare i progetti in base alla loro
                        originalità, fattibilità e corrispondenza alle tue direttive.
                    </span>
                </div>
            </div>
            <div className="two-columns-box">
                <div className="two-columns-box__text-col">
                    <h2>Scegli e premia i vincitori</h2>
                    <span>Dopo aver visionato i progetti ricevuti, dai la tua valutazione e scegli i vincitori.</span>
                </div>
                <div className="how-to-image-container">
                    <img src={ninthAvatar} alt="DrawUp" />
                </div>
            </div>
            <div className="two-columns-box">
                <div className="how-to-image-container">
                    <img src={tenthAvatar} alt="DrawUp" />
                </div>
                <div className="two-columns-box__text-col">
                    <h2>Realizza il tuo spazio</h2>
                    <span>
                        Al termine riceverai sia gli elaborati che i contatti dei designer vincitori: in questo modo, se
                        vorrai, potrai lavorare con loro e trasformare il tuo spazio in realtà.
                    </span>
                </div>
            </div>
            <div className="how-to-horizontal-divider" />
            <span className="howto-title-section">Quali sono i vantaggi del contest?</span>
            <div className="two-columns-box">
                <div className="two-columns-box__text-col">
                    <h2>Progetti innovativi e di alta qualità</h2>
                    <span>
                        Riceverai decine di proposte ed elaborati per la realizzazione del tuo progetto ideati da
                        architetti e designer professionisti da ogni parte del mondo.
                    </span>
                </div>
                <div className="how-to-image-container">
                    <img src={eleventhAvatar} alt="DrawUp" />
                </div>
            </div>
            <div className="two-columns-box">
                <div className="how-to-image-container">
                    <img src={twelfthAvatar} alt="DrawUp" />
                </div>
                <div className="two-columns-box__text-col">
                    <h2>Decine soluzioni proposte diverse</h2>
                    <span>
                        Perché dovresti accontentarti di un solo progetto? Con DrawUp ricevi decine di soluzioni diverse
                        e sei tu a scegliere quella più adatta alle tue esigenze.
                    </span>
                </div>
            </div>
            <div className="two-columns-box">
                <div className="two-columns-box__text-col">
                    <h2>Scegli tu i tempi</h2>
                    <span>
                        Su Drawup, i tempi li decidi tu: fissa la durata, lancia il tuo Contest e aspetta che i designer
                        trasformino il tuo desiderio in realtà.
                    </span>
                </div>
                <div className="how-to-image-container">
                    <img src={thirteenthAvatar} alt="DrawUp" />
                </div>
            </div>
            <div>
                <Button type="primary" onClick={() => navigate(ROUTES.PROJECTS_ROUTE)}>
                    Sfoglia le idee dei nostri designer
                </Button>
            </div>
            <Footer />
        </div>
    )
}

export default HowItWorks
