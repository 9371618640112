import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import { userResetPasswordRequest } from '../../resources/api-constants'
import { mailRegex } from '../../resources/other-constants'
import { ROUTES } from '../../resources/routes-constants'
import '../../styles/pages/authPages.sass'
import { ResetPasswordRequestBody } from '../../types/requests'

const ResetPasswordPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [errorIsVisible, setErrorIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const emailFromState = location.state.email || ''
        if (mailRegex.test(emailFromState)) setEmail(emailFromState)
    }, [])

    const incorrectData = useMemo(() => {
        return email === '' || !mailRegex.test(email)
    }, [email])

    const loginUser = async () => {
        try {
            setIsLoading(true)
            setErrorIsVisible(false)
            const body: ResetPasswordRequestBody = {
                email,
            }
            const res = await userResetPasswordRequest(body).catch((e) => {
                return e
            })
            if (res.error) throw res.error
            navigate(ROUTES.LOGIN_ROUTE)
        } catch (error) {
            setErrorIsVisible(true)
            console.error(error)
        }
        setIsLoading(false)
    }

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div className="auth-inputs-container">
                <h1 style={{ fontSize: '2em' }}>Reset password</h1>
                <TextInput label="Email" value={email} onChange={(newVal) => setEmail(newVal)} />
                {errorIsVisible ? (
                    <p className="error-container">
                        Qualcosa è andato storto durante l&apos;invio. Controlla la mail inserita e riprova.
                    </p>
                ) : (
                    <div style={{ height: '8px' }} />
                )}
                <Button disabled={isLoading || incorrectData} onClick={async () => void loginUser()}>
                    {isLoading ? 'Invio in corso...' : 'Invia'}
                </Button>
                <Link className="auth-link" to={ROUTES.REGISTER_ROUTE}>
                    Crea un account
                </Link>
            </div>
        </div>
    )
}

export default ResetPasswordPage
