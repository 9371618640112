import { createReducer } from '@reduxjs/toolkit'
import { setDrawerIsClosed } from '../actions/graphics'

interface GraphicsReducer {
    drawerIsClosed: boolean
}

const initialState: GraphicsReducer = {
    drawerIsClosed: true,
}

const graphicsReducer = createReducer<GraphicsReducer>(initialState, (builder) => {
    builder.addCase(setDrawerIsClosed, (state, action) => {
        state.drawerIsClosed = action.payload
    })
})

export default graphicsReducer
