/* eslint-disable indent */
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import './ExtraReportSelector.sass'
import { ReportRequest } from '../../types/contests'
import { ReportTypology } from '../../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import CheckBox from '../Checkbox/Checkbox'
import Button from '../Button/Button'

interface Props {
    selectedReports: ReportRequest[]
    availableExtraReports: GroupedExtraReports | null
    idTypologySize: number
    disabled?: boolean
    allSectionsAreCollapsed?: boolean
    contestId: string
    onSelectionChange: (selectedIDs: ExtraReportInfo[]) => void
    onClose: () => void
}

interface ExtraReportEntrySelectorProps {
    report: ReportTypology
    disabled?: boolean
    selected?: boolean
    onChange: (newVal: boolean) => void
}

interface ExtraReportSectionContainerProps {
    children?: ReactNode
    title: string
    allCollapsed?: boolean
}

export interface GroupedExtraReports {
    [key: string]: ReportTypology[]
}

export interface ExtraReportInfo {
    id: number
    price: number
}

const ExtraReportSelector: React.FC<Props> = (props) => {
    const [selectedExtraReports, setSelectedExtraReports] = useState<ReportTypology[] | null>(null)
    const [selectedExtraReportsIDs, setSelectedExtraReportsIDs] = useState<ExtraReportInfo[]>([])

    const confirmExtraSelection = () => {
        props.onSelectionChange(selectedExtraReportsIDs)
    }

    const checkIsSelected = useCallback(
        (extraReport: ReportTypology) => {
            return selectedExtraReportsIDs.map((extraReport) => extraReport.id).includes(extraReport.reportType)
        },
        [selectedExtraReportsIDs]
    )

    useEffect(() => {
        if (props.selectedReports.length === 0) return
        setSelectedExtraReportsIDs(
            props.selectedReports.map((selectedReport) => ({
                id: selectedReport.reportType,
                price: selectedReport.price,
            }))
        )
    }, [props.selectedReports])

    return (
        <div className="modal-extra-report-wrapper">
            <div className="dark-cover" onClick={() => props.onClose()} />
            <div className="modal-extra-report-card">
                <div className="modal-extra-report-card__header">
                    <Button type="secondary" onClick={() => props.onClose()}>
                        Annulla
                    </Button>
                    <Button onClick={() => confirmExtraSelection()}>Conferma</Button>
                </div>
                <div className="modal-extra-report-card__body">
                    {!!props.availableExtraReports &&
                        Object.keys(props.availableExtraReports).map((key, i) => {
                            return (
                                <ExtraReportSectionContainer
                                    allCollapsed={!!props.allSectionsAreCollapsed}
                                    key={`${key}__${i.toString()}`}
                                    title={
                                        props.availableExtraReports
                                            ? props.availableExtraReports[key as keyof GroupedExtraReports][0].name
                                            : ''
                                    }
                                >
                                    {props.availableExtraReports &&
                                        props.availableExtraReports[key as keyof GroupedExtraReports].map(
                                            (extraReport) => (
                                                <>
                                                    <ExtraReportEntrySelector
                                                        key={extraReport.nameTypology}
                                                        disabled={props.disabled}
                                                        report={extraReport}
                                                        selected={checkIsSelected(extraReport)}
                                                        onChange={(isSelected) => {
                                                            if (isSelected) {
                                                                setSelectedExtraReports(
                                                                    selectedExtraReports
                                                                        ? [...selectedExtraReports, extraReport]
                                                                        : [extraReport]
                                                                )
                                                                setSelectedExtraReportsIDs(
                                                                    selectedExtraReportsIDs
                                                                        ? [
                                                                              ...selectedExtraReportsIDs,
                                                                              {
                                                                                  id: extraReport.reportType,
                                                                                  price: extraReport.price,
                                                                              },
                                                                          ]
                                                                        : [
                                                                              {
                                                                                  id: extraReport.reportType,
                                                                                  price: extraReport.price,
                                                                              },
                                                                          ]
                                                                )
                                                            } else {
                                                                setSelectedExtraReports(
                                                                    selectedExtraReports?.filter(
                                                                        (selectedRep) =>
                                                                            selectedRep.reportType !==
                                                                            extraReport.reportType
                                                                    ) || []
                                                                )
                                                                setSelectedExtraReportsIDs(
                                                                    selectedExtraReportsIDs.filter(
                                                                        (selectedRep) =>
                                                                            selectedRep.id !== extraReport.reportType
                                                                    ) || []
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )
                                        )}
                                </ExtraReportSectionContainer>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

const ExtraReportSectionContainer: React.FC<ExtraReportSectionContainerProps> = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(!!props.allCollapsed)

    return (
        <div className="extra-report-section-container">
            <div className="extra-report-section-container__title" onClick={() => setIsCollapsed(!isCollapsed)}>
                <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
                <h3>{props.title}</h3>
            </div>
            <div className={`extra-report-section-container__body ${isCollapsed ? 'collapsed-body' : ''}`}>
                {props.children}
            </div>
        </div>
    )
}

const ExtraReportEntrySelector: React.FC<ExtraReportEntrySelectorProps> = (props) => {
    return (
        <div className="extra-report-entry-selector">
            <div>
                <div className="extra-report-entry-selector__title">{props.report.nameTypology}</div>
                <div className="subtitle-text">{props.report.descriptionTypology}</div>
            </div>
            <CheckBox
                disabled={props.disabled}
                value={!!props.selected}
                onChange={(newVal) => {
                    props.onChange(newVal)
                }}
                title={props.report.price.toString()}
            >
                {props.report.price}€
            </CheckBox>
        </div>
    )
}

export default ExtraReportSelector
