/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store'
import { ContestSummary } from '../../../types/contests'
import TagsContainer from '../../../components/TagsContainer/TagsContainer'
import { ReportTypology, Resource } from '../../../types/data'
import TextInput from '../../../components/TextInput/TextInput'
import InfiniteListLoader from '../../../components/Loader/InfiniteListLoader'
import { PlacementQuote } from '../../../types/user'
import Button from '../../../components/Button/Button'
import ContestResourcesSection from '../../../customComponents/ContestResourceSection/ContestResourcesSection'
import { ROUTES } from '../../../resources/routes-constants'
import { faLockOpen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTipologies } from '../../../store/actions/thunk_actions'
import { getReportsOfTypology, updateExistingContest } from '../../../resources/api-constants'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import StaticExtraReportSelector from '../../../components/ExtraReportSelector/StaticExtraReportSelector'
import ExtraReportSelector, {
    ExtraReportInfo,
    GroupedExtraReports,
} from '../../../components/ExtraReportSelector/ExtraReportSelector'

interface ContestPageProps {
    contestInfo: ContestSummary
    resources: Resource[]
    placementQuotes: PlacementQuote | null
    isPublicPage?: boolean
    loading?: boolean
    onContestSave: (updatedContest: ContestSummary | null, extraReportIDs: number[]) => void
}

const lockedEditStatusValues = [1, 2, 3, 4, 5, 6, 10]

const ContestInfoSection: React.FC<ContestPageProps> = (props) => {
    const data = useAppSelector((state) => state.data)
    const user = useAppSelector((state) => state.user)
    const [selectedExtraReports, setSelectedExtraReports] = useState<ReportTypology[] | null>(null)
    const [ungroupedAvailableExtraReports, setUngroupedAvailableExtraReports] = useState<ReportTypology[] | null>(null)
    const [availableExtraReports, setAvailableExtraReports] = useState<GroupedExtraReports | null>(null)
    const [selectedExtraReportsIDs, setSelectedExtraReportsIDs] = useState<ExtraReportInfo[]>([])
    const [isPreviewMode, setIsPreviewMode] = useState(false)
    const [currentContestInfo, setCurrentContestInfo] = useState<ContestSummary | null>(props.contestInfo)
    const [placementQuotes, setPlacementQuotes] = useState<PlacementQuote | null>(null)
    const [contestResources, setContestResources] = useState<Resource[]>([])
    const [saveContestInfoIsLoading, setSaveContestInfoIsLoading] = useState(false)
    // const [extraReportsIDs, setExtraReportsIDs] = useState<number[]>([])
    const [finalPrice, setFinalPrice] = useState(0)
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const { state } = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    // const publishContestMessage = 'Riserva questo contest alla sola attenzione degli utenti Prime.'

    // const fetchProjectData = async () => {
    //     try {
    //         setIsLoading(true)
    //         const project = await getSingleContest(user.accessToken, state.idContest)
    //         const quotes = await getSingleContestPlacementQuotes(user.accessToken, state.idContest)
    //         const resources = await getSingleContestResources(user.accessToken, state.idContest)
    //         if (project) {
    //             setCurrentContestInfo(project)
    //         }
    //         if (quotes) {
    //             setPlacementQuotes(quotes)
    //         }
    //         if (resources) {
    //             setContestResources(resources)
    //         }
    //     } catch (error) {
    //         console.error(error)
    //     }
    //     setIsLoading(false)
    // }

    useEffect(() => {
        void fetchExtraReports()
    }, [currentContestInfo])

    const fetchExtraReports = async () => {
        try {
            if (!currentContestInfo?.idTypologySize) return
            const extraReports: ReportTypology[] | undefined = await getReportsOfTypology(
                currentContestInfo?.idTypologySize.toString()
            )
            if (extraReports && extraReports.length > 0) {
                const groupedReports = extraReports.reduce((reports, item) => {
                    reports[item.name] = reports[item.name] || []
                    reports[item.name].push(item)
                    return reports
                }, Object.create(null))
                setUngroupedAvailableExtraReports(extraReports)
                setAvailableExtraReports(groupedReports)
                const idArray = selectedExtraReportsIDs.map((extraReport) => extraReport.id)
                setSelectedExtraReports(
                    extraReports.filter((extraReport) => extraReport && idArray.includes(extraReport.reportType))
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetchTypologies = async () => {
        try {
            await dispatch(
                getTipologies({ authToken: user.accessToken, idCategory: currentContestInfo?.category || -1 })
            )
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void fetchTypologies()
        if (state !== null && state.idContest !== undefined) {
            setIsPreviewMode(!!state.preview)
            setContestResources(props.contestInfo.resource)
            setPlacementQuotes(props.placementQuotes)
            setCurrentContestInfo(props.contestInfo)
            setFinalPrice(props.contestInfo.price ? parseInt(props.contestInfo.price, 10) : 0)
            setSelectedExtraReportsIDs(
                props.contestInfo.request.map((req) => ({ id: req.reportType, price: req.price }))
            )
        }
    }, [state, props.resources, props.placementQuotes, props.contestInfo])

    const saveContest = () => {
        try {
            setSaveContestInfoIsLoading(true)
            props.onContestSave(
                currentContestInfo,
                selectedExtraReportsIDs.map((extraRepID) => extraRepID.id)
            )
        } catch (error) {
            console.error(error)
        }
        setSaveContestInfoIsLoading(false)
    }

    const upgradeDraftProject = async () => {
        try {
            const data = {
                status: 1,
                idContest: currentContestInfo?.idContest,
            }
            await updateExistingContest(user.userInfo?.authToken || '', data)
            navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)
            ToastSuccess('Contest inviato con successo!')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un problema durante l'invio per l'approvazione del contest")
        }
    }

    return (
        <>
            {currentContestInfo && !props.loading ? (
                <>
                    {modalIsVisible && (
                        <ExtraReportSelector
                            availableExtraReports={availableExtraReports}
                            contestId={currentContestInfo.idContest}
                            selectedReports={
                                selectedExtraReports
                                    ? selectedExtraReports.map((extraRep) => ({
                                          ...extraRep,
                                          extra: 1,
                                          id: extraRep.reportType,
                                          idContest: currentContestInfo.idContest,
                                          annotation: extraRep.descriptionTypology,
                                          description: extraRep.descriptionTypology,
                                          name: extraRep.nameTypology,
                                      }))
                                    : []
                            }
                            idTypologySize={currentContestInfo.idTypologySize}
                            disabled={isPreviewMode || lockedEditStatusValues.includes(currentContestInfo.status)}
                            onSelectionChange={(newExtraIDs) => {
                                setSelectedExtraReportsIDs(
                                    newExtraIDs.map((extraID) => ({ id: extraID.id, price: extraID.price }))
                                )
                                const extraIDsOnly = newExtraIDs.map((extraId) => extraId.id)
                                setSelectedExtraReports(
                                    ungroupedAvailableExtraReports
                                        ? ungroupedAvailableExtraReports.filter((availableExtra) =>
                                              extraIDsOnly.includes(availableExtra.reportType)
                                          )
                                        : []
                                )
                                const additionalCosts = newExtraIDs
                                    .map((extraID) => extraID.price)
                                    .reduce((sum, priceValue) => sum + priceValue, 0)
                                setFinalPrice(parseInt(currentContestInfo.price, 10) + additionalCosts)
                                setModalIsVisible(false)
                            }}
                            onClose={() => setModalIsVisible(false)}
                        />
                    )}
                    <div className="page-main-header" style={{ justifyContent: 'flex-end', gap: 16 }}>
                        {isPreviewMode ? (
                            <>
                                {currentContestInfo && currentContestInfo.status <= 4 && (
                                    <Button onClick={() => setIsPreviewMode(false)}>
                                        <FontAwesomeIcon icon={faLockOpen} />
                                    </Button>
                                )}
                            </>
                        ) : (
                            <>
                                {/* <Button type="secondary" onClick={() => navigate(ROUTES.PERSONAL_CONTESTS_ROUTE)}>
                                    Annulla
                                </Button> */}
                                {/* {state && (
                                <Button type="error" onClick={() => void deleteProject()}>
                                    Elimina
                                </Button>
                            )} */}
                                {currentContestInfo.status === 0 && (
                                    <Button
                                        loading={saveContestInfoIsLoading}
                                        disabled={currentContestInfo.title === '' || saveContestInfoIsLoading}
                                        onClick={() => void saveContest()}
                                    >
                                        Salva
                                    </Button>
                                )}
                            </>
                        )}
                        {currentContestInfo && currentContestInfo.status === 0 && (
                            <Button
                                disabled={currentContestInfo.title === '' || saveContestInfoIsLoading}
                                onClick={() => void upgradeDraftProject()}
                            >
                                Invia per attivazione
                            </Button>
                        )}
                    </div>
                    <div className="detail-body">
                        {currentContestInfo && currentContestInfo.status === 0 && (
                            <div className="info-message-container">
                                <div className="subtitle-text">
                                    Cliccando su <b>&quot;Invia per attivazione&quot;</b> ti dichiari intenzionato a
                                    pagare la somma totale dei costi di questo contest, per renderlo visibile ai nostri
                                    designer.
                                </div>
                                <div className="subtitle-text">
                                    Una volta inviato, le informazioni del contest, ad esclusione delle risorse, non
                                    saranno più modificabili.
                                </div>
                                <div className="subtitle-text">Fai attenzione: l&apos;azione è irreversibile!</div>
                            </div>
                        )}
                        {(currentContestInfo.dataStart || currentContestInfo.dataEnd) && (
                            <div className="info-message-container">
                                {currentContestInfo.dataStart && (
                                    <span>
                                        Il contest è iniziato il giorno{' '}
                                        {new Date(currentContestInfo.dataStart).toLocaleDateString()}.{' '}
                                    </span>
                                )}
                                {currentContestInfo.dataEnd && (
                                    <span>
                                        {currentContestInfo.remainingDays && currentContestInfo.remainingDays > 0
                                            ? `Si concluderà il giorno
                                        ${new Date(currentContestInfo.dataEnd).toLocaleDateString()}.`
                                            : `Si è concluso il giorno
                                        ${new Date(currentContestInfo.dataEnd).toLocaleDateString()}.`}
                                    </span>
                                )}
                                <b style={{ letterSpacing: '.3px' }}>
                                    {currentContestInfo.dataStart &&
                                        currentContestInfo.remainingDays &&
                                        currentContestInfo.remainingDays > 0 &&
                                        ` Giorni rimanenti ${currentContestInfo.remainingDays}.`}
                                </b>
                            </div>
                        )}
                        <div className="section-title">Generali</div>
                        <div
                            style={{
                                marginTop: 16,
                                marginBottom: 16,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 16,
                            }}
                        >
                            <div className="rounded-chip">
                                {data.categories.find((cat) => cat.id === currentContestInfo.category)?.keyName || ''}
                            </div>
                            {data.tipologies.length > 0 && (
                                <div className="rounded-chip">
                                    {data.tipologies.find(
                                        (type) => parseInt(type.idTypology, 10) === currentContestInfo.typology
                                    )?.name || ''}
                                </div>
                            )}
                            <div className="rounded-chip">{currentContestInfo.MQ || 0} MQ</div>
                        </div>
                        <div className="project-general-form">
                            <TextInput
                                label="NOME*"
                                disabled={isPreviewMode || lockedEditStatusValues.includes(currentContestInfo.status)}
                                value={currentContestInfo.title}
                                onChange={(newVal) => setCurrentContestInfo({ ...currentContestInfo, title: newVal })}
                            />
                            <div />
                            {/* <CheckBox
                                disabled={isPreviewMode || lockedEditStatusValues.includes(currentContestInfo.status)}
                                value={!!currentContestInfo.public}
                                onChange={(newValue) =>
                                    setCurrentContestInfo({ ...currentContestInfo, public: newValue ? 1 : 0 })
                                }
                                title={publishContestMessage}
                            >
                                {publishContestMessage}
                            </CheckBox> */}
                            <TextInput
                                label="DESCRIZIONE*"
                                isTextarea
                                disabled={isPreviewMode || lockedEditStatusValues.includes(currentContestInfo.status)}
                                value={currentContestInfo.description}
                                onChange={(newVal) =>
                                    setCurrentContestInfo({ ...currentContestInfo, description: newVal })
                                }
                            />
                            <TextInput
                                label="ANNOTAZIONI AGGIUNTIVE"
                                isTextarea
                                disabled={isPreviewMode || lockedEditStatusValues.includes(currentContestInfo.status)}
                                value={currentContestInfo.externalAnnotation}
                                onChange={(newVal) =>
                                    setCurrentContestInfo({ ...currentContestInfo, externalAnnotation: newVal })
                                }
                            />
                            <TextInput
                                label="LOCALITÀ*"
                                disabled={isPreviewMode || lockedEditStatusValues.includes(currentContestInfo.status)}
                                value={currentContestInfo.localization}
                                onChange={(newVal) =>
                                    setCurrentContestInfo({ ...currentContestInfo, localization: newVal })
                                }
                            />
                        </div>
                        <div className="section-title">Tag</div>
                        <TagsContainer
                            disabled={isPreviewMode || lockedEditStatusValues.includes(currentContestInfo.status)}
                            options={data.tags}
                            selectedTags={currentContestInfo.tags}
                            onTagsChange={(newTags) => setCurrentContestInfo({ ...currentContestInfo, tags: newTags })}
                        />
                        <div className="section-title">
                            {user.userInfo?.idSysGrant === 10 ? 'Costi' : 'Elaborati richiesti'}
                        </div>
                        <div className="contest-placement-quotes-container" style={{ width: '50%', minWidth: 200 }}>
                            <div className="contest-placement-quotes-container__quote">
                                <span>
                                    {currentContestInfo.request.find((req) => req.extra === 0)?.description || ''}
                                </span>
                            </div>
                            {user.userInfo?.idSysGrant === 10 && (
                                <div className="contest-placement-quotes-container__quote">
                                    <span>Totale</span>
                                    <span className="contest-placement-quotes-container__quote__value">
                                        €{finalPrice}
                                    </span>
                                </div>
                            )}
                            <div className="how-to-horizontal-divider" style={{ width: '100%' }} />
                            <div className="contest-placement-quotes-container__quote" style={{ marginTop: 16 }}>
                                <span>Extra</span>
                            </div>
                            {currentContestInfo.status === 0 ? (
                                <>
                                    <Button disabled={isPreviewMode} onClick={() => setModalIsVisible(true)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                    <div>
                                        <StaticExtraReportSelector
                                            selectedReports={
                                                ungroupedAvailableExtraReports
                                                    ? ungroupedAvailableExtraReports
                                                          .filter((extraReport) =>
                                                              selectedExtraReportsIDs
                                                                  .map((extraRepID) => extraRepID.id)
                                                                  .includes(extraReport.reportType)
                                                          )
                                                          .map((report) => ({
                                                              ...report,
                                                              extra: 1,
                                                              id: report.reportType,
                                                              idContest: currentContestInfo.idContest,
                                                              annotation: report.descriptionTypology,
                                                              description: report.descriptionTypology,
                                                              name: report.nameTypology,
                                                          }))
                                                    : []
                                            }
                                        />
                                    </div>
                                </>
                            ) : !currentContestInfo.request || currentContestInfo.request.length < 2 ? (
                                <div className="contest-placement-quotes-container__quote">
                                    <i>Nessun extra richiesto</i>
                                </div>
                            ) : (
                                <StaticExtraReportSelector
                                    selectedReports={currentContestInfo.request.filter((req) => req.extra !== 0)}
                                />
                            )}
                        </div>
                        {placementQuotes && (
                            <>
                                <div className="section-title">Premi e riconoscimenti</div>
                                <div className="contest-placement-quotes-container">
                                    <div className="contest-placement-quotes-container__quote">
                                        <span>1° Posto</span>
                                        <span className="contest-placement-quotes-container__quote__value">
                                            €{placementQuotes.p1}
                                        </span>
                                    </div>
                                    <div className="contest-placement-quotes-container__quote">
                                        <span>2° Posto</span>
                                        <span className="contest-placement-quotes-container__quote__value">
                                            €{placementQuotes.p2}
                                        </span>
                                    </div>
                                    <div className="contest-placement-quotes-container__quote">
                                        <span>3° Posto</span>
                                        <span className="contest-placement-quotes-container__quote__value">
                                            €{placementQuotes.p3}
                                        </span>
                                    </div>
                                    <div className="contest-placement-quotes-container__quote">
                                        <span>4° Posto</span>
                                        <span className="contest-placement-quotes-container__quote__value">
                                            €{placementQuotes.p4}
                                        </span>
                                    </div>
                                    <div className="contest-placement-quotes-container__quote">
                                        <span>5° Posto</span>
                                        <span className="contest-placement-quotes-container__quote__value">
                                            €{placementQuotes.p5}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="section-title">Risorse</div>
                        <ContestResourcesSection
                            disabled={isPreviewMode}
                            contestCodeDrawup={currentContestInfo.idContest}
                            mainResource={null}
                            attachments={contestResources}
                            additionIsDisabled={currentContestInfo && currentContestInfo.status > 4}
                        />
                    </div>
                </>
            ) : (
                <InfiniteListLoader text="Sto caricando il contest" />
            )}
        </>
    )
}

export default ContestInfoSection
