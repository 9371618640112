import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import PasswordInput from '../../components/PasswordInput/PasswordInput'
import TextInput from '../../components/TextInput/TextInput'
import { mailRegex } from '../../resources/other-constants'
import { ROUTES } from '../../resources/routes-constants'
import { performUserLogin } from '../../store/actions/thunk_actions'
import { useAppDispatch } from '../../store/reducers/store'
import '../../styles/pages/authPages.sass'
import { LoginCredentials } from '../../types/user'

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorIsVisible, setErrorIsVisible] = useState(false)
    const [confirmMailIsVisible, setConfirmMailIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [passwordIsVisible, setPasswordIsVisible] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        setConfirmMailIsVisible(state ? state.showSuccessMessage : false)
    }, [state])

    const incorrectData = useMemo((): boolean => {
        const fieldsToCheck = [email, password]
        return fieldsToCheck.findIndex((field) => field === '') !== -1 || !mailRegex.test(email)
    }, [email, password])

    const loginUser = async () => {
        try {
            setIsLoading(true)
            setErrorIsVisible(false)
            const authData: LoginCredentials = {
                grantType: 'password',
                email,
                password,
            }
            const res = await dispatch(performUserLogin(authData)).catch((e) => {
                return e
            })
            if (res.error) throw res.error
            navigate(ROUTES.PROFILE_ROUTE)
        } catch (error) {
            setErrorIsVisible(true)
            console.error(error)
        }
        setIsLoading(false)
    }

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div className="auth-inputs-container">
                <h1 style={{ fontSize: '2em' }}>Login</h1>
                {confirmMailIsVisible && (
                    <p className="success-container">
                        Ti abbiamo inviato una mail. Controlla la tua casella postale per confermare il tuo account. Fai
                        attenzione: la mail potrebbe essere finita nella casella della posta indesiderata.
                    </p>
                )}
                <TextInput label="Email" value={email} onChange={(newVal) => setEmail(newVal)} />
                <PasswordInput
                    label="Password"
                    type={passwordIsVisible ? 'text' : 'password'}
                    value={password}
                    displayIcon
                    revealPassword={passwordIsVisible}
                    onChange={(newVal) => setPassword(newVal)}
                    onIconClick={(val) => setPasswordIsVisible(val)}
                />
                {errorIsVisible ? (
                    <p className="error-container">
                        Qualcosa è andato storto durante il login. Controlla i valori inseriti e riprova.
                    </p>
                ) : (
                    <div style={{ height: '8px' }} />
                )}
                <Button disabled={isLoading || incorrectData} onClick={async () => void loginUser()}>
                    {isLoading ? 'Accesso in corso...' : 'Accedi'}
                </Button>
                <div className="input-centered-row">
                    <Link className="auth-link" to={ROUTES.REGISTER_ROUTE}>
                        Crea un account
                    </Link>
                    <Link className="auth-link" to={ROUTES.RESET_PASS_REQUEST_ROUTE} state={{ email }}>
                        Password dimenticata
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LoginPage
