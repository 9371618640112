/**
 * Returns a string truncated on a specified index
 * @param string the string that will get truncated
 * @param length the index at which the string gets truncated
 * @returns truncated string
 */
export const truncateText = (string: string, length: number) => {
    if (!string) return string
    return string.length > length ? `${string.substring(0, length - 1)}...` : string
}

export const getRankLevel = (rankValue: number) => {
    if (rankValue < 6) return 'low'
    if (rankValue > 7.9) return 'high'
    return 'medium'
}

export const getRequestHeader = (token: string, type?: string) => {
    return { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': type || 'application/json' } }
}

export const deepCopy = <T = any>(data: T): T => {
    return JSON.parse(JSON.stringify(data))
}

export const handleFileUploadFromInput = (
    e: File | any,
    callback: (file: any) => void,
    defaultRefValue?: React.MutableRefObject<null>
): void => {
    if (e.target.files[0]) {
        const file = e.target.files[0]
        callback(file)
        const fr = new FileReader()
        fr.onload = function () {
            if (defaultRefValue && defaultRefValue.current) (defaultRefValue.current as any).src = fr.result
        }
        fr.readAsDataURL(file)
    }
}
