import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList'
import { useAppSelector } from '../../../store/reducers/store'
import { PaginatedResponse } from '../../../types/requests'
import { ProjectSummary } from '../../../types/projects'
import ProjectCard from '../../../components/ProjectCard/ProjectCard'
import { getDesigerPaginatedProjects } from '../../../resources/api-constants'

const DesignerDetailPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [projectsToDisplay, setProjectsToDisplay] = useState<ProjectSummary[]>([])
    const [availableProjectsLength, setAvailableProjectsLength] = useState<number | null>(null)
    const [currentOffset, setCurrentOffset] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const pageItemsSize = 12
    const querySearch = ''
    const { state } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        let hasMoreItemsToDownload =
            !!availableProjectsLength &&
            projectsToDisplay.length > 0 &&
            projectsToDisplay.length < availableProjectsLength
        if (availableProjectsLength === null) {
            hasMoreItemsToDownload = true
        }
        setHasMore(hasMoreItemsToDownload)
    }, [projectsToDisplay, availableProjectsLength])

    const fetchMoreProjects = async () => {
        try {
            setLoading(true)
            if (!hasMore) return
            const projects: PaginatedResponse<ProjectSummary> | undefined = await getDesigerPaginatedProjects(
                user.accessToken,
                pageItemsSize,
                currentOffset,
                state.designerId
            )

            if (!projects) return

            const finalDesigners = projects.project
                ? [...projectsToDisplay, ...projects.project]
                : [...projectsToDisplay]

            setCurrentOffset(finalDesigners.length)
            setAvailableProjectsLength(projects.totalRecordCount)
            setProjectsToDisplay(finalDesigners)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const resetProjectListParams = () => {
        setProjectsToDisplay([])
        setCurrentOffset(0)
        setAvailableProjectsLength(0)
    }

    const launchProjectSearch = async () => {
        if (!availableProjectsLength || loading) return
        await fetchMoreProjects()
    }

    useEffect(() => {
        resetProjectListParams()
        void launchProjectSearch()
    }, [querySearch])

    useEffect(() => {
        void fetchMoreProjects()
    }, [])

    return (
        <InfiniteScrollList
            fetchMore={() => {
                if (loading) return
                void fetchMoreProjects()
            }}
            hasMore={hasMore}
            isLoading={loading}
            currentDataLength={projectsToDisplay.length}
            emptyListMessage="Non ci sono ancora progetti da mostrare."
        >
            <div className="page-title-container">
                <span className="page-title">Progetti del designer {state.designerName}</span>
            </div>
            <div className="empty-space-divider" />
            {/* <div className="page-list-actions-header">
                <Search
                    onQueryChange={(q) => {
                        setQuerySearch(q)
                    }}
                />
            </div> */}
            <div className="scroll-list-container public-compact-list-container">
                <div className="grid-scroll-list">
                    {projectsToDisplay.map((projectInfo) => (
                        <ProjectCard
                            onClick={(idProject) => {
                                navigate(`${ROUTES.PROJECT_DETAIL_ROUTE}${idProject}`, {
                                    state: { preview: true, idProject },
                                })
                            }}
                            projectInfo={projectInfo}
                            key={projectInfo.id}
                            isPublic
                        />
                    ))}
                </div>
            </div>
        </InfiniteScrollList>
    )
}

export default DesignerDetailPage
