import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import TextInput from '../../components/TextInput/TextInput'
import '../../styles/pages/ContestCreationWidget.sass'
import { checkEmailCanBeUsed } from '../../resources/api-constants'
import { mailRegex } from '../../resources/other-constants'

interface Props {
    alreadyLoggedUser: boolean
    onConfirm: (email: string, createUser?: boolean) => void
}

const DefineCustomerEmail: React.FC<Props> = (props) => {
    const [customerEmail, setCustomerEmail] = useState('')
    const [errorIsVisible, setErrorIsVisible] = useState(false)

    useEffect(() => {
        if (props.alreadyLoggedUser) props.onConfirm('')
    }, [props.alreadyLoggedUser])

    const checkEmail = async () => {
        try {
            const isValid = await checkEmailCanBeUsed(customerEmail)
            if (isValid !== undefined) {
                if (isValid.emailExits) {
                    if (!isValid.isCustomer) {
                        setErrorIsVisible(true)
                    } else {
                        props.onConfirm(customerEmail)
                    }
                } else {
                    props.onConfirm(customerEmail, true)
                }
            } else {
                setErrorIsVisible(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="contest-creation-step">
            <div className="contest-creation-step__text-wrapper">
                <h1>La tua mail</h1>
                <p>
                    Le notifiche via mail ti permettono di tenere sotto controllo i tuoi Progetti e rispondere alle
                    domande che riceverai dai nostri architetti. Dove possiamo inviartele?
                </p>
            </div>
            <div style={{ width: '100%' }}>
                <TextInput
                    value={customerEmail}
                    placeholder="Scrivi qui la tua email"
                    onChange={(newVal) => setCustomerEmail(newVal)}
                />
                {errorIsVisible && (
                    <p>Questa mail è già in uso per un account designer, prova ad usare una nuova mail.</p>
                )}
                <div style={{ height: 24, width: 1 }} />
                <Button disabled={!customerEmail || !mailRegex.test(customerEmail)} onClick={() => void checkEmail()}>
                    Avanti
                </Button>
            </div>
        </div>
    )
}

export default DefineCustomerEmail
